import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['anyChangesCheckbox', 'newValueInput', 'select2'];

    connect() {
      if (this.hasSelect2Target) {
        this.select2Targets.forEach((el) => {
          // setup events for select2 (apparently it doesn't fire standard events
          $(el).on('select2:select', this._handleSelect2Change);
        });
      }
    }

    _handleSelect2Change(event) {
      event.target.dispatchEvent(new CustomEvent('change'));
    }

    _valueChanged(el) {
      if (['checkbox', 'radio'].indexOf(el.getAttribute('type')) > -1) {
        if (el.dataset.valueBefore === undefined) {
          return (el.checked === true);
        }
        return (el.dataset.valueBefore.indexOf(el.value) > -1 && !el.checked)
                    || (el.dataset.valueBefore.indexOf(el.value) === -1 && el.checked);
      }
      return (el.dataset.valueBefore != null && el.value.trim() !== el.dataset.valueBefore)
                || (el.dataset.valueBefore == null && el.value.trim() !== '');
    }

    toggleAnyChangesCheckbox = (_event) => {
      const somethingChanged = this.newValueInputTargets.some((el) => this._valueChanged(el));

      this.anyChangesCheckboxTarget.checked = somethingChanged;
      this.anyChangesCheckboxTarget.dispatchEvent(new CustomEvent('change'));
    }

    resetAllValues = (event) => {
      if (event.target.checked === false) {
        // reset inputs to initial value
        this.newValueInputTargets.forEach((el) => {
          if (this._valueChanged(el)) {
            if (this.select2Targets.includes(el)) {
              // input of type select
              $(el).val(el.dataset.valueBefore).trigger('change');
            } else if (el.classList.contains('hasDatepicker')) {
              // input of type datepicker
              if (el.dataset.valueBefore === undefined) {
                el.value = null;
              } else {
                const isHour = el.dataset.valueBefore.match(/^\d{2}:\d{2}/);
                const format = isHour ? 'HH:mm' : 'YYYY-MM-DD HH:mm::ss ZZ';
                const oldDate = moment(el.dataset.valueBefore, format);
                $(el).datetimepicker('setDate', oldDate.toDate());
              }
            } else if (['checkbox', 'radio'].indexOf(el.getAttribute('type')) > -1) {
              // checkboxes for multi-choice inputs and radios for yes/no
              if (el.dataset.valueBefore === undefined) {
                // value before was null
                el.checked = false;
              } else {
                el.checked = (el.dataset.valueBefore.indexOf(el.value) > -1);
              }
            } else if (el.dataset.valueBefore === undefined) {
              el.value = null;
            } else {
              el.value = el.dataset.valueBefore;
            }
          }
        });
      }
    }
}
