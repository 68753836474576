import { Controller } from 'stimulus';

// Depends on jQuery
export default class extends Controller {
  static targets = [
    'dateWidget',
    'dateInputsArea',
    'dateInput',
    'dateInputTemplate',
    'dateRangeStart',
    'dateRangeEnd',
  ]

  connect() {
    const controller = this;
    $(this.dateWidgetTarget).datepicker({
      numberOfMonths: 2,
      beforeShowDay(displayDate) {
        if (controller.isDateSelected(displayDate)) {
          return [true, 'selected', ''];
        }
        if (controller.isDateInsidePeriod(displayDate)) {
          return [true, 'inside-period', ''];
        }
        return [true, '', ''];
      },
    });
  }

  getDateFormat() {
    return 'D/M/Y';
  }

  setDateRange = () => {
    const selectedDate = $(this.dateWidgetTarget).datepicker('getDate');
    const startDateInput = $(this.dateRangeStartTarget);
    const endDateInput = $(this.dateRangeEndTarget);
    const selectedMoment = moment(selectedDate, this.getDateFormat());

    const startDate = moment(startDateInput.val(), this.getDateFormat());
    const endDate = moment(endDateInput.val(), this.getDateFormat());

    if (!startDate.isValid() || selectedMoment.isBefore(startDate) || endDate.isValid()) {
      startDateInput.val(selectedMoment.format(this.getDateFormat()));
      endDateInput.val('');
    } else {
      endDateInput.val(selectedMoment.format(this.getDateFormat()));
    }
    this.displayDateRange();
  }

  displayDateRange() {
    const startDate = $(this.dateRangeStartTarget).val();
    const endDate = $(this.dateRangeEndTarget).val();

    let dateRangeDisplay = '';
    if (startDate && endDate) {
      dateRangeDisplay = startDate == endDate ? startDate : `${startDate} - ${endDate}`;
    }

    if (dateRangeDisplay) {
      // Create a new element for the date range
      const dateRangeElement = this.dateInputTemplateTarget.cloneNode(true);

      // Set the input value and label to the date range
      dateRangeElement.querySelectorAll('input')[0].value = dateRangeDisplay;
      dateRangeElement.classList.remove('hidden');
      dateRangeElement.querySelectorAll('[data-target="multi-date-selector.dateInputLabel"]')[0].innerHTML = dateRangeDisplay;
      dateRangeElement.dataset.target = 'multi-date-selector.dateInput';
      dateRangeElement.dataset.multiDateSelectorDateStart = startDate;
      dateRangeElement.dataset.multiDateSelectorDateEnd = endDate;

      // Append the new element
      this.dateInputsAreaTarget.appendChild(dateRangeElement);
    }
  }

  selectDate = () => {
    const selectedDate = $(this.dateWidgetTarget).datepicker('getDate');
    if (this.isDateSelected(selectedDate)) {
      for (const dateInput of this.dateInputTargets) {
        if (moment(dateInput.dataset.multiDateSelectorDate).isSame(selectedDate)) {
          dateInput.remove();
          $(this.dateWidgetTarget).datepicker('setDate', null);
          return;
        }
      }
    } else {
      this.addDateSelection(selectedDate);
    }
  }

  removeDate = (event) => {
    event.preventDefault();
    event.target.closest('[data-target="multi-date-selector.dateInput"]').remove();
    $(this.dateWidgetTarget).datepicker('setDate', null);
  }

  isDateSelected(displayDate) {
    const displayedDate = moment(displayDate);

    return this.dateInputTargets.some((value) => displayedDate.isSame(moment(value.dataset.multiDateSelectorDateStart, this.getDateFormat()))
             || displayedDate.isSame(moment(value.dataset.multiDateSelectorDateEnd, this.getDateFormat()))
             || displayedDate.isSame(moment(value.dataset.multiDateSelectorDate, this.getDateFormat())));
  }

  isDateInsidePeriod(displayDate) {
    const displayedDate = moment(displayDate);

    return this.dateInputTargets.some((value) => {
      const startDate = value.dataset.multiDateSelectorDateStart;
      const endDate = value.dataset.multiDateSelectorDateEnd;

      return startDate && endDate && displayedDate.isAfter(moment(startDate, this.getDateFormat())) && displayedDate.isBefore(moment(endDate, this.getDateFormat()));
    });
  }

  addDateSelection(selectedDate) {
    const dateInput = this.dateInputTemplateTarget.cloneNode(true);

    dateInput.querySelectorAll('input')[0].value = moment(selectedDate).format(this.getDateFormat());
    dateInput.classList.remove('hidden');
    dateInput.querySelectorAll('[data-target="multi-date-selector.dateInputLabel"]')[0].innerHTML = moment(selectedDate).format(this.getDateFormat());
    dateInput.dataset.target = 'multi-date-selector.dateInput';
    dateInput.dataset.multiDateSelectorDate = moment(selectedDate).format(this.getDateFormat());

    this.dateInputsAreaTarget.appendChild(dateInput);
  }
}
