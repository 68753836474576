import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'icon']

  toggle_visibility = (event) => {
    if (this.inputTarget.type == 'password') {
      this.inputTarget.type = 'text';
      this.iconTarget.classList.toggle('mdi-eye-outline', false);
      this.iconTarget.classList.toggle('mdi-eye-off-outline', true);
    } else {
      this.inputTarget.type = 'password';
      this.iconTarget.classList.toggle('mdi-eye-outline', true);
      this.iconTarget.classList.toggle('mdi-eye-off-outline', false);
    }
  }
}
