import { Controller } from 'stimulus';
import I18n from '~/config/vue_i18n';

// controller: login-data-user-controller
export default class extends Controller {
  static targets = [
    'provider', 'submitButton', 'email', 'form', 'connected',
  ]

  connect() {
    if (this.providerTarget.value !== null && this.providerTarget.value !== undefined && this.providerTarget.value !== '') {
      this.oldEmail = this.emailTarget.value;
      this.providersHash = this.providerTargets.map((provider) => provider.value).reduce((map, obj) => {
        map[obj.split(';')[0]] = obj.split(';').filter((_value, index) => index != 0);
        return map;
      }, {});
    }
  }

  checkNeedForAlert(event) {
    const newEmail = event.target.value;
    if (newEmail !== this.oldEmail && this.providerTargets.length > 0) {
      const domain = newEmail.split('@')[1];
      const providerForDomain = Object.keys(this.providersHash).find((key) => this.providersHash[key].includes(domain));
      if (providerForDomain !== undefined) {
        this.providerMessage = I18n.t(`volunteers.settings.external_providers.email_belongs_to_other_eidp${this.connectedTarget.value === 'true' ? '' : '_and_account_without_providers'}`, { provider: providerForDomain });
        this.formTarget.dataset.action = 'submit->login-data-user#alertExternalProvider';
      }
    }
  }

  // this won't block button from going into loading
  alertExternalProvider(event) {
    if (!confirm(this.providerMessage)) {
      // This will block the rest of the chain of action
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
