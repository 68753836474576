import { Controller } from 'stimulus';
import { reloadUX } from '../base/beeple_lib';
import { removeFakes } from '../base/availabilities';

export default class extends Controller {
    static targets = ['wrapper', 'content', 'arrow', 'form', 'moreOptionsBtn'];

    // keep in sync with .popup__content height and width in
    // app/assets/stylesheets/availabilities.scss
    popoverHeight = 438;

    popoverWidth = 363;

    refresh = (_event) => {
      reloadUX(this.element);
    }

    // opens a quick popup to create a new availability
    openPopup = (event) => {
      event.stopPropagation();

      removeFakes();

      const clickedElementRect = event.target.getClientRects()[0];

      let top; let left; let
        arrowTop;
      // viewport width and height
      const vw = document.querySelector('html').clientWidth;
      const vh = document.querySelector('html').clientHeight;

      // try to position the popup in the middle of the element
      top = clickedElementRect.top + (clickedElementRect.height / 2) + this.popoverHeight;
      if (top > vh) {
        // would fall out of viewport, position above the element
        top = clickedElementRect.top + (clickedElementRect.height / 2) - this.popoverHeight;
        if (top < 80) top = 80;
      } else {
        top = clickedElementRect.top;
      }
      // position the arrow
      arrowTop = clickedElementRect.top - top - 20;
      if (arrowTop < 50) arrowTop = 50;

      // try to position the popup to the right of the element
      let isOnRightSide = true;
      left = clickedElementRect.right + this.popoverWidth;
      if (left > vw) {
        // would fall out of viewport, position to the left of the element
        left = clickedElementRect.left - this.popoverWidth - 10;
        isOnRightSide = false;
      } else {
        left = clickedElementRect.right + 10;
      }

      if (isOnRightSide) {
        this.arrowTarget.classList.add('left');
        this.arrowTarget.classList.remove('right');
      } else {
        this.arrowTarget.classList.add('right');
        this.arrowTarget.classList.remove('left');
      }

      const arrowCssText = `top: ${Math.floor(arrowTop)}px;`;
      this.arrowTarget.style.cssText = arrowCssText;

      const cssText = `top: ${Math.floor(top)}px; left: ${Math.floor(left)}px;`;

      this.wrapperTarget.style.cssText = cssText;
      // document.querySelector('.popup__content:before').style.cssText = `top: ${clickedElementRect.top}`;
      this.contentTarget.classList.remove('hide');
      this.arrowTarget.classList.remove('hide');

      const el = event.target.closest('.day-events:not(.locked)');
      if (el !== null) {
        const { date } = el.dataset;
        const url = el.dataset.quickCreateUrl;
        if (url !== undefined) {
          $.ajax(url, { data: { date } });
        }
      }
    }

    closePopup = (_event) => {
      const fakes = document.querySelectorAll("#availabilities_calendar div[data-dry-run='true']");
      fakes.forEach((div) => {
        div.remove();
      });

      const popupContent = this.contentTarget;
      popupContent.innerHTML = '';
      popupContent.classList.add('hide');
      this.arrowTarget.classList.add('hide');
    }

    openSidebarToCompleteCreate = (event) => {
      event.stopPropagation();
      if (this.hasFormTarget) {
        const url = this.moreOptionsBtnTarget.dataset.moreOptionsUrl;

        if (url !== undefined) {
          const form = this.formTarget;
          const data = $(form).serialize();
          this.closePopup();

          $.ajax({
            url,
            type: 'POST',
            data,
            error(_jqXHR, textStatus, errorThrown) {
              // log the error to the console
              console.error(`Error reading availability more options: ${textStatus}`, errorThrown);
            },
          });
        }
      }
    }
}
