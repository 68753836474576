//
// This is a complement for app/inputs/array_input.rb
//
import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['template'];

    addArrayField(event) {
      event.preventDefault();

      this.removeTemplateDatepicker();

      const templateClone = this.templateTarget.cloneNode(true);
      templateClone.classList.remove('template', 'hide');
      templateClone.removeAttribute('data-target');

      const inputType = templateClone.dataset.arrayInputType;
      const useDatePicker = inputType === 'date';

      const lastInput = templateClone.querySelector('input.array_input');

      if (useDatePicker === true) {
        lastInput.removeAttribute('id');
        $(lastInput).datepicker('destroy');
        let altField;
        // altField for datePicker is a hidden input generated in polyfill.js.coffee
        if (lastInput.previousElementSibling.classList.contains('datepicker-alt-field')) {
          altField = lastInput.previousElementSibling;
        }
        $(lastInput).datepicker({ dateFormat: 'dd/mm/yy', altField });
      }

      this.element.appendChild(templateClone);
    }

    removeArrayField(event) {
      event.preventDefault();
      const field = event.target.closest('div.input-group');
      this.element.removeChild(field);
    }

    removeTemplateDatepicker() {
      const inputType = this.templateTarget.dataset.arrayInputType;
      const useDatePicker = inputType === 'date';
      let templateInput;

      if (useDatePicker === true) {
        templateInput = this.templateTarget.querySelector('input.array_input');
        $(templateInput).datepicker('destroy');
        templateInput.removeAttribute('id');
      }
    }
}
