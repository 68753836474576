import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reasonInput', 'reasonIdInput'];

  selectOption = (event) => {
    event.stopPropagation();
    let btn;
    if (event.target.tagName == 'A') {
      btn = event.target;
    } else {
      btn = event.target.closest('a');
    }

    const reason = btn.dataset.entryText;
    if (reason !== undefined && this.hasReasonInputTarget) {
      this.reasonInputTarget.value = reason;
    }

    const reasonId = btn.dataset.entryId;
    if (this.hasReasonIdInputTarget) {
      this.reasonIdInputTarget.value = (reasonId === undefined ? null : reasonId);
    }
  };
}
