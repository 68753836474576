import { Controller } from 'stimulus';

// Clamps an input to integers 0..100.

export default class extends Controller {
  static targets = ['percent'];

  connect() {
    this.percentTargets.forEach((el) => { el.addEventListener('input', this.percent); });
  }

  percent = (event) => {
    const num = parseInt(event.target.value);
    if (num < 0) event.target.value = 0;
    if (num > 100) event.target.value = 100;
  };
}
