import { Controller } from 'stimulus';

// controller: linked-external-providers-controller
export default class extends Controller {
  static targets = [
    'alert',
  ]

  alertDisconnectProvider(event) {
    if (!confirm(this.alertTarget.dataset.alertMessage)) {
      // This will block the rest of the chain of action
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }
}
