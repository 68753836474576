import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'lastMessageDate', 'unreadCount',
    'resolveChannelButtonResolvedIcon', 'resolveChannelButtonUnresolvedIcon', 'channelResolvedIcon']

  connect() {
    this.update();
  }

  openChannel = (event) => {
    event.preventDefault();
    const chatContainer = document.querySelector('[data-controller="chat"]');
    if (chatContainer) {
      const chat_application = this.application.getControllerForElementAndIdentifier(
        chatContainer, 'chat',
      );
      if (chat_application) {
        for (const other_channel of chat_application.element.querySelectorAll(
          '[data-controller="chat-channel"].active',
        )) {
          other_channel.classList.toggle('active', false);
        }
        this.element.classList.toggle('active', true);

        chat_application.openChatBox(this.element.dataset.channelId);
      }
    }
  }

  update() {
    this.nameTarget.innerHTML = this.element.dataset.name;

    if (this.element.dataset.unreadCount != undefined && this.element.dataset.unreadCount != 0) {
      this.unreadCountTarget.innerHTML = this.element.dataset.unreadCount;
    } else {
      this.unreadCountTarget.innerHTML = '';
    }

    if (this.element.dataset.lastMessageDate != undefined) {
      this.lastMessageDateTarget.innerHTML = this.element.dataset.lastMessageDate;
    } else {
      this.lastMessageDateTarget.innerHTML = '';
    }

    if (this.element.dataset.isAdminView == 'true' && this.element.dataset.resolved != undefined) {
      if (this.element.dataset.resolved == 'true') {
        this.resolveChannelButtonResolvedIconTarget.classList.remove('hidden');
        this.resolveChannelButtonUnresolvedIconTarget.classList.add('hidden');
        this.channelResolvedIconTarget.classList.remove('hidden-keep-space');
      } else {
        this.resolveChannelButtonResolvedIconTarget.classList.add('hidden');
        this.resolveChannelButtonUnresolvedIconTarget.classList.remove('hidden');
        this.channelResolvedIconTarget.classList.add('hidden-keep-space');
        this.element.classList.toggle('hide', false);
      }
    }
  }
}
