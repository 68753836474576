import { Controller } from 'stimulus';
import I18n from '~/config/vue_i18n';

// controller: admin--tenant--external-providers
export default class extends Controller {
  static targets = [
    'sso', 'domains', 'name', 'dependableFields', 'dependableField',
  ]

  connect() {
    this.oldSSOValue = this.ssoTarget.checked;
  }

  askConfirmationForChangeInSSO(event) {
    if (this.element.dataset.isObjectPersisted === 'true') {
      const newValue = event.target.value;

      if (this.oldSSOValue !== newValue) {
        if (confirm(I18n.t('admin.tenant.external_providers.form.sso_change_configuration'))) {
          this.oldSSOValue = newValue;
        } else {
          event.preventDefault();
          this.ssoTarget.checked = this.oldSSOValue;
        }
      }
    }
  }

  askConfirmationForDomainRemoval(event) {
    if (this.element.dataset.isObjectPersisted === 'true') {
      const email = event.target.dataset.extraInfoRemove;
      if (email !== null && email !== undefined && email.match(/^ *$/) === null) {
        const message = I18n.t('admin.tenant.external_providers.form.domain_change_configuration', { domain: email });
        if (!confirm(message)) {
          // This will block the rest of the chain of action
          event.stopImmediatePropagation();
          return;
        }
      }
    }
    this.checkIfDomainsExist(event);
  }

  checkIfDomainsExist(event) {
    // this.domainsTarget
    const emailModified = event.target.dataset.extraInfoRemove;
    // check if there are no elements except like the one removed and the empty one (used for duplication), as well as to check if the radio buttons are disabled
    if (this.domainsTargets.filter((email) => (email.value != emailModified) && (email.value != '')).length == 0 && !this.dependableFieldsTarget.classList.contains('hidden') && !(event.type === 'focusout' && emailModified != '')) {
      this.dependableFieldTargets.forEach((disabledElement) => {
        disabledElement.disabled = true;
      });
      this.dependableFieldsTarget.classList.add('hidden');
    } else if (this.domainsTargets.filter((email) => email.value != '').length >= 1 && this.dependableFieldsTarget.classList.contains('hidden')) {
      this.dependableFieldsTarget.classList.remove('hidden');
      this.dependableFieldTargets.forEach((disabledElement) => {
        disabledElement.disabled = false;
      });
    }

    // if there are less than 2 blank, it means that there is no empty email domain (1 for inserting, 1 for template [hidden])
    if (this.domainsTargets.filter((email) => email.value == '').length < 2) {
      const addDomainElement = this.element.querySelector('i[data-action="click->array-input#addArrayField"]');
      addDomainElement.click();
    }
  }

  typeProviderInName(event) {
    const nameElementValue = this.nameTarget.value;
    if (nameElementValue === '' || nameElementValue === this.oldProvider) {
      this.nameTarget.value = event.target.value[0].toUpperCase() + event.target.value.substring(1);
      this.oldProvider = this.nameTarget.value;
    }
  }
}
