//
// Availabilities shared helpers
//

export function sidebarContents() {
  const heading = document.querySelector("[data-target='availabilities-calendar.heading']");

  if (heading !== null) {
    return heading.dataset.sidebarContents;
  }
  return '';
}

function _doHighlights(theseCells) {
  const allCells = document.querySelectorAll('#availabilities_calendar div[data-event-id]');

  for (const div of allCells) {
    div.classList.add('blurred');
  }
  for (const div of theseCells) {
    div.classList.add('highlighted');
    div.classList.remove('blurred');
  }
}

function _updateTopRightControls(newValue, oldValue) {
  let safeOldValue = oldValue;
  if (safeOldValue === null || safeOldValue === '') {
    safeOldValue = sidebarContents();
  }

  let topRightBtn = document.querySelector(`.calendar-top-right-controls a[data-contents='${safeOldValue}']`);
  // when oldValue is 'settings' or 'filter', remove the active class
  if (topRightBtn !== null) {
    topRightBtn.classList.remove('btn-primary');
  }

  topRightBtn = document.querySelector(`.calendar-top-right-controls a[data-contents='${newValue}']`);
  // when newValue is 'settings' or 'filter', add the active class
  if (topRightBtn !== null) {
    topRightBtn.classList.add('btn-primary');
  }
}

export function resetHighlights() {
  const allCells = document.querySelectorAll('#availabilities_calendar div[data-event-id]');

  for (const div of allCells) {
    div.classList.remove('blurred');
    div.classList.remove('highlighted');
  }
}

export function removeFakes() {
  // remove temporary availability events of a dry_run
  const allFakes = document.querySelectorAll("#availabilities_calendar .event-container[data-dry-run='true']");

  for (const div of allFakes) {
    div.outerHTML = '';
  }

  // show again any temporary hidden availability events of a dry_run
  const allHidden = document.querySelectorAll('#availabilities_calendar .temporary.hide');

  for (const div of allHidden) {
    div.classList.remove('temporary', 'hide');
  }
}

export function highlightEvents(id, repetitionId) {
  resetHighlights();
  if (repetitionId !== undefined && repetitionId !== '') {
    const sameIds = document.querySelectorAll(`#availabilities_calendar div[data-event-repetition-id='${repetitionId}']`);
    _doHighlights(sameIds);
    return;
  }

  if (id !== undefined) {
    const sameIds = document.querySelectorAll(`#availabilities_calendar div[data-event-id='${id}']`);
    _doHighlights(sameIds);
  }
}

export function setSidebarContents(value) {
  const heading = document.querySelector("[data-target='availabilities-calendar.heading']");

  if (heading !== null) {
    const oldValue = heading.dataset.sidebarContents;
    _updateTopRightControls(value, oldValue);
    heading.dataset.sidebarContents = value;
  }
}
