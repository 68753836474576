import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'output']

  connect() {
    // # Generally we don't like adding event listeners inside stimulus but this
    // # seems an apperopriate place to have an exception
    const form = this.formTarget;
    form.addEventListener('submit', this.ignoreFormSubmit);
    this.current_request = null;
  }

  workaroundForForms = (event) => {
    if (event.target.tagName == 'INPUT') {
      this.refreshContent();
    }
  }

  refreshContentDelayed() {
    setTimeout(this.refreshContent, 100);
  }

  refreshContent = () => {
    if (this.element.dataset.isBoard) {
      this.refreshBoard();
    } else {
      const controller = this.application.getControllerForElementAndIdentifier(this.element, 'dashboard-widget');
      if (controller) {
        controller.setPageNumber(1);
      }
      this.executeRequest();
    }
  }

  refreshBoard = (_event) => {
    this.element.classList.toggle('form-refreshing', true);
    this.executePrivateRequest();
  }

  executePrivateRequest = () => {
    this.executeRequest(true);
  }

  executeRequest = (private_request = false) => {
    const form = this.formTarget;
    const doPrivateRequest = form.action.indexOf('planning_board') > 0;

    if (!private_request) {
      if (this.current_request) {
        this.current_request.abort();
      }
      this.element.classList.toggle('form-refreshing', true);
    }

    const data = {};
    for (const element of form.elements) {
      if (element.name.endsWith('[]')) {
        if (element.type == 'hidden' || element.checked) {
          const current_values = data[element.name] || [];
          current_values.push(element.value);
          data[element.name] = current_values;
        }
      } else if (element.type == 'radio') {
        if (element.checked) {
          data[element.name] = element.value;
        }
      } else if (element.type == 'checkbox') {
        if (element.checked) {
          data[element.name] = element.value;
        }
      } else {
        data[element.name] = element.value;
      }
    }

    let handler;
    if (private_request || doPrivateRequest) {
      handler = () => { };
    } else {
      handler = (rdata, status, xhr) => this._loadContent(rdata, status, xhr);
    }

    if (this.formTarget.method == 'post') {
      this.current_request = $.post({
        url: form.action,
        data,
        success: handler,
      });
    } else {
      this.current_request = $.get({
        url: form.action,
        data,
        success: handler,
      });
    }
  }

  ignoreFormSubmit(event) {
    event.preventDefault();
  }

  _loadContent(data, status, xhr) {
    this.current_request = null;
    const resultWrapper = this.outputTarget;
    resultWrapper.innerHTML = data;

    $(resultWrapper).find('[data-toggle="popover"]').each((index, el) => {
      let popoverDelay;
      if ($(el).hasClass('sticky-popover')) {
        popoverDelay = { show: 50, hide: 400 };
      } else {
        popoverDelay = { show: 200, hide: 0 };
      }

      if ($(el).data('content-url')) {
        $(el).popover({
          html: true,
          trigger: 'hover',
          content: '<div class="center-block" style="width: 64px"><span class="pulse-loader small">...</span></div>',
          delay: popoverDelay,
        }).on('shown.bs.popover', (popover) => {
          if ($(el).data('content-loaded') == true) {
            init_popover_events($(el).siblings('.popover'));
            return;
          }
          const refresh_content = function (content) {
            popover = $(el).data('bs.popover');
            popover.options.content = $(content);

            if (popover.$tip.hasClass('in')) {
              $(el).popover('show');
              init_popover_events($(el).siblings('.popover'));
            }
          };
          $.ajax({
            url: $(el).data('content-url'),
            success(rdata, rstatus, rxhr) {
              $(el).data('content-loaded', true);
              refresh_content(rdata);
            },
          });
        });
      } else {
        $(el).popover({
          html: true,
          trigger: 'hover',
          title: null,
          content() {
            return $($(this).data('content-target')).html();
          },
        });
      }
    });

    const event = new CustomEvent('form-refresh-loaded', { });

    this.element.dispatchEvent(event);
    $(resultWrapper).find('rating').rating();
    reload_some_polyfills(this.outputTarget);

    this.element.classList.toggle('form-refreshing', false);
  }
}
