import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['switch'];

    static isAvailableColor;

    static isNotAvailableColor;

    static selectedStyle;

    static neutralStyle;

    connect() {
      this.isAvailableColor = document.querySelector('#availabilities_calendar').dataset.isAvailableColor;
      this.isNotAvailableColor = document.querySelector('#availabilities_calendar').dataset.isNotAvailableColor;
      this.selectedStyle = this.element.dataset.selectedStyle;
      this.neutralStyle = this.element.dataset.neutralStyle;
    }

    highlightYesNoButtons = (event) => {
      let color;

      if (event.target.dataset.available === 'yes') {
        color = this.isAvailableColor;
      } else {
        color = this.isNotAvailableColor;
      }
      event.target.style.cssText = `background: ${color};`;
      const labelName = `radio-${event.target.dataset.available}-${event.target.dataset.eventId}`;
      const labels = document.querySelectorAll(`label[for="${labelName}"]`);
      labels.forEach((label) => {
        label.style.cssText = `background: ${color};`;
      });
    }

    unHighlightYesNoButtons = (event) => {
      let style;

      if (event.target.dataset.selected === 'true') {
        style = this.selectedStyle;
      } else {
        style = this.neutralStyle;
      }
      event.target.style.cssText = style;
      const labelName = `radio-${event.target.dataset.available}-${event.target.dataset.eventId}`;
      const labels = document.querySelectorAll(`label[for="${labelName}"]`);
      labels.forEach((label) => {
        label.style.cssText = style;
      });
    }

    toggleYesNo(event) {
      event.stopPropagation();
      const url = event.target.dataset.toggleUrl;
      if (url !== undefined && url !== '') {
        $.ajax({ url, type: 'POST', dataType: 'script' });
      }
    }
}
