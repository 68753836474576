import { Controller } from 'stimulus';

// controller: admin--multi--bulk-search-collaborator
export default class extends Controller {
  static targets = [
    'legacyCollaboratorsSearchPageContent',
    'collaboratorsSelectionCheckbox',
    'collaboratorsSelectAllCheckbox',
  ]

  connect() {
    const legacyCollaboratorsSearchPageContent = this.legacyCollaboratorsSearchPageContentTarget;
    const { fetchBulkSearchCollaboratorsPageUrl } = legacyCollaboratorsSearchPageContent.dataset;

    $.ajax({
      url: fetchBulkSearchCollaboratorsPageUrl,
      type: 'GET',
      success(html) {
        // hack: append a legacy html from the server
        legacyCollaboratorsSearchPageContent.insertAdjacentHTML('beforeend', html);

        // hack: fire custom event to bind legacy filters to autosubmit on
        const event = document.createEvent('Event');
        event.initEvent('collaboratorsSearch:injected', true, true);
        document.dispatchEvent(event);
      },
      error(_jqXHR, textStatus, errorThrown) {
        // log the error to the console
        console.error(`Error fetching data: ${textStatus}`, errorThrown);
      },
    });
  }

  ignoreFormSubmit(event) {
    event.preventDefault();
  }

  toggleSelectAllCollaborators(event) {
    const collaboratorsSelectAllCheckbox = this.collaboratorsSelectAllCheckboxTarget;
    const collaboratorsSelectionCheckboxes = this.collaboratorsSelectionCheckboxTargets;

    if (collaboratorsSelectAllCheckbox.checked === true) {
      collaboratorsSelectionCheckboxes.forEach((el) => { el.checked = 'checked'; });
    } else {
      collaboratorsSelectionCheckboxes.forEach((el) => { el.checked = ''; });
    }
  }

  bulkAddFilteredCollaborators(event) {
    event.preventDefault();

    const legacyCollaboratorsSearchPageContent = this.legacyCollaboratorsSearchPageContentTarget;
    const collaboratorsSelectionCheckboxes = this.collaboratorsSelectionCheckboxTargets;
    const collaboratorsToAdd = [];

    collaboratorsSelectionCheckboxes.forEach((el) => {
      if (el.checked === true) {
        const collaboratorId = el.value;
        const rowElement = el.closest('.collaborator-row');
        const collaboratorName = rowElement.querySelector('.collaborator-name').dataset.value;
        const collaboratorEmail = rowElement.querySelector('.collaborator-email').dataset.value;
        const collaboratorPhone = rowElement.querySelector('.collaborator-mobile').dataset.value;
        const collaboratorBirthDate = rowElement.querySelector('.collaborator-birth-date').dataset.value;

        collaboratorsToAdd.push({
          id: collaboratorId,
          name: collaboratorName,
          email: collaboratorEmail,
          birthDate: collaboratorBirthDate,
          mobilePhone: collaboratorPhone,
          selected: true,
        });
      }
    });

    window.dispatchEvent(new CustomEvent('bulk-add-filtered-collaborators-clicked', {
      detail: { collaboratorsToAdd },
    }));
  }
}
