import { Controller } from 'stimulus';

// controller: customer-portal--settings
export default class extends Controller {
  static targets = [
    'addManagerButton',
    'departmentIdInput',
    'happeningIdInput',
    'clusterIdInput',
    'form',
    'errors',
    'enablePortalInput',
    'formSubmitButton',
  ]

  connect() {
    document.querySelector('#tenant_client_portal_active_true')
      .addEventListener('click', this.checkIfCanActivatePortal);
    document.querySelector('#tenant_client_portal_active_false')
      .addEventListener('click', this.checkIfCanActivatePortal);
  }

  confirmAddManagerAllowed(event) {
    event.preventDefault();

    const departmentAlreadySelected = this.departmentIdInputTarget.value;
    const showDepartmentWarning = (departmentAlreadySelected === '');
    if (showDepartmentWarning) {
      this._showWarning('warningSelectDepartment');
      return;
    }

    const projectAlreadySelected = this.happeningIdInputTarget.value;
    const showProjectWarning = (projectAlreadySelected === '');
    if (showProjectWarning) {
      this._showWarning('warningSelectProject');
    }
  }

  _showWarning(warningKey) {
    const warning = this.addManagerButtonTarget.dataset[warningKey];
    alert(warning);
  }

  checkIfCanActivatePortal = (event) => {
    event.stopImmediatePropagation();

    if (event.target.value === 'true') {
      // enabling the portal
      if (this.enablePortalInputTarget.dataset.canEnablePortal !== 'true') {
        event.preventDefault();
        this.errorsTarget.classList.remove('hide');
        this.formSubmitButtonTarget.disabled = true;
      } else {
        this.errorsTarget.classList.add('hide');
        this.formSubmitButtonTarget.disabled = false;

        const confirmEnableDepartmentsMessage = this.enablePortalInputTarget.dataset.confirmEnableDepartments;
        if (confirmEnableDepartmentsMessage !== undefined && confirmEnableDepartmentsMessage !== '') {
          if (confirm(confirmEnableDepartmentsMessage)) {
            this.formTarget.submit();
          } else {
            event.preventDefault();
          }
        } else {
          this.formTarget.submit();
        }
      }
    }
  }
}
