import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  statusesFilterSelector = '#team_registrations_filter_occupying_statuses';

  changeStatus(event) {
    $(this.statusesFilterSelector).val(event.currentTarget.value).change();
  }
}
