import { Controller } from 'stimulus';

// controller: admin--tenant--planning-rules
export default class extends Controller {
  static targets = [];

  connect() {
    const me = this;
    $('.filter-wrapper .selection-counter').each((i, e) => {
      me.calculateCount(e);
    });
  }

  updateCount(event) {
    this.calculateCount(event.currentTarget);
  }

  calculateCount(element) {
    const filterWrapper = $(element.closest('.filter-wrapper'));
    const selectionCounter = filterWrapper.find('.selection-counter');
    const checkedInputs = filterWrapper.find('input[type="checkbox"]:checked');
    const checkedCount = checkedInputs.length;
    if (checkedCount > 0 || selectionCounter.hasClass('always-show')) {
      const template = checkedCount === 1
        ? 'data-template'
        : 'data-template-plural';
      selectionCounter.text(selectionCounter.attr(template)
        .replace('[count]', checkedCount));
    } else {
      selectionCounter.text('');
    }
  }
}
