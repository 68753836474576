import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['payrollService', 'submit'];

  connect() {
    this.payrollServiceTargets.forEach((el) => { el.addEventListener('click', this.onChecked); });
    this.submitTarget.addEventListener('click', this.onSubmit);
    this.onChecked();
  }

  onChecked = () => {
    let checked = 0;
    this.payrollServiceTargets.forEach((el) => { if (el.checked) checked += 1; });
    this.submitTarget.disabled = (checked === 0);
  };

  onSubmit = () => {
    $('.modal:visible:last').modal('hide');
  };
}
