import { Controller } from 'stimulus';

/** The repeition selector makes it easy to select out of a list
 *
 * Targets:
 *
 * Handlers:
 *
 * Data attributes: (unless mentioned otherwise always on component level)
*/
export default class extends Controller {
  static targets = ['thisTeam', 'allTeams', 'followingTeams', 'followingNTeamsInput']

  selectOnlyOne = (event) => {
    event.preventDefault();
    this._deselectAll();

    this.thisTeamTarget.checked = true;
  }

  selectOnlyThisAndFollowing = (event) => {
    event.preventDefault();
    this._deselectAll();

    this.thisTeamTarget.checked = true;
    for (const select of this.followingTeamsTargets) {
      select.checked = true;
    }
  }

  selectAll = (event) => {
    event.preventDefault();
    for (const select of this.allTeamsTargets) {
      select.checked = true;
    }
  }

  selectNextN = (event) => {
    event.preventDefault();
    this._deselectAll();

    this.thisTeamTarget.checked = true;
    for (const select of this.followingTeamsTargets.slice(0, this.followingNTeamsInputTarget.value)) {
      select.checked = true;
    }
  }

  _deselectAll() {
    for (const select of this.allTeamsTargets) {
      select.checked = false;
    }
  }
}
