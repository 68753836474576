import { Controller } from 'stimulus';
import { reloadUX } from '../base/beeple_lib';

export default class extends Controller {
    static targets = ['header', 'body', 'footer'];

    connect() {
      this.element.removeEventListener('refresh', this.refresh);
      this.element.addEventListener('refresh', this.refresh);
      this.refresh();
    }

    refresh(event) {
      reloadUX('.sidebar');
    }

    toggleVisibility(event) {
      event.stopPropagation();
      const sidebar = document.querySelector('.sidebar');
      if (sidebar !== undefined) {
        sidebar.classList.toggle('hide');
      }
    }
}
