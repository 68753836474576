import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['message', 'senderName', 'sentAt']

  connect() {
    this.element.classList.toggle('message--received', this.element.dataset.received == 'true');
    this.element.classList.toggle('message--send', this.element.dataset.received == 'false');

    this.messageTarget.innerHTML = this.element.dataset.message;
    this.senderNameTarget.innerHTML = this.element.dataset.senderName;
    this.sentAtTarget.innerHTML = this.element.dataset.sentAt;
  }
}
