import { Controller } from 'stimulus';

// Used in app/views/shared/payroll_allocations/_contract_type.html.erb

export default class extends Controller {
  static targets = [
    'allocateNone', 'allocateManual', 'allocateWaterfall',
    'automaticSection', 'waterfallSection', 'automaticOn',
  ];

  connect() {
    this.refreshUI();
  }

  allocateNone = () => {
    if (this.allocateNoneTarget.checked) {
      this.allocateManualTarget.checked = false;
      this.allocateWaterfallTarget.checked = false;
      this.refreshUI();
    }
  };

  allocateManual = () => {
    if (this.allocateManualTarget.checked || this.allocateWaterfallTarget.checked) {
      if (this.hasAllocateNoneTarget) this.allocateNoneTarget.checked = false;
    }
    if (this.element.dataset.isTenant !== 'true') {
      if (!this.allocateManualTarget.checked) this.allocateWaterfallTarget.checked = true;
    }
    this.refreshUI();
  };

  allocateWaterfall = () => {
    if (this.allocateManualTarget.checked || this.allocateWaterfallTarget.checked) {
      if (this.hasAllocateNoneTarget) this.allocateNoneTarget.checked = false;
    }
    if (this.element.dataset.isTenant !== 'true') {
      if (!this.allocateWaterfallTarget.checked) this.allocateManualTarget.checked = true;
    }
    this.refreshUI();
  };

  automaticChange = () => {
    this.refreshUI();
  };

  refreshUI = () => {
    if (this.allocateWaterfallTarget.checked) {
      this.automaticSectionTarget.classList.remove('hidden');
      if (this.automaticOnTarget.checked) {
        this.waterfallSectionTarget.classList.remove('hidden');
      } else {
        this.waterfallSectionTarget.classList.add('hidden');
      }
    } else {
      this.automaticSectionTarget.classList.add('hidden');
      this.waterfallSectionTarget.classList.add('hidden');
    }
  };
}
