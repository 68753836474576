import { Controller } from 'stimulus';

// controller: admin--tenant--manager-roles-copy-settings
// This controllers implements a system where a user can click multiple times
// on the same element (should be linked to the title of the modal) to enable
// development behaviour (similar to Android going into development mode)
// this to copy the settings for use in the management portal.
export default class extends Controller {
  static targets = [];

  number_of_clicks = 0

  timeout = null;

  connect() {
  }

  click() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => { this.number_of_clicks = 0; }, 5000);
    this.number_of_clicks += 1;

    if (this.number_of_clicks >= 10) {
      // eslint-disable-next-line no-console
      console.log(this.element.dataset);
      navigator.clipboard.writeText(this.element.dataset.managerRolesJson);
    }
  }
}
