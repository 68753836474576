import { Controller } from 'stimulus';

// controller: admin--tenant--clients
export default class extends Controller {
  static targets = ['addManagerButton']

  connect() {
  }

  confirmAddManagerAllowed(event) {
    event.preventDefault();

    const departmentAlreadySelected = this.addManagerButtonTarget.dataset.departmentAlreadySelected.toString();
    const showDepartmentWarning = (departmentAlreadySelected !== 'true');
    if (showDepartmentWarning) {
      this._showWarning('warningSelectDepartment');
      return;
    }

    const projectAlreadySelected = this.addManagerButtonTarget.dataset.projectAlreadySelected.toString();
    const showProjectWarning = (projectAlreadySelected !== 'true');
    if (showProjectWarning) {
      this._showWarning('warningSelectProject');
    }
  }

  _showWarning(warningKey) {
    const warning = this.addManagerButtonTarget.dataset[warningKey];
    alert(warning);
  }
}
