import { Controller } from 'stimulus';
// Controller used to hide/show options of a list .
export default class extends Controller {
  static targets = ['icon', 'option', 'input']

  togglCollapsibleSection = (event) => {
    event.preventDefault();
    this.togglListState();
  }

  togglListState() {
    this.optionTargets.forEach((option) => {
      if (option.classList.contains('hidden')) {
        this.iconTarget.classList.remove('mdi-chevron-down');
        this.iconTarget.classList.add('mdi-chevron-up');

        this.inputTarget.classList.remove('hidden');
        option.classList.remove('hidden');
      } else {
        this.iconTarget.classList.remove('mdi-chevron-up');
        this.iconTarget.classList.add('mdi-chevron-down');

        this.inputTarget.classList.add('hidden');
        option.classList.add('hidden');
      }
    });
  }
}
