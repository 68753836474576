import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['anyChangesCheckbox', 'nestedCheckbox'];

    toggleAnyChangesCheckbox = (_event) => {
      const somethingChanged = this.nestedCheckboxTargets.some((el) => (el.checked === true));

      this.anyChangesCheckboxTarget.checked = somethingChanged;
    }

    checkAllNested = (event) => {
      if (event.target.checked === false) {
        // uncheck all nested descendants
        this.nestedCheckboxTargets.forEach((el) => {
          el.checked = false;
          el.dispatchEvent(new CustomEvent('click'));
        });
      }
    }
}
