import ScheduleController from 'base/schedule_controller';
import { animateEvent, distinct } from '../base/beeple_lib';

export default class extends ScheduleController {
  static targets = ['form', 'output', 'startDate', 'endDate',
    'actionPanel', 'actionPanelOutput',
    'sortableGroupingItems',
    'toasterContent',
    'multiDateSelect',
    'sidePanel',
    'displayHoursTo',
    'showGuidelinesInput',
    'planningToggleModeButton',
    'planningToggleVolunteerModeButton']

  connect() {
    this.selectedVolunteerIds = [];
    super.connect();
  }

  contentLoaded() {
    if (this.element.dataset.planningVolunteerSelectMode == 'true') {
      for (const volunteer_id of this.selectedVolunteerIds) {
        for (const handle of document.querySelectorAll(`[data-collaborators-schedule-select-volunteer-id="${volunteer_id}"] .volunteer-selection-handle`)) {
          handle.classList.toggle('selected', true);
        }
      }
    }
  }

  refreshContent = () => {
    this._refreshContent();
  }

  actionFeedback = (data) => {
    if (data.toaster_success) {
      this._showToaster(data.toaster_success, 'success');
    }

    if (data.toaster_error) {
      this._showToaster(data.toaster_error, 'error');
    }

    for (const refresh_tag of document.querySelectorAll('.planning-schedule-suggestions-refresh')) {
      const form_refresh = this.application.getControllerForElementAndIdentifier(refresh_tag, 'form-refresh');
      if (form_refresh) {
        form_refresh.refreshContent();
      }
    }

    if (data.full_refresh) {
      this._refreshContent();

      const form_refresh_controller = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller="form-refresh"][id*="suggestions-"]'), 'form-refresh');
      if (form_refresh_controller != undefined) {
        form_refresh_controller.executeRequest();
      }
      return;
    }

    if (data.open_panel != undefined) {
      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
      const new_panel = side_panel_controller.createSecondaryTab(data.open_panel.main_tab_id, data.open_panel.secondary_tab_id, data.open_panel.tab_label, null);
      new_panel.innerHTML = data.open_panel.content;
    }

    if (data.removed_team_registrations) {
      for (const reg_id of data.removed_team_registrations) {
        for (const reg_element of this.element.querySelectorAll(`[data-planning-schedule-team-registration-id="${reg_id}"]`)) {
          const element = reg_element.closest('.content-cel');
          reg_element.remove();
          if (element != null) {
            animateEvent(element, 'removed_team_registrations');
          }
        }
      }
    }

    if (data.new_team_registrations) {
      for (const new_team_registration of data.new_team_registrations) {
        /**/
      }
    }
  }

  togglePlanningMode = (event) => {
    event.preventDefault();
    const current_active = this.planningToggleModeButtonTarget.classList.contains('active');

    if (current_active) {
      this._disablePlanningMode();
    } else {
      this._enablePlanningMode();
    }
  }

  _enforcePlanningMode() {
    const current_active = this.planningToggleModeButtonTarget.classList.contains('active');

    if (current_active) {
      this._refreshPlanningPanelContent();
      return;
    }

    this._enablePlanningMode();
  }

  _enablePlanningMode() {
    this.planningToggleModeButtonTarget.classList.toggle('active', true);
    this._refreshPlanningPanelContent();

    /* Check if there are any teams selected, and if so highlight them */
    if (this.element.dataset.planningVolunteerSelectMode == 'true') {
      this._enablePlanningVolunteerSelectMode();
    }
  }

  _disablePlanningMode() {
    this.planningToggleModeButtonTarget.classList.toggle('active', false);
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    for (const team_card of this.element.querySelectorAll('.team-shift-card.selected')) {
      team_card.classList.toggle('selected', false);
    }

    side_panel_controller.closeSecondaryTabById(
      'planning',
      '',
    );
  }

  togglePlanningVolunteerSelectMode = (event) => {
    event.preventDefault();

    const multiSelect = this.element.dataset.planningVolunteerSelectMode == 'true';
    if (multiSelect) {
      this.selectedVolunteerIds = [];
      this.element.dataset.planningVolunteerSelectMode = 'false';
    } else {
      this.element.dataset.planningVolunteerSelectMode = 'true';
    }

    this.planningToggleVolunteerModeButtonTarget
      .classList.toggle('active', !multiSelect);
    this._highlightSelectedVolunteerIds();
    this._refreshPlanningPanelContent();
  }

  _enablePlanningVolunteerSelectMode() {
    this.element.dataset.planningVolunteerSelectMode = 'true';
    this.planningToggleVolunteerModeButtonTarget.classList.toggle('active', true);

    for (const volunteer_id of this.selectedVolunteerIds) {
      for (const handle of document.querySelectorAll(`[data-collaborators-schedule-select-volunteer-id="${volunteer_id}"] .volunteer-selection-handle`)) {
        handle.classList.toggle('selected', true);
      }
    }
  }

  toggleHourGrid = (event) => {
    this.showGuidelinesInputTarget.value = event.target.checked ? '1' : '0';

    this.element.classList.toggle('display-hour-grid', event.target.checked);

    const form_refresh = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    form_refresh.executeRequest(true);
  }

  limitDisplayHoursTo = (event) => {
    for (const option of this.displayHoursToTarget.querySelectorAll('option')) {
      option.disabled = (parseInt(option.value) <= parseInt(event.target.value));
    }

    if (parseInt(this.displayHoursToTarget.value) <= parseInt(event.target.value)) {
      this.displayHoursToTarget.value = 24;
    }

    $(this.displayHoursToTarget).select2({ theme: 'bootstrap' });
  }

  highlightTeamApplication = (event) => {
    for (const team_application of this.element.querySelectorAll(`[data-availability-team-application-id="${event.target.dataset.teamApplicationId}"]`)) {
      team_application.classList.toggle('hide', false);
    }
  }

  deHighlightTeamApplication = (event) => {
    for (const team_application of this.element.querySelectorAll(`[data-availability-team-application-id="${event.target.dataset.teamApplicationId}"]`)) {
      team_application.classList.toggle('hide', true);
    }
  }

  directEnrolment = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const target = event.target.closest('[data-planning-schedule-team-registration-url]');

    const main_tab_id = target.dataset.planningScheduleMainTabId;
    const secondary_tab_id = target.dataset.planningScheduleSuggestionId;
    const secondary_tab_label = target.dataset.planningScheduleTabLabel;
    const secondary_tab_url = target.dataset.planningScheduleTeamRegistrationUrl;
    side_panel_controller.createSecondaryTab(
      'new-teamregistration',
      secondary_tab_id,
      secondary_tab_label,
      secondary_tab_url,
      true,
      null,
      null,
    );
  }

  directEnrolments = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const card = event.target.closest('[data-collaborators-schedule-volunteer-ids]');
    const team_id = card.dataset.collaboratorsScheduleTeamId;

    for (const volunteer_id of card.dataset.collaboratorsScheduleVolunteerIds.split(';')) {
      side_panel_controller.createSecondaryTab(
        'new-teamregistration',
        `${card.dataset.collaboratorsScheduleVolunteerId}-${volunteer_id}`,
        card.dataset[`collaboratorsScheduleTabLabel-${volunteer_id}`],
        card.dataset[`collaboratorsScheduleTeamRegistrationUrl-${volunteer_id}`],
        true,
        null,
        team_id,
      );
    }
  }

  startMultiEnrolmentsFromSelection = (event) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_suggestions = selections.querySelectorAll('[name="team_ids[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    for (const selected_suggestion of selected_suggestions) {
      const team_id = selected_suggestion.value;
      const card = selected_suggestion.closest('[data-collaborators-schedule-volunteer-ids]');
      for (const volunteer_id of card.dataset.collaboratorsScheduleVolunteerIds.split(';')) {
        side_panel_controller.createSecondaryTab(
          'new-teamregistration',
          `${card.dataset.collaboratorsScheduleTeamId}-${volunteer_id}`,
          card.dataset[`collaboratorsScheduleTabLabel-${volunteer_id}`],
          card.dataset[`collaboratorsScheduleTeamRegistrationUrl-${volunteer_id}`],
          true,
          null,
          team_id,
        );
      }
    }
  }

  startEnrolmentsFromSelection = (event) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_suggestions = selections.querySelectorAll('[name="team_ids[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    for (const selected_suggestion of selected_suggestions) {
      const card = selected_suggestion.closest('[data-planning-schedule-team-registration-url]');
      side_panel_controller.createSecondaryTab(
        'new-teamregistration',
        card.dataset.planningScheduleSuggestionId,
        card.dataset.planningScheduleTabLabel,
        card.dataset.planningScheduleTeamRegistrationUrl,
        true,
        null,
        null,
      );
    }
  }

  startActionTeamApplicationsFromSelection = (event, action_name, url_data_prefix, tab_name, is_post) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_teams = selections.querySelectorAll('input[name="team_applications[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    for (const selected_team of selected_teams) {
      const card = selected_team.closest('.card');
      const team_id = card.dataset.planningScheduleTeamId;
      const volunteer_id = card.dataset.planningScheduleVolunteerId;
      side_panel_controller.createSecondaryTab(
        tab_name,
        card.dataset.planningScheduleSecondaryTabId,
        card.dataset.planningScheduleTabLabel,
        card.dataset[url_data_prefix],
        is_post,
        null,
        team_id,
        volunteer_id,
        null,
        card.dataset.planningScheduleSecondaryTabId,
      );
    }
  }

  startAcceptTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'collaborators-schedule-accept-team-application-url', 'planningScheduleAcceptTeamApplicationUrl', 'accept-team-application', true);
  }

  startRejectTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'collaborators-schedule-reject-team-application-url', 'planningScheduleRejectTeamApplicationUrl', 'reject-team-application', false);
  }

  startStandbyTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'collaborators-schedule-standby-team-application-url', 'planningScheduleStandbyTeamApplicationUrl', 'standby-team-application', false);
  }

  selectVolunteer = (event) => {
    event.preventDefault();

    const selected_volunteer = event.target.closest('[data-collaborators-schedule-select-volunteer-id]');
    const volunteer_id = selected_volunteer.dataset.collaboratorsScheduleSelectVolunteerId;

    const previously_selected = this.selectedVolunteerIds.includes(volunteer_id);

    if (this.element.dataset.planningVolunteerSelectMode != 'true') {
      this.selectedVolunteerIds = [];
      if (!previously_selected) {
        this.selectedVolunteerIds.push(volunteer_id);
      }
    } else if (previously_selected) {
      this.selectedVolunteerIds = this.selectedVolunteerIds.filter((ele) => ele != volunteer_id);
    } else {
      this.selectedVolunteerIds.push(volunteer_id);
    }

    this._highlightSelectedVolunteerIds();
    this._enforcePlanningMode();
  }

  _highlightSelectedVolunteerIds = () => {
    for (const handle of document.querySelectorAll('.volunteer-selection-handle')) {
      const selected_volunteer = handle.closest('[data-collaborators-schedule-select-volunteer-id]');
      const volunteer_id = selected_volunteer.dataset.collaboratorsScheduleSelectVolunteerId;
      const is_selected = this.selectedVolunteerIds.includes(volunteer_id);
      handle.classList.toggle('selected', is_selected);
    }
  }

  selectVolunteerId = (volunteer_id) => {
    return;
    /* eslint-disable no-unreachable */
    for (const handle of document.querySelectorAll('.volunteer-selection-handle')) {
      handle.classList.toggle('selected', false);
    }

    if (volunteer_id) {
      for (const handle_parent of document.querySelectorAll(`[data-collaborators-schedule-select-volunteer-id="${volunteer_id}"]`)) {
        for (const handle of handle_parent.querySelectorAll('.volunteer-selection-handle')) {
          handle.classList.toggle('selected', true);
        }
      }
    }
    /* eslint-enable no-unreachable */
  }

  selectTeamRegistrationId = (team_registration_id) => {
    for (const handle of document.querySelectorAll('.cell-entry.team-registration')) {
      handle.classList.toggle('selected', handle.dataset.planningScheduleTeamRegistrationId == team_registration_id);
    }
  }

  selectTeamApplicationId = (team_application_id) => {
    for (const handle of document.querySelectorAll('.cell-entry.team-application')) {
      handle.classList.toggle('selected', handle.dataset.planningScheduleTeamApplicationId == team_application_id);
    }
  }

  replaceTeamRegistrationAfterDrop = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();

    const data = event.dataTransfer.getData('beeple/move-team-registration');

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', false);

    const target_team_registration_url = real_target.dataset.planningScheduleTeamRegistrationReplaceDirectUrl;
    const source_team_id = data.split(';')[0];
    const source_team_volunteer_id = data.split(';')[2];

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    side_panel_controller.createSecondaryTab(
      'replace',
      data.split(';')[3],
      data.split(';')[4],
      target_team_registration_url,
      false,
      {
        source_team: source_team_id,
        new_volunteer_id: source_team_volunteer_id,
      },
      source_team_id,
    );
  }

  startMovingTeamRegistration = (event) => {
    const real_source = this.teamRegistrationMoveSource(event.target);
    event.dataTransfer.setData('beeple/move-team-registration',
      [
        real_source.closest('[data-beeple-team-id]').dataset.beepleTeamId,
        real_source.dataset.planningScheduleTeamRegistrationMoveDirectUrl,
        real_source.dataset.planningScheduleVolunteerId,
        real_source.dataset.planningScheduleTabId,
        real_source.dataset.planningScheduleTabLabel,
        real_source.dataset.planningScheduleHappeningId,
      ].join(';'));
  }

  checkTeamRegistrationDragEnter = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', true);
  }

  checkTeamRegistrationDragOver = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();
  }

  checkTeamRegistrationDragExit = (event) => {
    event.preventDefault();

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', false);
  }

  allowMoveTeamRegistrationEvent(event) {
    if (!event.dataTransfer.types.includes('beeple/move-team-registration')) {
      return false;
    }
    const data = event.dataTransfer.getData('beeple/move-team-registration');
    const team_id = data.split(';')[0];
    const real_target = this.teamRegistrationMoveTarget(event.target);
    if (real_target.dataset.beepleTeamId == team_id) {
      return false;
    }
    if (real_target.dataset.planningScheduleVolunteerId != undefined) {
      /* This is a replace. check if same project */
      const happening_id = data.split(';')[5];
      if (real_target.dataset.planningScheduleHappeningId != happening_id) {
        return false;
      }
    }

    return true;
  }

  teamRegistrationMoveTarget(target) {
    if (target.nodeName == '#text') {
      target = target.parentNode;
    }
    return target.closest('.cell-entry');
  }

  teamRegistrationMoveSource(target) {
    if (target.nodeName == '#text') {
      target = target.parentNode;
    }
    return target.closest('.cell-entry');
  }

  startDragPlanning = (event) => {
    const volunteer_ids = event.target.dataset.collaboratorsScheduleHighlightVolunteerIds;
    const volunteer_ids_for_application = event.target.dataset.collaboratorsScheduleTeamApplicationVolunteerIds;

    for (const volunteer_id of volunteer_ids.split(',')) {
      for (const volunteer_container of document.querySelectorAll(`[data-collaborators-schedule-select-volunteer-id="${volunteer_id}"]`)) {
        volunteer_container.classList.toggle('highlight', true);
      }
    }
    this.element.classList.toggle('planning-mode', true);
    this.dragVolunteerLeaveExitCounter = 0;
    event.dataTransfer.setData('beeple/apply-suggestion',
      [
        volunteer_ids,
        event.target.dataset.collaboratorsScheduleTeamId,
        event.target.dataset.collaboratorsScheduleTeamName,
        volunteer_ids_for_application,
      ].join(';'));
  }

  endDragPlanning = (event) => {
    this.element.classList.toggle('planning-mode', false);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    for (const volunteer_container of this.element.querySelectorAll('.highlight')) {
      volunteer_container.classList.toggle('highlight', false);
      volunteer_container.classList.toggle('currentselected', false);
    }
    event.preventDefault();
  }

  findVolunteerDragTarget(targetish) {
    if (targetish.nodeName == '#text') {
      targetish = targetish.parentElement;
    }
    return targetish.closest('[data-collaborators-schedule-select-volunteer-id]');
  }

  volunteerIsValidDropTarget(target, data) {
    const volunteer_ids = data.split(';')[0].split(',');
    return volunteer_ids.includes(target.dataset.collaboratorsScheduleSelectVolunteerId);
  }

  dragEnterVolunteer = (event) => {
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    const target = this.findVolunteerDragTarget(event.target);

    if (this.volunteerIsValidDropTarget(target, data)) {
      if (this.dragVolunteerLeaveExitCounter == 0) {
        target.classList.toggle('currentselected', true);
      }
      this.dragVolunteerLeaveExitCounter += 1;
    }
    event.preventDefault();
  }

  dragOverVolunteer = (event) => {
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    const target = this.findVolunteerDragTarget(event.target);

    if (this.volunteerIsValidDropTarget(target, data)) {
      event.preventDefault();
    }
  }

  dragLeaveVolunteer = (event) => {
    const target = this.findVolunteerDragTarget(event.target);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    if (!this.volunteerIsValidDropTarget(target, data)) {
      return;
    }
    this.dragVolunteerLeaveExitCounter -= 1;
    if (this.dragVolunteerLeaveExitCounter == 0) {
      target.classList.toggle('currentselected', false);
    }
  }

  dropVolunteer = (event) => {
    const target = this.findVolunteerDragTarget(event.target);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');

    const [volunteer_ids, team_id, team_name, volunteer_ids_for_applications] = data.split(';');
    const volunteer_id = target.dataset.planningScheduleVolunteerSelectId;
    if (volunteer_ids_for_applications.split(',').map((x) => x.split('-')[0]).includes(volunteer_id)) {
      throw new Error('TO IMPLEMENT APPLICATION');
    } else {
      const secondary_tab_url = target.dataset.collaboratorsScheduleTeamRegistrationUrl.replace('TEAM_ID', team_id);

      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

      const secondary_tab_id = volunteer_id;
      const secondary_tab_label = target.dataset.collaboratorsScheduleTeamRegistrationLabel.replace('TEAM_NAME', team_name);
      side_panel_controller.createSecondaryTab(
        'new-teamregistration',
        secondary_tab_id,
        secondary_tab_label,
        secondary_tab_url,
        true,
        null,
        target.dataset.planningScheduleTeamSelectId,
      );
    }
  }

  _refreshPlanningPanelContent() {
    if (this.element.dataset.planningVolunteerSelectMode == 'true' || this.selectedVolunteerIds.length == 1) {
      if (this.selectedVolunteerIds.length == 1) {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'planning',
          '',
          '',
          this.element.dataset.collaboratorsScheduleVolunteerUrl.replace('VOLUNTEER_ID', this.selectedVolunteerIds[0]),
          false,
        );
      } else {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'planning',
          '',
          '',
          this.element.dataset.collaboratorsSchedulePlanningUrl.replace('SELECTED_VOLUNTEER_IDS', this.selectedVolunteerIds.join(',')),
          true,
        );
      }
    } else {
      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
      side_panel_controller.createSecondaryTab(
        'planning',
        '',
        '',
        this.element.dataset.collaboratorsSchedulePlanningUrl.replace('SELECTED_VOLUNTEER_IDS', ''),
        true,
      );
    }
  }

  _refreshSidePanelForChangedPeriod() {
    const suggestions_panel = this.application.getControllerForElementAndIdentifier(document.querySelector('[name="planning-suggestions-filter"]'), 'form-refresh');
    if (suggestions_panel) {
      setTimeout(() => { suggestions_panel.refreshContent(); }, 1000);
    }

    const team_applications_panel = this.application.getControllerForElementAndIdentifier(document.querySelector('[name="planning-team-applications-filter"]'), 'form-refresh');
    if (team_applications_panel) {
      setTimeout(() => { team_applications_panel.refreshContent(); }, 1200);
    }
  }
}
