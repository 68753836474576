import { Controller } from 'stimulus';

/** The preselector controller has an input field and (generally) a list.
 * One can preselect something from the list
 *
 * Targets:
 *   - input: The input field form, can generally be of any type.
 *            The value will be set verbatim.
 *
 * Handlers:
 *   - usePreselect (usually invoked with the "click" event):
 *                 fills the
 *
 * Data attributes: (unless mentioned otherwise always on component level)
 *  - preselector-value: (on the object that triggered the usePreselect, or any of its parents).
                          The value of the preselecotr
*/
export default class extends Controller {
  static targets = ['input']

  usePreselect = (event) => {
    event.preventDefault();

    let { target } = event;
    if (!event.target.dataset.preselectValue) {
      target = target.closest('[data-preselect-value]');
    }

    this.inputTarget.value = target.dataset.preselectValue;
  }
}
