import { Controller } from 'stimulus';

// # The limited fields controller has 2 connected fields
// # * Field one (note it could be a set of fields) is the selector.
// #         Note: This should be a select field (in the future we might add checkbox fields as well)
// #  Field two is the recipient, This should be a date / datetime field (In the future we could also implement numeric fields)
// # The min (can be extended to max) of the recipient will be based on the selected value of the selector.
// #
// # The method updateRecipientLimits can be used as data-action
// # It is generally not needed to link to this event yourself as this controller
// # will link to the update event itself (mostly to work around a timing issue)
export default class extends Controller {
  static targets = ['selector', 'recipient']

  connect() {
    const selectors = this.selectorTargets;
    for (const selector in selectors) {
      $(selector).change(this.updateRecipientLimits);
    }

    this.updateRecipientLimits();
  }

  updateRecipientLimits(event) {
    const selectors = this.selectorTargets;
    const recipient = this.recipientTarget;

    for (const selector of selectors) {
      if (selector.disabled) {
        break;
      }
      for (const option in selector.getElementsByTagName('option')) {
        if (option.selected) {
          recipient.dataset.datepickerMinDate = option.dataset.limitedFieldsMin;
          recipient.dataset.datepickerMaxDate = option.dataset.limitedFieldsMax;

          $(recipient).datepicker('option', 'minDate', moment(recipient.dataset.datepickerMinDate, 'YYYY/MM/DD').toDate());
          $(recipient).datepicker('option', 'maxDate', moment(recipient.dataset.datepickerMaxDate, 'YYYY/MM/DD').toDate());
        }
      }
    }
  }
}
