import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['unreadCountBadge']

  initialize() {
    this.last_time = 0;
  }

  connect() {
    const current_time = new Date().getTime();
    if (current_time > this.last_time + 60 * 1000) {
      this.last_time = current_time;

      if (!this.chatNotificationEndpointElement()) return; // tenant uses_chat_sendbird

      if (this.multipleChatAccountsElement()) {
        this.updateCounterBadgeForMultipleAccounts();
      } else {
        this.updateCounterBadgeForSingleAccount();
      }
    }
  }

  chatNotificationEndpointElement() {
    return document.querySelector('meta[name="chat-notification-endpoint"]');
  }

  chatNotificationEndpoint() {
    let endpoint = this.chatNotificationEndpointElement().content;

    if (!endpoint.startsWith('http')) {
      endpoint = `${window.location.protocol == 'https:' ? 'https:' : 'http:'}//${endpoint}`;
    }

    return endpoint;
  }

  multipleChatAccountsElement() {
    return document.querySelector('meta[name="chat-accounts"]');
  }

  multipleChatAccountsContent() {
    return this.multipleChatAccountsElement().content;
  }

  updateCounterBadgeForMultipleAccounts() {
    $.ajax({
      url: `${this.chatNotificationEndpoint()}/api/v1/accounts/unread.json`,
      type: 'POST',
      data: JSON.stringify({ accounts: JSON.parse(this.multipleChatAccountsContent()) }),
      dataType: 'json',
      contentType: 'application/json',
      unreadCountBadgeTarget: this.unreadCountBadgeTarget,
    }).done(this.updateCounter);
  }

  updateCounterBadgeForSingleAccount() {
    const chatUserId = document.querySelector('meta[name="chat-user-id"]').content;
    const chatUserAccessToken = document.querySelector('meta[name="chat-user-access-token"]').content;

    $.ajax({
      url: `${this.chatNotificationEndpoint()}/api/v1/accounts/${chatUserId}/unread?access_token=${chatUserAccessToken}`,
      unreadCountBadgeTarget: this.unreadCountBadgeTarget,
    }).done(this.updateCounter);
  }

  updateCounter(data) {
    const unreadCount = data.unread_messages;

    this.unreadCountBadgeTarget.innerHTML = unreadCount;
    this.unreadCountBadgeTarget.classList.toggle('hide', unreadCount == 0);
  }
}
