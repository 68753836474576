import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['newStartDate']

  onHappeningOrClusterSelected(event) {
    const index = event.target.options.selectedIndex;
    const optionSelected = event.target.options[index];
    if (optionSelected) {
      const minDate = optionSelected.dataset.limitedFieldsMin; // YYYY-MM-DD?
      const maxDate = optionSelected.dataset.limitedFieldsMax; // YYYY-MM-DD?
      $(this.newStartDateTarget).datepicker('option', 'minDate', new Date(minDate));
      $(this.newStartDateTarget).datepicker('option', 'maxDate', new Date(maxDate));
    }
  }
}
