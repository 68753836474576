import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['inviteChannel', 'alertChannel', 'triggerInviteButton'];

  channelSelected() {
    const inviteChannelSelected = this.inviteChannelTargets.filter((el) => el.checked).length > 0;
    const alertChannelSelected = this.alertChannelTargets.filter((el) => el.checked).length > 0;
    this.triggerInviteButtonTarget.disabled = !(inviteChannelSelected || alertChannelSelected);
  }
}
