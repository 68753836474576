import { Controller } from 'stimulus';

// controller: customer-portal--managers
export default class extends Controller {
  static targets = [
    'collaboratorSelect',
    'roleSelect',
    'roleRegularInputGroup',
    'roleNameInputGroup',
    'roleName',
    'roleErrorMessage',
    'submitButton']

  connect() {
  }

  checkIfCollaboratorIsAlreadySelected = (event) => {
    const selectedCollaborator = this.collaboratorSelectTarget.value;
    if (selectedCollaborator === '') {
      event.preventDefault();
      event.stopImmediatePropagation();
      const { warningSelectCollaborator } = this.roleSelectTarget.dataset;
      alert(warningSelectCollaborator);
    }
  }

  checkAssignedRole = (event) => {
    event.preventDefault();
    const selectedCollaborator = this.collaboratorSelectTarget.value;
    const validRoles = this.roleSelectTarget.dataset.allowedRoles.split(',');
    const outerThis = this;

    this._resetForm();

    if (selectedCollaborator !== '') {
      // read details about this collaborator's (admin) role
      $.ajax({
        url: `/admin/volunteers/${selectedCollaborator}/role_detail`,
        type: 'GET',
        success(roleDetails) {
          if (validRoles.includes(roleDetails.id.toString())) {
            outerThis._showRoleName(roleDetails.id, roleDetails.name,
              false);
            outerThis.submitButtonTarget.disabled = false;
          } else {
            outerThis._showRoleName(roleDetails.id, roleDetails.name,
              true);
            outerThis.submitButtonTarget.disabled = true;
          }
        },
        error(jqXHR) {
          if (jqXHR.status === 404) {
            // admin has no role, just show the list of available roles to allow a selection
            outerThis._showRoleInput();
            outerThis.submitButtonTarget.disabled = false;
          }
        },
      });
    }
  }

  _resetForm() {
    this._showRoleInput();
  }

  _showRoleInput() {
    this.roleRegularInputGroupTarget.classList.remove('hide');
    this._hideRoleName();
  }

  _showRoleName(id, name, showError) {
    this._hideRoleInput();
    this.roleNameTarget.innerHTML = name;
    this.roleSelectTarget.value = id;
    this.roleNameInputGroupTarget.classList.remove('hide');
    if (showError) {
      this.roleErrorMessageTarget.classList.remove('hide');
    }
  }

  _hideRoleInput() {
    this.roleRegularInputGroupTarget.classList.add('hide');
  }

  _hideRoleName() {
    this.roleNameTarget.innerHTML = '';
    this.roleNameInputGroupTarget.classList.add('hide');
  }
}
