import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['requiresApproval', 'allowEntryByVolunteer', 'payrollCodesTable', 'enterPerWarning',
      'applicableForSocialStatusesInput', 'alreadyUsedBySocialStatusesWarning',
      'allowedToBeOverlapped', 'overlappableTypesTable', 'warningWillRemoveOverlappedFutureAvailabilities'];

    connect() {
      this.checkOverlappableTypesVisibility();
    }

  checkOverlappableTypesVisibility = (event) => {
    if (this.hasAllowedToBeOverlappedTarget) {
      const hideTable = !this.allowedToBeOverlappedTarget.checked;
      if (this.hasOverlappableTypesTableTarget) {
        this.overlappableTypesTableTarget.classList.toggle('hide', hideTable);
      }
      if (event) {
        this.warningWillRemoveOverlappedFutureAvailabilitiesTarget.classList.toggle(
          'hide', !hideTable,
        );
      }
      document.querySelectorAll('label.beeple-disabled > input[type="radio"]').forEach((el) => {
        el.disabled = 'disabled';
      });
    }
  };

    showEnterPerWarningOnChange = (event) => {
      if (this.hasEnterPerWarningTarget) {
        if (event.target.dataset.initialValue !== event.target.value) {
          this.enterPerWarningTarget.classList.remove('hide');
        } else {
          this.enterPerWarningTarget.classList.add('hide');
        }
      }
    }

    checkIfAlreadyUsed = (event) => {
      let { alreadyUsedBySocialStatuses } = this.applicableForSocialStatusesInputTarget.dataset;
      if (alreadyUsedBySocialStatuses == null) return;
      const unselectedValue = event.detail.event.params.args.data.element.value;
      if (unselectedValue == null) return;
      alreadyUsedBySocialStatuses = alreadyUsedBySocialStatuses.split(',');

      if (alreadyUsedBySocialStatuses.includes(unselectedValue)) {
        this.alreadyUsedBySocialStatusesWarningTarget.classList.remove('hide');
        event.preventDefault();
      }
    }
}
