import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['repeatPeriod', 'weeklyRepeatsOn', 'monthlyRepeatSystem', 'repeatUntil', 'endDate', 'occurrences', 'summary', 'repeatsEvery'];

    connect() {
      this.refreshSummary();
    }

    setWeeklyRepeatsOn = (event) => {
      if (!this.hasWeeklyRepeatsOnTarget) {
        return;
      }

      const start_date = $(this.element.closest('form').querySelector("[name='presenter_for_volunteers_availability[starts_at]']")).datepicker('getDate');
      const dayOfWeek = start_date.getDay();
      const checkBoxes = this.element.querySelectorAll("[name='volunteers_availability[repetition_schedule_attributes][weekly_repeats_on][]']");
      if (!Array.from(checkBoxes).some((el) => el.checked === true)) {
        const checkBox = this.element.querySelector(`[name='volunteers_availability[repetition_schedule_attributes][weekly_repeats_on][]'][value='${dayOfWeek}']`);
        checkBox.setAttribute('checked', true);
      }
      this.refreshSummary(event);
    }

    setWeeklyRepeatsOn = (event) => {
      if (!this.hasWeeklyRepeatsOnTarget) {
        return;
      }

      const start_date = $(this.element.closest('form').querySelector("[name='presenter_for_volunteers_availability[starts_at]']")).datepicker('getDate');
      const dayOfWeek = start_date.getDay();
      const checkBoxes = this.element.querySelectorAll("[name='volunteers_availability[repetition_schedule_attributes][weekly_repeats_on][]']");
      if (!Array.from(checkBoxes).some((el) => el.checked === true)) {
        const checkBox = this.element.querySelector(`[name='volunteers_availability[repetition_schedule_attributes][weekly_repeats_on][]'][value='${dayOfWeek}']`);
        checkBox.setAttribute('checked', true);
      }
      this.refreshSummary(event);
    }

    refreshSummary = (_event) => {
      if (!this.hasEndDateTarget) {
        return;
      }

      const start_date = $(this.element.closest('form').querySelector("[name='presenter_for_volunteers_availability[starts_at]']")).datepicker('getDate');
      $(this.endDateTarget).datepicker('option', 'minDate', start_date);
      const end_date = $(this.endDateTarget).datepicker('getDate');

      let occurrences = this.occurrencesTarget.value;
      let repeats_every = this.repeatsEveryTarget.value;
      if (repeats_every === '') {
        this.repeatsEveryTarget.value = 1;
        repeats_every = 1;
      } else {
        repeats_every = parseInt(this.repeatsEveryTarget.value);
      }

      const repeat_period = this.repeatPeriodTarget.value;

      const repeat_until = this.repeatUntilTarget.value;

      const system = this.monthlyRepeatSystemTargets.filter((v) => v.checked);

      let weekly_repeats_on_days;
      let week_number;
      let counter;
      let summary;

      if (repeats_every === 1) summary = i18n(['period', repeat_period, 'one']);
      else summary = i18n(['period', repeat_period, 'other'], { '%n': repeats_every });

      if (repeat_period === 'weekly') {
        weekly_repeats_on_days = [];
        this.weeklyRepeatsOnTargets
          .filter((v) => v.checked)
          .forEach((object) => weekly_repeats_on_days.push(moment.weekdays(parseInt(object.value))));
        if (weekly_repeats_on_days.length > 0) {
          summary += i18n(['weekly', 'on'], {
            '%days': weekly_repeats_on_days.join(', '),
          });
        }
      }

      if (repeat_period === 'monthly') {
        if (system.length > 0 && system[0].value === 'day_of_month') summary += i18n(['monthly', 'day'], { '%day': moment(start_date).date() });
        else if (system.length > 0 && system[0].value === 'week_of_month') {
          week_number = moment(start_date).week() - moment(start_date).startOf('month').week() + 1;
          counter = (() => {
            switch (week_number) {
              case 1:
                return 'first';
              case 2:
                return 'second';
              case 3:
                return 'third';
              case 4:
                return 'fourth';
              case 5:
                return 'last';
              default:
                throw new Error('Should not happen');
            }
          })();
          summary += i18n(['monthly', 'week', counter], { '%day': moment.weekdays(moment(start_date).day()) });
        }
      }

      if (repeat_until === 'number_of_repetitions') {
        if (isNaN(occurrences) || occurrences === null || occurrences === '') {
          this.occurrencesTarget.value = 1;
          occurrences = 1;
        }

        if (!isNaN(occurrences)) {
          summary += i18n(['times'], {
            '%times': occurrences,
          });
        }
      }

      summary += i18n(['since'], { '%date': moment(start_date).format('DD/MM/YYYY') });

      if (repeat_until.length > 0) {
        if (end_date) {
          summary += i18n(['till'], {
            '%date': moment(end_date).format('DD/MM/YYYY'),
          });
        }
      }
      this.summaryTarget.innerHTML = summary;

      const frm = document.querySelector("div[data-controller='availabilities-form']");
      if (frm !== null) {
        const formController = this.application.getControllerForElementAndIdentifier(frm, 'availabilities-form');
        formController.liveUpdateAvailability(new CustomEvent('change'));
      }
    };
}
