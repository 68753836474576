import merge from 'deepmerge';

export default class I18nTranslationMessageLoader {
  static load({ locale }) {
    /* Loading translations from the "main" locale place */
    const translationMessages = {};

    const mainTranslations = require.context('Locales/full', false, /.yml$/);
    const defaultAndCurrentLocaleRegexp = new RegExp(`^(en|${locale})`);

    mainTranslations.keys().forEach((pathName) => {
      const localeName = pathName.substring(2, pathName.length - 4);
      // we only want to load default en.yml locale and locale chosen by user only
      if (defaultAndCurrentLocaleRegexp.test(localeName)) {
        /* pathName is in the form of `./locale_name.yml` */
        translationMessages[localeName] = mainTranslations(pathName)[localeName];
      }
    });

    const newEnTranslation = require.context('Locales/new', false);
    newEnTranslation.keys().forEach((pathName) => {
      if (newEnTranslation(pathName).en !== undefined) {
        translationMessages.en = merge(translationMessages.en,
          newEnTranslation(pathName).en);
      }
    });

    const aliasedEnTranslation = require.context('Locales/', false, /aliases.en.yml$/);
    aliasedEnTranslation.keys().forEach((pathName) => {
      if (aliasedEnTranslation(pathName).en !== undefined) {
        translationMessages.en = merge(translationMessages.en,
          aliasedEnTranslation(pathName).en);
      }
    });
    return translationMessages;
  }
}
