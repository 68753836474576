import { Controller } from 'stimulus';

// The multiselector allows to select enrolments from projects-subprojects-team-enrolments dialog tree
//
// Example:
//
// Go to live mode (2/2) dialog example:
//
// [x] - means checked
// [ ] - means unchecked
//
// When tenant supports subprojects:
//
// Project             |     Subproject            |     Team
// --------------------------------------------------------------------------------------
// [x] Project 1       |     [x] Subproject 1      |     [x] Subproject 1
//                     |                           |        [x] Team A
//                     |                           |           [x] John Doe (enrolment)
//                     |                           |        [x] Team B
//                     |                           |           [x] Great User (enrolment)
//                     |                           |
// [x] Project 2       |     [x] Subproject 2      |     [x] Subproject 1
//                     |                           |        [x] Team C
//                     |                           |           [ ] Good User (enrolment)
//                     |                           |           [ ] Bad User (enrolment)
//                     |                           |
// [ ] Project 3       |                           |
// --------------------------------------------------------------------------------------
//
// When tenant does not subprojects:
//
// Project             |     Team
// ----------------------------------------------------------
// [x] Project 1       |     [x] Project 1
//                     |        [x] Team A
//                     |           [x] John Doe (enrolment)
//                     |        [x] Team B
//                     |           [x] Great User (enrolment)
//                     |
// [x] Project 2       |     [x] Project 1
//                     |        [x] Team C
//                     |           [ ] Good User (enrolment)
//                     |           [ ] Bad User (enrolment)
//                     |
// [ ] Project 3       |
// --------------------------------------------------------------------------------------
//
// Notes:
//
// 1. Elements having nested children are called "parent" elements.
//    The parent element has a `data-multi-level-selector-parent-name` attribute.
//
//    For example, "Project 1" is a parent element in a third column because it has Team A and Team B as children.
//    but "Project 1" not the parent element in the first and "Subproject 1" is not a parent in the second columns
//    as both has no children in these columns.
//
//    Elements could be parent and child at the same time:
//
//    * "Team A" is a parent element in the third column because it has "John Doe" enrolment as a child.
//    * "Team A" is also a child element of "Project 1" in the third column.
//
// 2. When element with the same data-node-id is checked/unchecked, all other elements with the same data-node-id are
//    checked/unchecked as well.
//
//    For example, when "Project 1" is unchecked in any column, all "Project 1" elements in other columns are unchecked.
export default class extends Controller {
    static targets = ['searchItem']

    connect() {
      for (const parent of document.querySelectorAll('[data-multi-level-selector-parent-name]')) {
        this._updateParentStatus(parent);
      }

      for (const parent of document.querySelectorAll('[data-multi-level-selector-target-id]')) {
        document.querySelector(`#${parent.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !parent.checked);
      }
    }

    search = (event) => {
      const search_text = event.target.value;

      for (const search_target of this.searchItemTargets) {
        if (search_text.length > 0) {
          search_target.classList.toggle('hidden', !(search_target.dataset.multiLevelSelectorSearch.indexOf(search_text) >= 0));
        } else {
          search_target.classList.toggle('hidden', false);
        }
      }
    }

    toggleLevel = (event) => {
      document.querySelector(`#${event.target.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !event.target.checked);
    }

    toggleChildren = (event) => {
      this._toggleChildren(event.target);
    }

    _toggleChildren(triggeringParent) {
      const selector = `[data-multi-level-selector-parent="${triggeringParent.dataset.multiLevelSelectorParentName}"]`;
      for (const child of document.querySelectorAll(selector)) {
        child.checked = triggeringParent.checked;

        if (child.dataset.multiLevelSelectorTargetId) {
          document.querySelector(`#${child.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !child.checked);
        }

        if (child.dataset.multiLevelSelectorParentName) {
          this._toggleChildren(child);
        }
      }
    }

    toggleParent = (event) => {
      const parentName = event.target.dataset.multiLevelSelectorParent;
      const parent = document.querySelector(`[data-multi-level-selector-parent-name="${parentName}"]`);
      this._updateParentStatus(parent);
    }

    _updateParentStatus(parent) {
      let all_checked = true;
      let all_unchecked = true;
      let any_indeterminate = false;
      const selector = `[data-multi-level-selector-parent="${parent.dataset.multiLevelSelectorParentName}"]`;
      for (const child of document.querySelectorAll(selector)) {
        if (child.checked) {
          all_unchecked = false;
        } else {
          all_checked = false;
        }

        if (child.indeterminate) {
          any_indeterminate = true;
        }
      }

      parent.indeterminate = (!all_checked && !all_unchecked) || any_indeterminate;
      if (all_checked) {
        parent.checked = true;
      }
      if (all_unchecked) {
        parent.checked = false;
      }

      if (parent.dataset.multiLevelSelectorTargetId) {
        document.querySelector(`#${parent.dataset.multiLevelSelectorTargetId}`).classList.toggle('hidden', !parent.checked);
      }

      if (parent.dataset.multiLevelSelectorParent) {
        const grandParent = document.querySelector(`[data-multi-level-selector-parent-name="${parent.dataset.multiLevelSelectorParent}"]`);
        this._updateParentStatus(grandParent);
      }
    }

  toggleNode = (event) => {
    const { nodeId } = event.target.dataset;
    const { checked } = event.target;

    this._toggleChildrenOf(nodeId, checked);
    this._toggleReplicasOf(nodeId, checked, event.target);
  }

  _toggleChildrenOf(parentId, checked) {
    document.querySelectorAll(`[data-parent-id='${parentId}']`)
      .forEach((el) => {
        const wrapper = el.closest('div.multi-selector-wrapper');
        if (wrapper) {
          wrapper.classList.toggle('hidden', !checked);
        }
        el.checked = checked;

        // Recursively toggle children
        //
        // Just changing visibiliy of team1 and team2 to be hidden does not help:
        // enrolments stay checked without recursive _toggleChildrenOf() call.
        //
        // Example:
        // + project is unchecked from the tree
        //   + team1 is unchecked by this _toggleChildrenOf(project-node-id, false) call
        //   + team2 is unchecked by this _toggleChildrenOf(project-node-id, false) call
        //      + enrolment should be uncheched as well by _toggleChildrenOf(team-node-id, false) call
        this._toggleChildrenOf($(el).data('node-id'), checked);
      });
  }

  _toggleReplicasOf(nodeId, checked, triggeringNode) {
    document.querySelectorAll(`[data-node-id='${nodeId}']`)
      .forEach((el) => {
        if (el !== triggeringNode) {
          const wrapper = el.closest('div.multi-selector-wrapper');
          if (wrapper) {
            wrapper.classList.toggle('hidden', !checked);
          }
          el.checked = checked;
        }
      });
  }
}
