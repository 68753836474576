import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['requiredProfileInputs',
      'replaceRequiredProfileDiv', 'removeRequiredProfileDiv',
      'replaceRequiredProfileInput', 'removeRequiredProfileInput',
      'replaceRequiredProfileInputLabel', 'removeRequiredProfileInputLabel'];

    toggleRequiredProfileInputs = (event) => {
      const el = event.target;
      if (el.dataset.valueBefore != null && el.value.trim() === el.dataset.valueBefore) {
        // no changes: just hide all conditional inputs
        this.requiredProfileInputsTarget.classList.add('hide');
        return;
      }

      const url = event.target.dataset.queryBulkEditVariableUrl;
      $.ajax({
        url,
        type: 'POST',
        data: {
          function_id: event.target.value,
          attribute_team_ids: event.target.dataset.attributeTeamIds,
        },
        success: (data) => {
          if (data.teams_default_profile_property_requirements_would_change === true && data.old_function_has_required_profile === true) {
            this.requiredProfileInputsTarget.classList.remove('hide');
          } else {
            this.requiredProfileInputsTarget.classList.add('hide');
            this.replaceRequiredProfileInputTarget.checked = false;
            this.removeRequiredProfileInputTarget.checked = false;
          }

          if (data.new_function_has_required_profile === true) {
            this.replaceRequiredProfileDivTarget.classList.remove('hide');
            this.removeRequiredProfileDivTarget.classList.add('hide');
            this.replaceRequiredProfileInputLabelTarget.innerHTML = data.label_for_replace;
            this.replaceRequiredProfileInputTarget.checked = true;
            this.removeRequiredProfileInputTarget.checked = false;
          } else {
            this.replaceRequiredProfileDivTarget.classList.add('hide');
            this.removeRequiredProfileDivTarget.classList.remove('hide');
            this.removeRequiredProfileInputLabelTarget.innerHTML = data.label_for_remove;
            this.replaceRequiredProfileInputTarget.checked = false;
            this.removeRequiredProfileInputTarget.checked = true;
          }
        },
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error querying requiredProfileChanges: ${textStatus}`, errorThrown);
        },
      });
    }
}
