import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['query'];

  connect() {
    this.search();

    const reset = this.element.parentElement.querySelector('a[data-beeple-toggle="reset"]');
    if (reset) {
      reset.onclick = () => this.search('reset');
    }
    const $resetAlls = $(document).find('a[data-beeple-toggle="reset-all"]');
    $resetAlls.each((ii, resetAll) => { resetAll.onclick = () => this.search('reset'); });
  }

  search = (trigger) => {
    const reset = (trigger === 'reset');
    const query = (this.hasQueryTarget && !reset) ? this.queryTarget.value.toLowerCase() : '';
    const $div = $(this.element.querySelector('div.check_boxes'));
    const $boxes = $div.find('span.checkbox');

    $boxes.each((ii, box) => {
      if (!box.dataset.order) {
        box.dataset.order = ii + 1;
      }
      const label = box.querySelector('label');
      const text = label ? label.innerText.toLowerCase() : '';
      const input = box.querySelector('input');
      const { checked } = input;
      box.dataset.checked = checked ? '0' : '1000000';
      if (text.includes(query) || checked) {
        box.style.display = 'block';
      } else {
        box.style.display = 'none';
      }
    });

    $boxes.sort((a, b) => {
      const ai = +a.dataset.checked + a.dataset.order;
      const bi = +b.dataset.checked + b.dataset.order;
      return ai - bi;
    }).appendTo($div);
  }
}
