import Vue from 'vue';
import VueI18n from 'vue-i18n';
import I18nLabelSubstitutionFormatter from 'bumblebee/helpers/i18n_label_substitution_formatter';
import I18nTranslationMessageLoader from 'bumblebee/helpers/i18n_translation_message_loader';

const tNamespaced = function (...args) {
  const [key, otherArgs] = args;
  // if we refer to localized (current component) key
  if (key[0] === '.') {
    // i.e. this.$options._componentTag returns 'delate-team'
    const currentComponentTag = this.$options._componentTag;
    const localKey = key.substring(1);
    const prefixedKey = `customer_portal.components.${currentComponentTag.replace(/-/g, '_')}.${localKey}`;
    const componentTranslation = this.$t(prefixedKey, otherArgs);
    return componentTranslation;
  }
  const prefixedKey = `customer_portal.${key}`;
  const result = this.$t(prefixedKey, otherArgs);
  // if we refer to existing key via alias
  if (result[0] === ':') {
    const aliasedKey = result.substring(1);
    const aliasedTranslation = this.$t(aliasedKey, otherArgs);
    return aliasedTranslation;
  }
  return result;
};

const currentLocale = document ? document.querySelector('html').getAttribute('lang') : 'en-GB';

Vue.use(VueI18n);
Vue.prototype.$tn = tNamespaced;
const translationMessages = I18nTranslationMessageLoader.load({ locale: currentLocale });
const i18nFormatter = new I18nLabelSubstitutionFormatter(/* here the constructor options */);
i18nFormatter.loadTranslations();
const i18n = new VueI18n({
  locale: currentLocale,
  formatter: i18nFormatter,
  messages: translationMessages,
});
VueI18n.prototype.tn = (...args) => {
  const [key, otherArgs] = args;
  const prefixedKey = `customer_portal.${key}`;
  const result = i18n.t(prefixedKey, otherArgs);
  // if we refer to existing key via alias
  if (result[0] === ':') {
    const aliasedKey = result.substring(1);
    const aliasedTranslation = i18n.t(aliasedKey, otherArgs);
    return aliasedTranslation;
  }
  return result;
};

export default i18n;
