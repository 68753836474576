import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['optionSelectList', 'customName', 'customNameInput'];

  connect() {
    if (this.hasOptionSelectListTarget) {
      const target = this.optionSelectListTarget;
      this.updateTargets(target);
    }
  }

  nameSelectChange = (event) => {
    const { target } = event;
    this.updateTargets(target);
  }

  updateTargets = (target) => {
    this.customNameTarget.classList.toggle('hide', target.value != '');
    this.customNameInputTarget.disabled = target.value != '';
  }
}
