import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['templateRow',
    'useInCalendarInput', 'useInTeamPlanningBoardInput']

  connect() {
    this.toggleUseInWidgets(); /* Make sure initial values are correct */
  }

  toggleUseInWidgets(event) {
    const any_row_in_use = this.templateRowTargets.some((x) => x.value.trim() != '');

    this.useInCalendarInputTarget.readOnly = any_row_in_use;
    this.useInCalendarInputTarget.disabled = any_row_in_use;

    if (any_row_in_use) {
      this.useInCalendarInputTarget.checked = false;
      this.useInTeamPlanningBoardInputTarget.checked = true;
    }
  }
}
