import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['unreadCount']

  connect() {
    this.api = this.element.dataset.chatApi; // beeple | sendbird
    this.userId = this.element.dataset.userId;
    this.userAccessToken = this.element.dataset.userAccessToken;
    this.departmentId = this.element.dataset.dId;
    this.chat_application = this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller~="chat"]'), 'chat');

    this.update();
  }

  openDepartment = (event) => {
    event.preventDefault();

    if (this._useSendbirdApi()) {
      // force re-initialization of chat app (app/assets/javascripts/chat/beeple-chat.js.coffee) with new params
      document.querySelector('meta[name="sendbird-user-id"]').setAttribute('content', this.userId);
      document.querySelector('meta[name="sendbird-user-token"]').setAttribute('content', this.userAccessToken);
      document.querySelector('meta[name="sendbird-d-id"]').setAttribute('content', this.departmentId);

      window.beeple_chat.stop();
      window.beeple_chat = null;
      // eslint-disable-next-line no-undef
      window.beeple_chat = new BeepleChat();
    } else {
      this.chat_application.openChatFor(this.userId, this.userAccessToken, this.departmentId);
    }
  }

  update() {
    if (this._useSendbirdApi()) {
      return;
    }

    // TODO: implement unread counts for department list
    if (this.element.dataset.unreadCount != undefined && this.element.dataset.unreadCount != 0) {
      this.unreadCountTarget.innerHTML = this.element.dataset.unreadCount;
    } else {
      this.unreadCountTarget.innerHTML = '';
    }
  }

  _useSendbirdApi = () => this.api === 'sendbird'
}
