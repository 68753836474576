import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['enableDisableSwitch', 'switchToLink'];

  connect() {
    if (this.hasEnableDisableSwitchTarget) {
      if (this.enableDisableSwitchTarget.dataset.value !== 'true') {
        this.enableDisableSwitchTarget.checked = 'checked';
      }
      this._toggleSwitchesVisibility();
    }
    $('body').tooltip({ selector: '[data-toggle="tooltip"]' });
  }

  updateLinkedWorkEnvironmentCollaborator = (event) => {
    event.stopPropagation();
    const url = this.enableDisableSwitchTarget.dataset.actionUrl;
    if (url !== undefined && url !== '') {
      $.ajax(url, {
        type: 'PATCH',
        data: { linked_work_environment_collaborator: { usage_disabled_by_collaborator: !this.enableDisableSwitchTarget.checked } },
      });
    }
    this._toggleSwitchesVisibility();
  };

  _toggleSwitchesVisibility() {
    if (this.hasSwitchToLinkTarget) {
      this.switchToLinkTarget.classList.toggle('hide',
        !this.enableDisableSwitchTarget.checked);
    }
    const { externalAppId } = this.enableDisableSwitchTarget.dataset;
    const dropdownLi = document.querySelector(`.external-application-li[data-external-app-id="${externalAppId}"]`);

    if (dropdownLi) {
      dropdownLi.classList.toggle('hide',
        !this.enableDisableSwitchTarget.checked);
    }
  }
}
