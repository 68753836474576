import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (this.element.dataset.warningValue === undefined) return;
    if (!this.element.dataset.warningText) return;

    this.element.onchange = (event) => this.check(event);
  }

  check = (event) => {
    const select = this.element;
    const { value } = select;
    const { warningValue, warningText } = this.element.dataset;

    if (warningValue.startsWith('!')) {
      if (value === warningValue.substr(1)) return;
    } else if (value !== warningValue) return;

    let showText = warningText.replaceAll(':value', value);
    const selectedOption = select.selectedOptions[0];
    if (selectedOption) {
      showText = showText.replaceAll(':option', selectedOption.text);
    }

    window.alert(showText);
  }
}
