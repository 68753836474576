import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['popoverOverlay', 'popoverContent']

    connect() {
      const endpoint = `${window.location.protocol == 'https:' ? 'wss:' : 'ws:'}//${window.location.host}`;
      /* this.consumer = ActionCable.createConsumer(`${endpoint}/cable`);
        this.consumer.subscriptions.create("AsyncTasksChannel", { received: this._task_updated }) */
    }

    disconnect() {
      if (this.consumer !== undefined) {
        this.consumer.disconnect();
      }
    }

    closePopover = (event) => {
      // When we just open tasks panel programmatically,
      // js attemps to send mouseenter event on popoverOverlay
      // which causes tasks panel to close before the results are fetched
      if (this.data.get('fetch-status') == 'pending') {
        return; // fetchMyTasks is still pending
      }
      this.popoverContentTarget.querySelectorAll('[data-toggle="tooltip"]').forEach((el) => {
        $(el).tooltip('hide');
      });
      this.popoverOverlayTarget.classList.add('hide');
      this.popoverContentTarget.classList.add('hide');
    }

    fetchMyTasks = (event) => {
      // make sure async tasks button is visible
      this.element.classList.remove('hide');
      const { url } = this.element.dataset;
      if (!this.popoverOverlayTarget.classList.contains('hide')) return; // task list already visible

      this.popoverOverlayTarget.classList.remove('hide');
      this.popoverContentTarget.classList.remove('hide');

      if (url !== undefined) {
        this.data.set('fetch-status', 'pending');
        $.ajax({
          url,
          type: ('GET'),
          complete: (_jqXHR, textStatus) => {
            this.data.set('fetch-status', 'complete');
          },
          error: (_jqXHR, textStatus, errorThrown) => {
            this.data.set('fetch-status', 'error');
            // log the error to the console
            console.error(`Error retrieving tasks: ${textStatus}`, errorThrown);
          },
        });
      }
    }

    _task_updated = (data) => {
      const parsed = JSON.parse(data);
      if (parsed.task_updated !== undefined) {
        const { url } = parsed.task_updated;
        $.ajax({
          url,
          type: ('GET'),
          error(_jqXHR, textStatus, errorThrown) {
            // log the error to the console
            console.error(`Error retrieving updated task row: ${textStatus}`, errorThrown);
          },
        });
      }

      if (parsed.bulk_edit_finished !== undefined) {
        const element = document.querySelector('[data-controller*="planning-schedule"]');
        const controller = this.application.getControllerForElementAndIdentifier(element, 'planning-schedule');

        if (controller !== undefined) {
          controller.refreshAfterBulkEditFinished(parsed.bulk_edit_finished.team_ids);
        }
      }
    }
}
