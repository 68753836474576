import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['happeningSelector', 'clusterSelector', 'teamSelector']

  connect() {
    $(this.happeningSelectorTarget).on('change', this.updateClusterInput);

    $(this.clusterSelectorTarget).on('change', this.updateTeaamInput);
  }

  prepareParams() {
    const p = { q: '', happening_id: $('[name=happening_id]').val() };
    if (this.clusterSelectorTarget.value != null) {
      p.cluster_id = this.clusterSelectorTarget.value;
    }
    if ($('[name=original_team_id]').val() != '' && $('[name=original_team_id]').val() != null) {
      p.original_team_id = $('[name=original_team_id]').val();
    }
    return p;
  }

  updateClusterInput = (event) => {
    $.ajax({
      url: '/admin/happenings/search-clusters.json',
      dataType: 'json',
      data: { q: '', happening_id: this.happeningSelectorTarget.value },
    }).done((data) => {
      if (data.length == 1) {
        data.each(function (cluster) {
          const select = $(this.clusterSelectorTarget);
          const option = $('<option></option>')
            .attr('selected', true)
            .text(cluster.name)
            .val(cluster.id);
          option.appendTo(select);
          select.trigger('change');
        });
      }
    });

    if ($(this.clusterSelectorTarget)[0] != undefined) {
      $('#volunteers_team_registration_cluster')[0].selectedIndex = -1;
      $('#volunteers_team_registration_cluster').trigger('change');
    }
    $('#search_team_for_move')[0].selectedIndex = -1;
    $('#search_team_for_move').trigger('change');
  }

  updateTeamInput = (event) => {
    $.ajax({
      url: '/admin/happenings/search-clusters.json',
      dataType: 'json',
      data: { q: '', happening_id: this.happeningSelectorTarget.value },
    }).done((data) => {
      if (data.length == 1) {
        data.each(function (cluster) {
          const select = $(this.clusterSelectorTarget);
          const option = $('<option></option>')
            .attr('selected', true)
            .text(cluster.name)
            .val(cluster.id);
          option.appendTo(select);
          select.trigger('change');
        });
      }
    });
  }
}
