import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['warning', 'warning2', 'migrationOption', 'migrated', 'submit', 'warningOverlappingAvailabilities'];

    connect() {
      // Availabilities 2.0 settings page checks
      const migrationOptionNone = this.migrationOptionTargets.filter((v) => v.value === 'none')[0];

      migrationOptionNone.checked = true;

      const warning = this.warningTarget;
      if (warning !== undefined) {
        const usesAvailabilities2 = warning.dataset.tenantUsesAvailabilities2;
        const usesAvailabilities1 = warning.dataset.tenantUsesAvailabilities1;

        if (usesAvailabilities2 !== 'true') {
          if (usesAvailabilities1 === 'true') {
            warning.classList.remove('hide');
            this.migratedTarget.classList.add('hide');
            this.submitTarget.classList.add('hide');
          }
        }
      }

      const warning2 = this.warning2Target;
      if (warning2 !== undefined) {
        const usesAvailabilities2 = warning2.dataset.tenantUsesAvailabilities2;
        const usesAvailabilities1 = warning2.dataset.tenantUsesAvailabilities1;

        if (usesAvailabilities2 !== 'true') {
          if (usesAvailabilities1 === 'false') {
            warning2.classList.remove('hide');
            this.migratedTarget.classList.add('hide');
          }
        }
      }

      if (this.hasWarningOverlappingAvailabilitiesTarget) {
        const allowOverlappingAvailabilities = this.warningOverlappingAvailabilitiesTarget.dataset.tenantAllowOverlappingAvailabilities;

        if (allowOverlappingAvailabilities === 'true') {
          // display the warning that this setting cannot be changed
          this.warningOverlappingAvailabilitiesTarget.classList.remove('hide');

          // disable the radios for enabling/disabling overlapping availabilities
          const radioToEnableOverlappingAvailabilities = document.querySelector("label[for='tenant_allow_overlapping_availabilities_true']");
          const radioToDisableOverlappingAvailabilities = document.querySelector("label[for='tenant_allow_overlapping_availabilities_false']");

          radioToEnableOverlappingAvailabilities.classList.add('beeple-disabled');
          radioToDisableOverlappingAvailabilities.classList.add('beeple-disabled');

          const overlappingAvailabilitiesRadios = document.querySelectorAll("input[name='tenant[allow_overlapping_availabilities]']");
          overlappingAvailabilitiesRadios.forEach((r) => {
            r.disabled = 'disabled';
          });
        }
      }
    }

    migrationOptionChanged(event) {
      if (event.target.value !== 'none') {
        this.submitTarget.classList.remove('hide');
      } else {
        this.submitTarget.classList.add('hide');
      }
    }

    allowOverlappingAvailabilitiesChanged(event) {
      if (event.target.value === 'false') {
        this.warningOverlappingAvailabilitiesTarget.classList.add('hide');
      } else {
        this.warningOverlappingAvailabilitiesTarget.classList.remove('hide');
      }
    }
}
