import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['enableInput', 'weightBar', 'weightBarWrapper', 'overridableCheckbox', 'hardRequirementInput'];

  connect() {
    this.setDisabledProps();
    this.toggleDisplayWeightBar();
    $(this.enableInputTarget).change((event) => {
      this.setDisabledProps();
    });
  }

  setDisabledProps = () => {
    const enabled = $(this.enableInputTarget).prop('checked');

    this.weightBarTarget.disabled = !enabled;

    if (this.hasHardRequirementInputTarget) {
      this.hardRequirementInputTarget.disabled = !enabled;
    }
  }

  toggleMandatory() {
    this.toggleDisplayWeightBar();
  }

  toggleDisplayWeightBar = () => {
    if (this.hasHardRequirementInputTarget) {
      this.weightBarWrapperTarget.classList.toggle('hidden', this.hardRequirementInputTarget.checked);
    }
  }
}
