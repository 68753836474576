import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fromTemplateSelector', 'resultsThrobber', 'formContent'];

  readFromTemplate = (_event) => {
    if (this.fromTemplateSelectorTarget.value === '') return;
    this.resultsThrobberTarget.classList.remove('hidden');
    this.formContentTarget.classList.add('hidden');

    const { url } = this.fromTemplateSelectorTarget.dataset;
    $.ajax({
      url,
      type: 'POST',
      data: {
        from_template: this.fromTemplateSelectorTarget.value,
        format: 'js',
      },
      error(_jqXHR, textStatus, errorThrown) {
        // log the error to the console
        console.error(`Error reading planning_template form: ${textStatus}`,
          errorThrown);
      },
    });
  };
}
