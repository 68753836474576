import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
      'useRepetitionInput', // enable / disable repetition section
      'repetitionEnabledInputs', // groups all controls for repetition section
      'repetitionDisabledInputs', // groups all controls for when no repetition
      'repeatPeriod', // 'weekly' or 'monthly'
      'repeatsEvery', // repetition frequency, i.e. every two weeks
      'endDate', // repetiton occur until this date, it is in ISO 8601 format
      'occurrences', // total occurrences count
      'summary', // text label, i.e. 'Weekly until 21/09/2020',
      'repeatsEveryWeeklyLabel', 'repeatsEveryMonthlyLabel', // addon label for repeatsEvery field
    ];

    connect() {
      this.updateRepetitionInputsVisibility();
    }

    // Actions
    updateRepetitionInputsVisibility(_event) {
      if (this.isRepetitionEnabled) {
        this.repetitionEnabledInputsTarget.classList.remove('hidden');
        this.repetitionDisabledInputsTarget.classList.add('hidden');
        this.refreshSummary();
      } else {
        this.repetitionDisabledInputsTarget.classList.remove('hidden');
        this.repetitionEnabledInputsTarget.classList.add('hidden');
      }
    }

    // Attributes section
    get occurrences() {
      return parseInt(this.occurrencesTarget.value);
    }

    get repeatPeriod() {
      return this.repeatPeriodTarget.value;
    }

    get repeatsEvery() {
      return parseInt(this.repeatsEveryTarget.value);
    }

    get endDate() {
      // using datepicker('getDate') is required to get proper value in ISO8601,
      // especially when datepicker uses altField for localization
      return $(this.endDateTarget).datepicker('getDate');
    }

    get useRepetition() {
      return this.useRepetitionInputTarget.checked === true;
    }

    set summary(value) {
      this.summaryTarget.innerText = value;
    }

    // Computed properties
    get isRepetitionEnabled() {
      return this.useRepetition;
    }

    get hasEndDate() {
      return this.endDate != undefined;
    }

    get hasOccurrences() {
      return this.occurrences > 0;
    }

    get isRepeatingRegularly() {
      return !(this.repeatsEvery > 0);
    }

    // Methods section
    refreshSummary() {
      if (!['weekly', 'monthly'].includes(this.repeatPeriod)) {
        return; // not yet selected
      }
      if (this.repeatPeriod == 'weekly') {
        this.repeatsEveryMonthlyLabelTarget.classList.add('hidden');
        this.repeatsEveryWeeklyLabelTarget.classList.remove('hidden');
      } else {
        // monthly
        this.repeatsEveryWeeklyLabelTarget.classList.add('hidden');
        this.repeatsEveryMonthlyLabelTarget.classList.remove('hidden');
      }
      let summaryText = '';
      if (this.isRepeatingRegularly) {
        summaryText = i18n(['period', this.repeatPeriod, 'one']);
      } else {
        summaryText = i18n(['period', this.repeatPeriod, 'other'], { '%n': this.repeatsEvery });
      }
      if (this.hasOccurrences) {
        summaryText += i18n(['times'], { '%times': this.occurrences });
      }
      if (this.hasEndDate) {
        summaryText = `${summaryText} ${i18n(['till'], { '%date': moment(this.endDate).format('L') })}`;
      }
      this.summary = summaryText;
    }
}
