import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = []

  togglePanel(event) {
    event.preventDefault();
    this.element.classList.toggle('panel-open');
  }
}
