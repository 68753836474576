import { Controller } from 'stimulus';

// controller: admin--tenant--enrolments
export default class extends Controller {
  static targets = [
    'teamEnrollmentNeedsConfirmationInputWrapper',
    'teamEnrollmentViaPlannerNeedsConfirmationInputWrapper',
    'unconfirmedEnrolmentsCanBeOverlappedInputWrapper',
    'workStabilityWarningOrErrorWrapper',
    'workStabilityPeriodInDaysInput',
    'workStabilityPeriodInHoursAndMinsInput',
    'workStabilityPeriodUnitInput',
  ]

  connect() {
    this.toggleUnconfirmedEnrolmentsCanBeOverlappedInputWrapperVisibility();
    this.toggleWorkStabilityWarningOrErrorWrapperVisibility();
  }

  toggleWorkStabilityWarningOrErrorWrapperVisibility() {
    if (this.workStabilityPeriodUnitInputTarget.value == 'days' && this.workStabilityPeriodInDaysInputTarget.value) {
      // work stability period is set in days
      this.workStabilityWarningOrErrorWrapperTarget.classList.remove('hidden');
    } else if (this.workStabilityPeriodUnitInputTarget.value == 'hours_and_mins' && this.workStabilityPeriodInHoursAndMinsInputTarget.value) {
      // work stability period is set in hours and mins
      this.workStabilityWarningOrErrorWrapperTarget.classList.remove('hidden');
    } else {
      // work stability period is not set
      this.workStabilityWarningOrErrorWrapperTarget.classList.add('hidden');
    }
  }

  toggleUnconfirmedEnrolmentsCanBeOverlappedInputWrapperVisibility() {
    if (this.teamEnrollmentNeedsConfirmationEnabled() || this.teamEnrollmentViaPlannerNeedsConfirmationEnabled()) {
      this.showUnconfirmedEnrolmentsCanBeOverlappedInputWrapper();
    } else {
      this.hideUnconfirmedEnrolmentsCanBeOverlappedInputWrapper();
    }
  }

  showUnconfirmedEnrolmentsCanBeOverlappedInputWrapper() {
    this.unconfirmedEnrolmentsCanBeOverlappedInputWrapperTarget.classList.remove('hidden');
  }

  hideUnconfirmedEnrolmentsCanBeOverlappedInputWrapper() {
    this.unconfirmedEnrolmentsCanBeOverlappedInputWrapperTarget.classList.add('hidden');
  }

  teamEnrollmentNeedsConfirmationEnabled() {
    if (this.hasTeamEnrollmentNeedsConfirmationInputWrapperTarget) {
      const checkedInput = this.teamEnrollmentNeedsConfirmationInputWrapperTarget.querySelector('input:checked');
      return checkedInput.value == 'true';
    }

    return false;
  }

  teamEnrollmentViaPlannerNeedsConfirmationEnabled() {
    if (this.hasTeamEnrollmentViaPlannerNeedsConfirmationInputWrapperTarget) {
      const checkedInput = this.teamEnrollmentViaPlannerNeedsConfirmationInputWrapperTarget.querySelector('input:checked');
      return checkedInput.value == 'true';
    }

    return false;
  }
}
