import ScheduleController from 'base/schedule_controller';
import { animateEvent, distinct } from '../base/beeple_lib';

export default class extends ScheduleController {
  static targets = ['form', 'output', 'startDate', 'endDate',
    'actionPanel', 'actionPanelOutput',
    'sortableGroupingItems',
    'toasterContent',
    'multiDateSelect',
    'planningToggleModeButton',
    'planningToggleTeamModeButton',
    'bulkEditTeamsButton', 'bulkDeleteTeamsButton', 'bulkCopyTeamsButton',
    'sidePanel',
    'columnSelectCheckbox', 'rowSelectCheckbox', 'teamShiftCard']

  connect() {
    this.selectedTeamIds = [];
    this.showDetailedProgressBar = this.element.dataset.planningScheduleShowDetailedProgressBar === 'true';
    this.progressSelector = this.showDetailedProgressBar ? '.progress-planning' : '.progressx';
    super.connect();
  }

  contentLoaded() {
    if (this.element.dataset.planningTeamSelectMode == 'true') {
      for (const team_id of this.selectedTeamIds) {
        for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
          team_card.classList.toggle('selected', true);
          this.applySelectedStyle(team_card);
        }
      }
    }

    this._initializeTooltip();
  }

  _initializeTooltip() {
    this.element.querySelectorAll('[data-toggle="tooltip"]').forEach((el) => {
      $(el).tooltip();
    });
  }

  unselectAllTeams = (event) => {
    event.preventDefault();

    for (const team_id of this.selectedTeamIds) {
      for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
        team_card.classList.toggle('selected', false);
        this.applySelectedStyle(team_card);
      }
    }

    this.selectedTeamIds = [];

    this.bulkEditTeamsButtonTarget.setAttribute('disabled', true);
    this.bulkDeleteTeamsButtonTarget.setAttribute('disabled', true);
    this.bulkCopyTeamsButtonTarget.setAttribute('disabled', true);

    this._refreshPlanningPanelContent();
  }

  refreshAfterBulkEditFinished = (teamIds) => {
    const { element } = this;
    const contains_team = function (team_id) {
      return element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`).length > 0;
    };

    if (teamIds.some(contains_team)) {
      this._refreshContent();
    }
  }

  togglePlanningMode = (event) => {
    event.preventDefault();
    const current_active = this.planningToggleModeButtonTarget.classList.contains('active');

    if (current_active) {
      this._disablePlanningMode();
    } else {
      this._enablePlanningMode();
    }
  }

  bulkEditTeams = (event) => {
    event.preventDefault();
    const current_active = this.bulkEditTeamsButtonTarget.classList.contains('active');

    if (current_active) {
      this._cancelBulkEditTeams();
    } else {
      this._startBulkEditTeams();
    }
  }

  _cancelBulkEditTeams() {
    this.bulkEditTeamsButtonTarget.classList.toggle('active', false);
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    side_panel_controller.closeSecondaryTabById(
      'bulk-edit',
      '',
    );
  }

  _startBulkEditTeams() {
    this.bulkEditTeamsButtonTarget.classList.toggle('active', true);

    if (this.element.dataset.planningTeamSelectMode == 'true') {
      if (this.selectedTeamIds.length > 0) {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'bulk-edit',
          '',
          '',
          this.element.dataset.planningScheduleBulkEditUrl.replace('SELECTED_TEAM_IDS', this.selectedTeamIds.join(',')),
          false,
        );
      }
    }
  }

  bulkDeleteTeams = (event) => {
    event.preventDefault();
    const current_active = this.bulkDeleteTeamsButtonTarget.classList.contains('active');

    if (current_active) {
      this._cancelBulkDeleteTeams();
    } else {
      this._startBulkDeleteTeams();
    }
  }

  _cancelBulkDeleteTeams() {
    this.bulkDeleteTeamsButtonTarget.classList.toggle('active', false);
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    side_panel_controller.closeSecondaryTabById(
      'bulk-delete',
      '',
    );
  }

  _startBulkDeleteTeams() {
    this.bulkDeleteTeamsButtonTarget.classList.toggle('active', true);

    if (this.element.dataset.planningTeamSelectMode == 'true') {
      if (this.selectedTeamIds.length > 0) {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'bulk-delete',
          '',
          '',
          this.element.dataset.planningScheduleBulkDeleteUrl.replace('SELECTED_TEAM_IDS', this.selectedTeamIds.join(',')),
          false,
        );
      }
    }
  }

  bulkCopyTeams = (event) => {
    event.preventDefault();
    const current_active = this.bulkCopyTeamsButtonTarget.classList.contains('active');

    if (current_active) {
      this._cancelBulkCopyTeams();
    } else {
      this._startBulkCopyTeams();
    }
  }

  _cancelBulkCopyTeams() {
    this.bulkCopyTeamsButtonTarget.classList.toggle('active', false);
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    side_panel_controller.closeSecondaryTabById(
      'bulk-copy',
      '',
    );
  }

  _startBulkCopyTeams() {
    this.bulkCopyTeamsButtonTarget.classList.toggle('active', true);

    if (this.element.dataset.planningTeamSelectMode == 'true') {
      if (this.selectedTeamIds.length > 0) {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'bulk-copy',
          '',
          '',
          this.element.dataset.planningScheduleBulkCopyUrl.replace('SELECTED_TEAM_IDS', this.selectedTeamIds.join(',')),
          false,
        );
      }
    }
  }

  _enforcePlanningMode() {
    const current_active = this.planningToggleModeButtonTarget.classList.contains('active');

    if (this.selectedTeamIds.length >= 2) {
      if (this.hasBulkEditTeamsButtonTarget) {
        this.bulkEditTeamsButtonTarget.removeAttribute('disabled');
      }

      if (this.hasBulkDeleteTeamsButtonTarget) {
        this.bulkDeleteTeamsButtonTarget.removeAttribute('disabled');
      }

      if (this.hasBulkCopyTeamsButtonTarget) {
        this.bulkCopyTeamsButtonTarget.removeAttribute('disabled');
      }
    } else {
      if (this.hasBulkEditTeamsButtonTarget) {
        this.bulkEditTeamsButtonTarget.setAttribute('disabled', true);
      }

      if (this.hasBulkDeleteTeamsButtonTarget) {
        this.bulkDeleteTeamsButtonTarget.setAttribute('disabled', true);
      }

      if (this.hasBulkCopyTeamsButtonTarget) {
        this.bulkCopyTeamsButtonTarget.setAttribute('disabled', true);
      }
    }
    // }

    if (current_active) {
      this._refreshPlanningPanelContent();
      return;
    }

    this._enablePlanningMode();
  }

  _enablePlanningMode() {
    this.planningToggleModeButtonTarget.classList.toggle('active', true);
    this._refreshPlanningPanelContent();

    /* Check if there are any teams selected, and if so highlight them */
    if (this.element.dataset.planningTeamSelectMode == 'true') {
      this._enablePlanningTeamSelectMode();
    } else if (this.selectedTeamIds.length == 1) {
      for (const team_id of this.selectedTeamIds) {
        for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
          team_card.classList.toggle('selected', true);
          this.applySelectedStyle(team_card);
        }
      }
    }
  }

  _disablePlanningMode() {
    this.planningToggleModeButtonTarget.classList.toggle('active', false);
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    for (const team_card of this.element.querySelectorAll('.team-shift-card.selected')) {
      team_card.classList.toggle('selected', false);
      this.applySelectedStyle(team_card);
    }
    this.planningToggleTeamModeButtonTarget.classList.toggle('active', false);
    this._showSelectCheckboxes(false);

    side_panel_controller.closeSecondaryTabById(
      'planning',
      '',
    );
  }

  togglePlanningTeamSelectMode = (event) => {
    event.preventDefault();
    // switching to selectMode?
    const switchToSelectMode = this.element.dataset.planningTeamSelectMode !== 'true';
    this._showSelectCheckboxes(switchToSelectMode);

    this.planningToggleTeamModeButtonTarget.classList.toggle('active');
    this.planningToggleModeButtonTarget.classList.toggle('active', true);
    if (this.element.dataset.planningTeamSelectMode != 'true') {
      this.element.dataset.planningTeamSelectMode = 'true';
      for (const team_card of this.element.querySelectorAll('.team-shift-card.selected')) {
        team_card.classList.toggle('selected', false);
        this.applySelectedStyle(team_card);
      }

      for (const team_id of this.selectedTeamIds) {
        for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
          team_card.classList.toggle('selected', true);
          this.applySelectedStyle(team_card);
        }
      }

      if (this.selectedTeamIds.length >= 2) {
        this.bulkEditTeamsButtonTarget.removeAttribute('disabled');
        this.bulkDeleteTeamsButtonTarget.removeAttribute('disabled');
        this.bulkCopyTeamsButtonTarget.removeAttribute('disabled');
      }

      if (this.selectedTeamIds.length >= 2) {
        this.bulkEditTeamsButtonTarget.removeAttribute('disabled');
        this.bulkDeleteTeamsButtonTarget.removeAttribute('disabled');
        this.bulkCopyTeamsButtonTarget.removeAttribute('disabled');
      }

      this._refreshPlanningPanelContent();
    } else {
      this.selectedTeamIds = [];

      this.bulkEditTeamsButtonTarget.setAttribute('disabled', true);
      this.bulkDeleteTeamsButtonTarget.setAttribute('disabled', true);
      this.bulkCopyTeamsButtonTarget.setAttribute('disabled', true);

      this.planningToggleTeamModeButtonTarget.blur();
      this.element.dataset.planningTeamSelectMode = 'false';
      for (const team_card of this.element.querySelectorAll('.team-shift-card.selected')) {
        team_card.classList.toggle('selected', false);
        this.applySelectedStyle(team_card);
      }

      this._refreshPlanningPanelContent();
    }
  }

  _enablePlanningTeamSelectMode() {
    this.element.dataset.planningTeamSelectMode = 'true';
    this.planningToggleTeamModeButtonTarget.classList.toggle('active', true);
    this._showSelectCheckboxes(true);

    for (const team_id of this.selectedTeamIds) {
      for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
        team_card.classList.toggle('selected', true);
        this.applySelectedStyle(team_card);
      }
    }
  }

  _refreshPlanningPanelContent() {
    if (this.element.dataset.planningTeamSelectMode == 'true') {
      if (this.selectedTeamIds.length == 1) {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'planning',
          '',
          '',
          this.element.dataset.planningScheduleTeamUrl.replace('TEAM_ID', this.selectedTeamIds[0]),
          false,
        );
      } else {
        const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
        side_panel_controller.createSecondaryTab(
          'planning',
          '',
          '',
          this.element.dataset.planningSchedulePlanningUrl.replace('SELECTED_TEAM_IDS', this.selectedTeamIds.join(',')),
          true,
        );
      }
    } else if (this.selectedTeamIds.length == 1) {
      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
      side_panel_controller.createSecondaryTab(
        'planning',
        '',
        '',
        this.element.dataset.planningScheduleTeamUrl.replace('TEAM_ID', this.selectedTeamIds[0]),
        false,
      );
    } else {
      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
      side_panel_controller.createSecondaryTab(
        'planning',
        '',
        '',
        this.element.dataset.planningSchedulePlanningUrl.replace('SELECTED_TEAM_IDS', ''),
        true,
      );
    }
  }

  selectTeam = (event) => {
    event.preventDefault();

    const selected_team = event.target.closest('[data-planning-schedule-team-select-id]');
    const team_id = selected_team.dataset.planningScheduleTeamSelectId;

    if (this.element.dataset.planningTeamSelectMode != 'true') {
      if (this.selectedTeamIds.length > 1) {
        /*
          So we remember that multiple teams were selected, but we are not in multi select team mode
          As a result we forget everything we had selected, and assume nothing is selected
        */
        this.selectedTeamIds = [];
      }

      const previously_selected = this.selectedTeamIds.includes(team_id);

      if (previously_selected) {
        this.selectedTeamIds = [];
      } else {
        this.selectedTeamIds = [team_id];
      }

      /* Deslect all teams */
      for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id]:not([data-planning-schedule-team-select-id="${team_id}"])`)) {
        team_card.classList.toggle('selected', false);
        this.applySelectedStyle(team_card);
      }

      for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
        team_card.classList.toggle('selected', !previously_selected);
        this.applySelectedStyle(team_card);
      }
    } else {
      // confirm close any previous bulk edit
      const wasBulkEditing = this.bulkEditTeamsButtonTarget.classList.contains('active');
      if (wasBulkEditing) {
        const endBulkEditConfirmed = confirm(this.element.dataset.planningScheduleBulkEditConfirmClose);
        if (endBulkEditConfirmed) this._cancelBulkEditTeams();
        else return;
      }

      const previously_selected = this.selectedTeamIds.includes(team_id);

      if (previously_selected) {
        this.selectedTeamIds = this.selectedTeamIds.filter((ele) => ele != team_id);
      } else {
        this.selectedTeamIds.push(team_id);
      }

      for (const team_card of this.element.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
        team_card.classList.toggle('selected', !previously_selected);
        this.applySelectedStyle(team_card);
      }

      if (this.selectedTeamIds.length >= 2) {
        this.bulkEditTeamsButtonTarget.removeAttribute('disabled');
        this.bulkDeleteTeamsButtonTarget.removeAttribute('disabled');
        this.bulkCopyTeamsButtonTarget.removeAttribute('disabled');
      } else {
        this.bulkEditTeamsButtonTarget.setAttribute('disabled', true);
        this.bulkDeleteTeamsButtonTarget.setAttribute('disabled', true);
        this.bulkCopyTeamsButtonTarget.setAttribute('disabled', true);
      }
    }

    this._enforcePlanningMode();
  }

  selectTeamById = (team_id) => {
    return false;
    /* eslint-disable no-unreachable */
    if (this.element.dataset.planningTeamSelectMode == 'true') {
      return false;
    }
    for (const team of this.element.querySelectorAll('[data-planning-schedule-team-select-id]')) {
      team.classList.toggle('selected', team.dataset.planningScheduleTeamSelectId == team_id);
    }
    /* eslint-enable no-unreachable */
  }

  directEnrolment = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const target = event.target.closest('[data-planning-schedule-team-registration-url]');

    const main_tab_id = target.dataset.planningScheduleMainTabId;
    const secondary_tab_id = target.dataset.planningScheduleVolunteerId;
    const secondary_tab_label = target.dataset.planningScheduleTabLabel;
    const secondary_tab_url = target.dataset.planningScheduleTeamRegistrationUrl;
    side_panel_controller.createSecondaryTab(
      'new-teamregistration',
      secondary_tab_id,
      secondary_tab_label,
      secondary_tab_url,
      true,
      null,
      target.dataset.planningScheduleTeamId,
    );
  }

  openTeamRegistrationAction = (event) => {
    event.stopPropagation();
    this.openSidePanelAction(event);
  }

  toggleAlertsAndInvites = (event) => {
    event.target.closest('.actions-content').querySelector('.planning-schedule-alerts-box').classList.toggle('hide');
    event.preventDefault();
  }

  prepareSubmitInvitesAndAlerts = (event) => {
    const form = event.target;

    const container = event.target.closest('.flexible');
    for (const selected_suggestion of container.querySelectorAll("[name='volunteer_ids[]']:checked")) {
      const node = document.createElement('INPUT');
      node.name = 'happenings_team_invite_template[volunteer_ids][]';
      node.value = selected_suggestion.value;
      node.type = 'hidden';
      form.appendChild(node);
    }
  }

  startMultiEnrolmentsFromSelection = (event) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_suggestions = selections.querySelectorAll('[name="volunteer_ids[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    for (const selected_suggestion of selected_suggestions) {
      const card = selected_suggestion.closest('[data-planning-schedule-team-ids]');
      const team_ids = card.dataset.planningScheduleTeamIds.split(';');

      if (team_ids.length > 0) {
        // https://beeple.atlassian.net/browse/BAPP-12635 Note AMW Feb 11 2021
        // after sorting, when tabs need to be opened e.g. because special compensations
        // can be entered or because a planning rule is violated, make the tab of the first team
        // active and not on the last
        for (const team_id of team_ids) {
          side_panel_controller.createSecondaryTab(
            'new-teamregistration',
            `${card.dataset.planningScheduleVolunteerId}-${team_id}`,
            card.dataset[`planningScheduleTabLabel-${team_id}`],
            card.dataset[`planningScheduleTeamRegistrationUrl-${team_id}`],
            true,
            null,
            team_id,
            null,
            null,
            null,
            team_id === team_ids[0], // switchTo first created tab
          );
        }
      }
    }
  }

  directEnrolments = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const card = event.target.closest('[data-planning-schedule-team-ids]');
    const team_ids = card.dataset.planningScheduleTeamIds.split(';');

    if (team_ids.length > 0) {
      for (const team_id of team_ids) {
        side_panel_controller.createSecondaryTab(
          'new-teamregistration',
          `${card.dataset.planningScheduleVolunteerId}-${team_id}`,
          card.dataset[`planningScheduleTabLabel-${team_id}`],
          card.dataset[`planningScheduleTeamRegistrationUrl-${team_id}`],
          true,
          null,
          team_id,
          null,
          null,
          null,
          team_id === team_ids[0], // switchTo first created tab
        );
      }
    }
  }

  startEnrolmentsFromSelection = (event) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_suggestions = selections.querySelectorAll('[name="volunteer_ids[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

    let switchToThisTab = true;
    for (const selected_suggestion of selected_suggestions) {
      const card = selected_suggestion.closest('[data-planning-schedule-team-registration-url]');
      const tabId = `${card.dataset.planningScheduleVolunteerId}::${card.dataset.planningScheduleTeamId}`;
      side_panel_controller.createSecondaryTab(
        'new-teamregistration',
        tabId,
        card.dataset.planningScheduleTabLabel,
        card.dataset.planningScheduleTeamRegistrationUrl,
        true,
        null,
        card.dataset.planningScheduleTeamId,
        null,
        null,
        null,
        switchToThisTab, // switchTo first created tab
      );
      switchToThisTab = false;
    }
  }

  startActionTeamApplicationsFromSelectionWithSingleTeam = (event, action_name, url_data_prefix, tab_name, is_post) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_volunteers = selections.querySelectorAll('#planning-team-applications [name="team_applications[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    let switchToThisTab = true;

    for (const selected_volunteer of selected_volunteers) {
      const card = selected_volunteer.closest('[data-planning-schedule-team-id]');
      const team_id = card.dataset.planningScheduleTeamId;

      side_panel_controller.createSecondaryTab(
        tab_name,
        card.dataset.planningScheduleSecondaryTabId,
        card.dataset.planningScheduleTabLabel,
        card.dataset[url_data_prefix],
        is_post,
        null,
        team_id,
        null,
        null,
        null,
        switchToThisTab, // switchTo first created tab
      );
      switchToThisTab = false;
    }
  }

  startActionTeamApplicationsFromSelection = (event, action_name, url_data_prefix, tab_name, is_post) => {
    event.preventDefault();
    const selections = event.target.closest('.flexible');
    const selected_volunteers = selections.querySelectorAll('#planning-team-applications [name="volunteer_ids[]"]:checked');

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    let switchToThisTab = true;

    for (const selected_volunteer of selected_volunteers) {
      const card = selected_volunteer.closest('[data-planning-schedule-team-application-team-ids]');
      for (const team_id of card.dataset.planningScheduleTeamApplicationTeamIds.split(';')) {
        side_panel_controller.createSecondaryTab(
          tab_name,
          `${card.dataset.planningScheduleVolunteerId}-${team_id}`,
          card.dataset[`planningScheduleTabLabel-${team_id}`],
          card.dataset[`${url_data_prefix}-${team_id}`],
          is_post,
          null,
          team_id,
          null,
          null,
          null,
          switchToThisTab, // switchTo first created tab
        );
        switchToThisTab = false;
      }
    }
  }

  directTeamApplicationsAction = (event) => {
    event.preventDefault();

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const card = event.target.closest('[data-planning-schedule-team-application-team-ids]');

    const action_name = event.target.closest('[data-action-name]').dataset.actionName;
    let tab_name; let url_data_prefix; let
      is_post;
    if (action_name == 'Accept') {
      tab_name = 'accept-team-application';
      url_data_prefix = 'planningScheduleAcceptTeamApplicationUrl';
      is_post = true;
    } else if (action_name == 'Reject') {
      tab_name = 'reject-team-application';
      url_data_prefix = 'planningScheduleRejectTeamApplicationUrl';
      is_post = false;
    } else if (action_name == 'Standby') {
      tab_name = 'standby-team-application';
      url_data_prefix = 'planningScheduleStandbyTeamApplicationUrl';
      is_post = false;
    }

    let switchToThisTab = true;
    for (const team_id of card.dataset.planningScheduleTeamApplicationTeamIds.split(';')) {
      side_panel_controller.createSecondaryTab(
        tab_name,
        `${card.dataset.planningScheduleVolunteerId}-${team_id}`,
        card.dataset[`planningScheduleTabLabel-${team_id}`],
        card.dataset[`${url_data_prefix}-${team_id}`],
        is_post,
        null,
        team_id,
        null,
        null,
        null,
        switchToThisTab, // switchTo first created tab
      );
      switchToThisTab = false;
    }
  }

  startAcceptTeamApplicationsFromSelectionWithSingleTeam = (event) => {
    this.startActionTeamApplicationsFromSelectionWithSingleTeam(event, 'planning-schedule-accept-team-application-url', 'planningScheduleAcceptTeamApplicationUrl', 'accept-team-application', true);
  }

  startRejectTeamApplicationsFromSelectionWithSingleTeam = (event) => {
    this.startActionTeamApplicationsFromSelectionWithSingleTeam(event, 'planning-schedule-reject-team-application-url', 'planningScheduleRejectTeamApplicationUrl', 'reject-team-application', false);
  }

  startStandbyTeamApplicationsFromSelectionWithSingleTeam = (event) => {
    this.startActionTeamApplicationsFromSelectionWithSingleTeam(event, 'planning-schedule-standby-team-application-url', 'planningScheduleStandbyTeamApplicationUrl', 'standby-team-application', false);
  }

  startAcceptTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'planning-schedule-accept-team-application-url', 'planningScheduleAcceptTeamApplicationUrl', 'accept-team-application', true);
  }

  startRejectTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'planning-schedule-reject-team-application-url', 'planningScheduleRejectTeamApplicationUrl', 'reject-team-application', false);
  }

  startStandbyTeamApplicationsFromSelection = (event) => {
    this.startActionTeamApplicationsFromSelection(event, 'planning-schedule-standby-team-application-url', 'planningScheduleStandbyTeamApplicationUrl', 'standby-team-application', false);
  }

  teamFeedback = (event) => {
    const { data } = event.detail;
    const { xhr } = event.detail;
    const { status } = event.detail;

    this.actionFeedback(data);
  }

  finishBulkEdit = () => {
    this.bulkEditTeamsButtonTarget.classList.toggle('active', false);
  }

  actionFeedback = (data) => {
    if (data.toaster_success) {
      this._showToaster(data.toaster_success, 'success');
    }

    if (data.toaster_error) {
      this._showToaster(data.toaster_error, 'error');
    }

    if (data.open_panel != undefined) {
      // We are already opening a side panel, no need to refresh current panel?
      for (const refresh_tag of document.querySelectorAll('.planning-schedule-suggestions-refresh')) {
        const form_refresh = this.application.getControllerForElementAndIdentifier(refresh_tag, 'form-refresh');
        if (form_refresh) {
          form_refresh.refreshContent();
        }
      }
    }

    if (data.open_tasks_panel) {
      const el = document.querySelector('li[data-action="mouseenter->async-tasks#fetchMyTasks"]');
      const asyncTasksController = this.application.getControllerForElementAndIdentifier(el, 'async-tasks');
      asyncTasksController.fetchMyTasks(new Event('open-task-panel'));
    }

    if (data.full_refresh) {
      this._refreshContent();
      return;
    }

    if (data.open_panel != undefined) {
      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
      const new_panel = side_panel_controller.createSecondaryTab(data.open_panel.main_tab_id, data.open_panel.secondary_tab_id, data.open_panel.tab_label, null);
      new_panel.innerHTML = data.open_panel.content;
    }

    if (data.updated_shift_hours) {
      for (const shift_id in data.updated_shift_hours) {
        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          shift_card.querySelector('.shift-time').innerHTML = data.updated_shift_hours[shift_id];
          animateEvent(shift_card, 'updated_shift_hours');
        }
      }
    }

    if (data.volunteers_needed_diff) {
      for (const shift_id in data.volunteers_needed_diff) {
        const new_volunteers_needed_diff = data.volunteers_needed_diff[shift_id];

        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          const new_percentage = this.updateProgressBarTotal(shift_card.querySelector(this.progressSelector), new_volunteers_needed_diff);
          shift_card.classList.toggle('team-full', new_percentage == 100);
          animateEvent(shift_card, 'volunteers_needed_diff');
        }

        const shift_list = this.element.querySelector(`[data-planning-schedule-team-registrations-shift-id="${shift_id}"]`);

        for (const progress_bar_element of this.element.querySelectorAll(`[data-planning-schedule-progress-shift-id-${shift_id}]`)) {
          this.updateProgressBarTotal(progress_bar_element.querySelector(this.progressSelector), new_volunteers_needed_diff);
        }
      }
    }

    if (data.decrement_counter) {
      for (const shift_id of data.decrement_counter) {
        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          const new_percentage = this.decreaseProgressBarCount(shift_card.querySelector(this.progressSelector));
          shift_card.classList.toggle('team-full', false);
          animateEvent(shift_card, 'decrement_counter');
        }

        for (const progress_bar_element of this.element.querySelectorAll(`[data-planning-schedule-progress-shift-id-${shift_id}]`)) {
          this.decreaseProgressBarCount(progress_bar_element.querySelector(this.progressSelector));
        }
      }
    }

    if (data.decrement_unconfirmed_counter) {
      for (const shift_id of data.decrement_unconfirmed_counter) {
        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          const new_percentage = this.decreaseProgressBarUnconfirmedCount(shift_card.querySelector(this.progressSelector));
          shift_card.classList.toggle('team-full', false);
          animateEvent(shift_card, 'decrement_unconfirmed_counter');
        }

        for (const progress_bar_element of this.element.querySelectorAll(`[data-planning-schedule-progress-shift-id-${shift_id}]`)) {
          this.decreaseProgressBarUnconfirmedCount(progress_bar_element.querySelector(this.progressSelector));
        }
      }
    }

    if (data.increment_counter) {
      for (const shift_id of data.increment_counter) {
        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          const new_percentage = this.increaseProgressBarCount(shift_card.querySelector(this.progressSelector));
          if (new_percentage == 100) {
            shift_card.classList.toggle('team-full', true);
            animateEvent(shift_card, 'increment_counter');
          }
        }

        for (const progress_bar_element of this.element.querySelectorAll(`[data-planning-schedule-progress-shift-id-${shift_id}]`)) {
          this.increaseProgressBarCount(progress_bar_element.querySelector(this.progressSelector));
        }
      }
    }

    if (data.increment_unconfirmed_counter) {
      for (const shift_id of data.increment_unconfirmed_counter) {
        const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
        if (shift_card != null) {
          const new_percentage = this.increaseProgressBarUnconfirmedCount(shift_card.querySelector(this.progressSelector));
          if (new_percentage == 100) {
            shift_card.classList.toggle('team-full', true);
            animateEvent(shift_card, 'increment_unconfirmed_counter');
          }
        }

        for (const progress_bar_element of this.element.querySelectorAll(`[data-planning-schedule-progress-shift-id-${shift_id}]`)) {
          this.increaseProgressBarUnconfirmedCount(progress_bar_element.querySelector(this.progressSelector));
        }
      }
    }

    if (data.removed_team_registrations) {
      for (const reg_id of data.removed_team_registrations) {
        for (const reg_element of this.element.querySelectorAll(`[data-planning-schedule-team-registration-id="${reg_id}"]`)) {
          const shift_card = reg_element.closest('.team-shift-card');
          reg_element.remove();
          if (shift_card != null) {
            animateEvent(shift_card, 'removed_team_registrations');
          }
        }
      }
    }

    if (data.new_team_registrations) {
      for (const new_team_registration of data.new_team_registrations) {
        for (const shift_id in new_team_registration) {
          const shift_list = this.element.querySelector(`[data-planning-schedule-team-registrations-shift-id="${shift_id}"]`);
          const team_registration_html = new_team_registration[shift_id].html;
          const team_registration_sort_key = new_team_registration[shift_id].sort_key.toLowerCase();

          let insert_before_sibling = null;
          for (const sibling_element of shift_list.querySelectorAll(':scope > [data-planning-schedule-sort-key]')) {
            if (sibling_element.dataset.planningScheduleSortKey == '-' || sibling_element.dataset.planningScheduleSortKey.toLowerCase() > team_registration_sort_key) {
              insert_before_sibling = sibling_element;
              break;
            }
          }
          if (insert_before_sibling) {
            insert_before_sibling.insertAdjacentHTML('beforebegin', team_registration_html);
          } else {
            shift_list.insertAdjacentHTML('beforeend', team_registration_html);
          }
          $(shift_list).find('[data-toggle=tooltip]').tooltip({ trigger: 'hover' });
          const shift_card = this.element.querySelector(`[data-planning-schedule-shift-id="${shift_id}"]`);
          if (shift_card != null) {
            animateEvent(shift_card, 'new_team_registrations');
          }
        }
      }
      // if new team registration comes from side panel and suggestions, refresh side panel
      // to remove enrolled collaborators from suggestions
      this._refreshSidePanelForChangedPeriod();
    }
  }

  _refreshSidePanelForChangedPeriod = () => {
    if (this.planningToggleModeButtonTarget.classList.contains('active')) {
      /* we need to find the controller as this is a bit weird here with inhetiance??? (this._refreshPlanningPanelContent function not found) */
      const controller = this.application.getControllerForElementAndIdentifier(this.element, 'planning-schedule');
      if (controller) {
        setTimeout(() => { controller._refreshPlanningPanelContent(); }, 1000);
      }
    }
  }

  dismissToaster = (event) => {
    if (this.dismissToasterTimer) {
      clearTimeout(this.dismissToasterTimer);
      this.dismissToasterTimer = null;
    }
    this.toasterContentTarget.classList.toggle('hide', true);
  }

  _showToaster(content, type) {
    if (this.dismissToasterTimer) {
      clearTimeout(this.dismissToasterTimer);
    }

    if (type == 'error') {
      this.toasterContentTarget.classList.toggle('alert-danger', true);
      this.toasterContentTarget.classList.toggle('alert-success', false);
    } else if (type == 'success') {
      this.toasterContentTarget.classList.toggle('alert-danger', false);
      this.toasterContentTarget.classList.toggle('alert-success', true);
    }
    this.toasterContentTarget.classList.toggle('hide', false);
    this.toasterContentTarget.innerHTML = content;

    this.dismissToasterTimer = setTimeout(this._autoDismissTimer, 30000);
  }

  _autoDismissTimer = () => {
    this.dismissToasterTimer = null;
    this.toasterContentTarget.classList.toggle('hide', true);
  }

  startMovingTeamRegistration = (event) => {
    const real_source = this.teamRegistrationMoveSource(event.target);
    event.dataTransfer.setData('beeple/move-team-registration',
      [
        real_source.closest('[data-beeple-team-id]').dataset.beepleTeamId,
        real_source.dataset.planningScheduleTeamRegistrationMoveDirectUrl,
        real_source.dataset.planningScheduleVolunteerId,
        real_source.dataset.planningScheduleTabId,
        real_source.dataset.planningScheduleTabLabel,
        real_source.dataset.planningScheduleHappeningId,
      ].join(';'));
  }

  checkTeamRegistrationDragEnter = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', true);
  }

  checkTeamRegistrationDragOver = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();
  }

  checkTeamRegistrationDragExit = (event) => {
    event.preventDefault();

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', false);
  }

  toggleEditable = (event) => {
    const target = event.target.closest('.editable');
    event.preventDefault();
    event.stopPropagation();

    const clickOutsidehandler = (event_outside) => {
      const outside_target = event_outside.target;
      /* Check if event is really outside */
      if (target.contains(outside_target)) {
        return;
      }

      /* Check if event is not in a datetime picker */
      if (outside_target.closest('.ui-datepicker')) {
        return;
      }

      target.dataset.editing = 'false';
      target.classList.toggle('editing');
      document.removeEventListener('click', clickOutsidehandler);

      $(target.querySelector('form')).submit();
    };

    if (target.dataset.editing != 'true') {
      target.dataset.editing = 'true';
      target.classList.toggle('editing');

      target.querySelector('input:not([type=hidden])').focus();

      document.addEventListener('click', clickOutsidehandler);
    }
  }

  startDragPlanning = (event) => {
    const team_ids = event.target.dataset.planningScheduleHighlightTeamIds;
    const team_ids_for_application = event.target.dataset.planningScheduleTeamApplicationTeamIds;
    for (const team_id of team_ids.split(',')) {
      for (const team_container of document.querySelectorAll(`[data-planning-schedule-team-select-id="${team_id}"]`)) {
        team_container.classList.toggle('highlight', true);
      }
    }
    this.element.classList.toggle('planning-mode', true);
    this.dragTeamLeaveExitCounter = 0;
    event.dataTransfer.setData('beeple/apply-suggestion',
      [
        team_ids,
        event.target.dataset.planningScheduleVolunteerId,
        event.target.dataset.planningScheduleVolunteerName,
        team_ids_for_application,
      ].join(';'));
  }

  endDragPlanning = (event) => {
    this.element.classList.toggle('planning-mode', false);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    for (const team_container of document.querySelectorAll('.team-shift-card.highlight')) {
      team_container.classList.toggle('highlight', false);
      team_container.classList.toggle('currentselected', false);
    }
    event.preventDefault();
  }

  findTeamDragTarget(targetish) {
    if (targetish.nodeName == '#text') {
      targetish = targetish.parentElement;
    }
    return targetish.closest('.team-shift-card');
  }

  teamIsValidDropTarget(target, data) {
    const team_ids = data.split(';')[0].split(',');
    return team_ids.includes(target.dataset.planningScheduleTeamSelectId);
  }

  dragEnterTeam = (event) => {
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    const target = this.findTeamDragTarget(event.target);

    if (this.teamIsValidDropTarget(target, data)) {
      if (this.dragTeamLeaveExitCounter == 0) {
        target.classList.toggle('currentselected', true);
      }
      this.dragTeamLeaveExitCounter += 1;
    }
    event.preventDefault();
  }

  dragOverTeam = (event) => {
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    const target = this.findTeamDragTarget(event.target);

    if (this.teamIsValidDropTarget(target, data)) {
      event.preventDefault();
    }
  }

  dragLeaveTeam = (event) => {
    const target = this.findTeamDragTarget(event.target);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    if (!this.teamIsValidDropTarget(target, data)) {
      return;
    }
    this.dragTeamLeaveExitCounter -= 1;
    if (this.dragTeamLeaveExitCounter == 0) {
      target.classList.toggle('currentselected', false);
    }
  }

  dropTeam = (event) => {
    const target = this.findTeamDragTarget(event.target);
    const data = event.dataTransfer.getData('beeple/apply-suggestion');
    if (data == '') { /* Seems to be happening when moving people around */
      return;
    }

    const [team_ids, volunteer_id, volunteer_name, team_ids_for_applications] = data.split(';');
    const team_id = target.dataset.planningScheduleTeamSelectId;
    if (team_ids_for_applications.split(',').map((x) => x.split('-')[0]).includes(team_id)) {
      const team_application_id_tuple = team_ids_for_applications.split(',').find((x) => x.split('-')[0] == team_id);
      const team_application_id = team_application_id_tuple.split('-')[1];
      const secondary_tab_url = target.dataset.planningScheduleAcceptTeamApplicationUrl.replace('TEAM_APPLICATION_ID', team_application_id);

      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

      const secondary_tab_id = team_application_id;
      const secondary_tab_label = target.dataset.planningScheduleTeamRegistrationLabel.replace('VOLUNTEER_NAME', volunteer_name);
      side_panel_controller.createSecondaryTab(
        'accept-team-application',
        secondary_tab_id,
        secondary_tab_label,
        secondary_tab_url,
        true,
        null,
        target.dataset.planningScheduleTeamSelectId,
      );
    } else {
      const secondary_tab_url = target.dataset.planningScheduleTeamRegistrationUrl.replace('VOLUNTEER_ID', volunteer_id);

      const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');

      const secondary_tab_id = volunteer_id;
      const secondary_tab_label = target.dataset.planningScheduleTeamRegistrationLabel.replace('VOLUNTEER_NAME', volunteer_name);
      side_panel_controller.createSecondaryTab(
        'new-teamregistration',
        secondary_tab_id,
        secondary_tab_label,
        secondary_tab_url,
        true,
        null,
        target.dataset.planningScheduleTeamSelectId,
      );
    }
  }

  moveTeamRegistrationAfterDrop = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();

    const data = event.dataTransfer.getData('beeple/move-team-registration');
    const source_team_registration_url = data.split(';')[1];

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', false);

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    side_panel_controller.createSecondaryTab(
      'move',
      data.split(';')[3],
      data.split(';')[4],
      source_team_registration_url,
      true,
      {
        volunteers_team_registration: {
          team_id: real_target.dataset.beepleTeamId,
        },
      },
      real_target.dataset.beepleTeamId,
    );
  }

  replaceTeamRegistrationAfterDrop = (event) => {
    if (!this.allowMoveTeamRegistrationEvent(event)) {
      return;
    }
    event.preventDefault();

    const data = event.dataTransfer.getData('beeple/move-team-registration');

    const real_target = this.teamRegistrationMoveTarget(event.target);
    real_target.classList.toggle('drop-target', false);

    const target_team_registration_url = real_target.dataset.planningScheduleTeamRegistrationReplaceDirectUrl;
    const source_team_id = data.split(';')[0];
    const source_team_volunteer_id = data.split(';')[2];

    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    side_panel_controller.createSecondaryTab(
      'replace',
      data.split(';')[3],
      data.split(';')[4],
      target_team_registration_url,
      false,
      {
        source_team: source_team_id,
        new_volunteer_id: source_team_volunteer_id,
      },
      source_team_id,
    );
  }

  allowMoveTeamRegistrationEvent(event) {
    if (!event.dataTransfer.types.includes('beeple/move-team-registration')) {
      return false;
    }
    const data = event.dataTransfer.getData('beeple/move-team-registration');
    const team_id = data.split(';')[0];
    const real_target = this.teamRegistrationMoveTarget(event.target);
    if (real_target.dataset.beepleTeamId == team_id) {
      return false;
    }
    if (real_target.dataset.planningScheduleVolunteerId != undefined) {
      /* This is a replace. check if same project */
      const happening_id = data.split(';')[5];
      if (real_target.dataset.planningScheduleHappeningId != happening_id) {
        return false;
      }
    }

    return true;
  }

  teamRegistrationMoveTarget(target) {
    if (target.nodeName == '#text') {
      target = target.parentNode;
    }
    return target.closest('li');
  }

  teamRegistrationMoveSource(target) {
    if (target.nodeName == '#text') {
      target = target.parentNode;
    }
    return target.closest('li');
  }

  updateProgressBarTotal(progress_bar, diff) {
    if (progress_bar == null) {
      return 0;
    }
    /* TODO: make progress bar a stimulus controller */
    let percentage;
    if (this.showDetailedProgressBar) {
      progress_bar.dataset.total = parseInt(progress_bar.dataset.total) + diff;
      percentage = this.rerenderProgressPlanning(progress_bar);
    } else {
      progress_bar.dataset.progressTotal = parseInt(progress_bar.dataset.progressTotal) + diff;
      percentage = this.rerenderProgressBar(progress_bar);
    }
    return percentage;
  }

  decreaseProgressBarCount(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    /* TODO: make progress bar a stimulus controller */
    let percentage;
    if (this.showDetailedProgressBar) {
      progress_bar.dataset.confirmed = parseInt(progress_bar.dataset.confirmed) - 1;
      percentage = this.rerenderProgressPlanning(progress_bar);
    } else {
      progress_bar.dataset.progressFill = parseInt(progress_bar.dataset.progressFill) - 1;
      percentage = this.rerenderProgressBar(progress_bar);
    }
    return percentage;
  }

  decreaseProgressBarUnconfirmedCount(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    progress_bar.dataset.unconfirmed = parseInt(progress_bar.dataset.unconfirmed) - 1;
    return this.rerenderProgressPlanning(progress_bar);
  }

  increaseProgressBarUnconfirmedCount(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    progress_bar.dataset.unconfirmed = parseInt(progress_bar.dataset.unconfirmed) + 1;
    return this.rerenderProgressPlanning(progress_bar);
  }

  increaseProgressBarCount(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    let percentage;
    if (this.showDetailedProgressBar) {
      progress_bar.dataset.confirmed = parseInt(progress_bar.dataset.confirmed) + 1;
      percentage = this.rerenderProgressPlanning(progress_bar);
    } else {
      progress_bar.dataset.progressFill = parseInt(progress_bar.dataset.progressFill) + 1;
      percentage = this.rerenderProgressBar(progress_bar);
    }
    return percentage;
  }

  rerenderProgressBar(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    let new_percentage = (progress_bar.dataset.progressFill / progress_bar.dataset.progressTotal) * 100;
    if (progress_bar.dataset.progressTotal == 0) {
      new_percentage = 100;
    }
    const progress_element = progress_bar.querySelectorAll('.progressx-limiter')[0];
    progress_element.style.left = `${new_percentage}%`;
    progress_element.querySelector('.progressx-text').style.left = `${-new_percentage}%`;
    for (const text_element of progress_bar.querySelectorAll('.progressx-text')) {
      text_element.innerHTML = `${progress_bar.dataset.progressFill.toString()} / ${progress_bar.dataset.progressTotal.toString()}`;
    }
    return new_percentage;
  }

  rerenderProgressPlanning(progress_bar) {
    if (progress_bar == null) {
      return 0;
    }
    const container = progress_bar.querySelector('.progress-planning-container');

    if (container == null) {
      return 0;
    }
    const remaining = Number(progress_bar.dataset.total) - (Number(progress_bar.dataset.unconfirmed) + Number(progress_bar.dataset.confirmed));
    let new_percentage = (progress_bar.dataset.confirmed / progress_bar.dataset.total) * 100;

    if (progress_bar.dataset.total == 0) {
      new_percentage = 100;
    }
    const confirmed_element = progress_bar.querySelector('.progress-planning-bar-confirmed');
    let remaning_element = progress_bar.querySelector('.progress-planning-bar-remaining');
    const new_remaining_percentage = (remaining / Number(progress_bar.dataset.total)) * 100;
    const new_unconfirmed_percentage = (progress_bar.dataset.unconfirmed / progress_bar.dataset.total) * 100;
    const unconfirmed_element = progress_bar.querySelector('.progress-planning-bar-unconfirmed');

    this.replaceBar(
      container,
      this.progressPlanningBar(progress_bar.dataset.confirmed, new_percentage, 'confirmed'),
      confirmed_element,
    );
    if (remaning_element) {
      container.removeChild(remaning_element);
      remaning_element = null;
    }
    this.replaceBar(
      container,
      this.progressPlanningBar(progress_bar.dataset.unconfirmed, new_unconfirmed_percentage, 'unconfirmed'),
      unconfirmed_element,
    );
    this.replaceBar(
      container,
      this.progressPlanningBar(remaining, new_remaining_percentage, 'remaining'),
      remaning_element,
    );

    progress_bar.querySelector('.tooltip-info').dataset.originalTitle = `
    <span class="progress-planning-mark progress-planning-mark-confirmed"></span>
    <span>${progress_bar.dataset.confirmed}</span>
    <span class="progress-planning-mark progress-planning-mark-unconfirmed"></span>
    <span>${progress_bar.dataset.unconfirmed}</span>
    <span class="progress-planning-mark progress-planning-mark-remaining"></span>
    <span>${remaining}</span>
    <span> / ${progress_bar.dataset.total}</span>
    `;
    return new_percentage;
  }

  replaceBar(container, new_element, old_element) {
    if (old_element) {
      container.replaceChild(new_element, old_element);
    } else {
      container.appendChild(new_element);
    }
  }

  progressPlanningBar(label, completion_rate, type) {
    if (completion_rate <= 0) {
      return document.createTextNode('');
    }
    const bar = document.createElement('div');
    bar.className = `progress-planning-bar progress-planning-bar-${type}`;
    bar.style.width = `${completion_rate}%`;
    bar.innerHTML = `<span class="ellipsis">${label}</span>`;
    return bar;
  }

  _showSelectCheckboxes(show) {
    // toggle visibility of column and row selection checkboxes
    this.columnSelectCheckboxTargets.forEach((el) => {
      el.classList.toggle('hide', !show);
    });
    this.rowSelectCheckboxTargets.forEach((el) => {
      el.classList.toggle('hide', !show);
    });
  }

  // keepPreviouslySelected is true if user selected whole row or column, otherwise - false
  _toggleSelectCardsOfTeam(teamId, keepPreviouslySelected) {
    const previouslySelected = this.selectedTeamIds.includes(teamId);
    if (previouslySelected && !keepPreviouslySelected) {
      this.selectedTeamIds = this.selectedTeamIds.filter((el) => el !== teamId);
    } else {
      this.selectedTeamIds.push(teamId);
    }

    for (const teamCard of this.element.querySelectorAll(`[data-planning-schedule-team-select-id='${teamId}']`)) {
      teamCard.classList.toggle('selected', !previouslySelected || keepPreviouslySelected);
      this.applySelectedStyle(teamCard);
    }
  }

  toggleSelectRow = (event) => {
    const groupId = event.target.dataset.rowGroupId;
    const select = event.target.checked;

    let members = this.teamShiftCardTargets.filter((el) => (el.dataset.rowGroupId.startsWith(groupId)));

    members = members.map((el) => el.dataset.planningScheduleTeamSelectId);

    // get an array of distinct teamIds
    const teamIds = members.filter(distinct);

    teamIds.forEach((teamId) => {
      this._toggleSelectCardsOfTeam(teamId, select);
    });

    // also (un)check the descendant checkboxes
    members = this.rowSelectCheckboxTargets.filter((el) => (el.dataset.rowGroupId.startsWith(groupId)));

    members.forEach((el) => {
      el.checked = select;
    });
    this._enforcePlanningMode();
  }

  toggleSelectColumn = (event) => {
    const groupId = event.target.dataset.columnGroupId;
    const select = event.target.checked;

    let members = this.teamShiftCardTargets.filter((el) => (el.dataset.columnGroupId === groupId));

    members = members.map((el) => el.dataset.planningScheduleTeamSelectId);

    // get an array of distinct teamIds
    const teamIds = members.filter(distinct);

    teamIds.forEach((teamId) => {
      this._toggleSelectCardsOfTeam(teamId, select);
    });
    this._enforcePlanningMode();
  }

  applySelectedStyle(teamCard) {
    if (teamCard.dataset.selectedForeground) {
      const { selectedForeground } = teamCard.dataset;
      const { selectedBackground } = teamCard.dataset;
      const { unselectedForeground } = teamCard.dataset;
      const { unselectedBackground } = teamCard.dataset;
      const selectedStyle = `color: ${selectedForeground}; background-color: ${selectedBackground}`;
      const unselectedStyle = `color: ${unselectedForeground}; background-color: ${unselectedBackground}`;
      teamCard.style.cssText = teamCard.classList.contains('selected') ? selectedStyle : unselectedStyle;
    }
  }
}
