import { Controller } from 'stimulus';
import { setupAnimations } from './beeple_lib';

export default class extends Controller {
  connect() {
    this.group_collapsing = false;

    const sortableGroup = this.sortableGroupingItemsTarget;
    $(sortableGroup).sortable({
      items: '.checkbox',
      update: (event, ui) => {
        this._refreshContent();
      },
    });

    const multiDateSelect = this.multiDateSelectTarget;
    const startDate = this.startDateTarget;
    const endDate = this.endDateTarget;

    multiDateSelect.dataset.selectingStartDate = 'true';
    $(multiDateSelect).datepicker({
      numberOfMonths: 4,
      beforeShowDay: (date) => {
        const first_date_selected = moment($(startDate).val(), 'L');
        const end_date_selected = moment($(endDate).val(), 'L');

        const moment_date = moment(date);
        if (moment_date.isSame(first_date_selected)) {
          return [true, 'selected', ''];
        }

        if (multiDateSelect.dataset.selectingStartDate != 'false') {
          if (moment_date.isSame(end_date_selected)) {
            return [true, 'selected', ''];
          }
          if (moment_date.isBetween(first_date_selected, end_date_selected)) {
            return [true, 'selected', ''];
          }
          return [true, '', ''];
        }
        return [true, '', ''];
      },
      dateFormat: 'dd-mm-yy',
      onSelect: (date_text, date_picker) => {
        const date = moment(date_text, 'L');
        const date_text_formatted = date.format('L');
        const change_event = document.createEvent('Event');

        // Define that the event name is 'build'.
        change_event.initEvent('change', true, true);

        if (multiDateSelect.dataset.selectingStartDate == 'true') {
          $(startDate).datepicker('setDate', date_text_formatted);
          multiDateSelect.dataset.selectingStartDate = 'false';
        } else {
          $(endDate).datepicker('setDate', date_text_formatted);
          multiDateSelect.dataset.selectingStartDate = 'true';

          const start_date = moment($(startDate).val(), 'L');
          if (date.isBefore(start_date)) {
            $(startDate).datepicker('setDate', date.format('L'));
            $(endDate).datepicker('setDate', start_date.format('L'));
          }

          endDate.dispatchEvent(change_event);
        }
      },
    });

    const h = () => {
      $(endDate).datepicker('option', 'minDate', startDate.value);
      $(multiDateSelect).datepicker('refresh');
    };
    setTimeout(h, 1000);

    setupAnimations();
  }

  workaroundForForms = (event) => {
    if (event.target.tagName == 'INPUT') {
      if (event.target.type != 'text') {
        this._refreshContent();
      }
    }
  }

  workaroundForForms2 = (event) => {
    if (event.target.tagName == 'INPUT') {
      if (event.target.type != 'text') {
        this._refreshContent2(event.target.closest('[data-controller~="form-refresh2"]'));
      }
    }
  }

  openSidePanelAction = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const target = event.target.closest('[data-planning-schedule-action-url]') || event.target;

    let secondary_tab_id;
    let secondary_tab_label;
    const main_tab_id = target.dataset.planningScheduleMainTabId || target.closest('[data-planning-schedule-main-tab-id]').dataset.planningScheduleMainTabId;
    if (main_tab_id != 'planning') {
      secondary_tab_id = target.dataset.planningScheduleTabId || target.closest('[data-planning-schedule-tab-id]').dataset.planningScheduleTabId;
      secondary_tab_label = target.dataset.planningScheduleTabLabel || target.closest('[data-planning-schedule-tab-label]').dataset.planningScheduleTabLabel;
    }
    const secondary_tab_url = target.dataset.planningScheduleActionUrl;
    let team_id = target.dataset.planningScheduleTeamId;
    if (team_id == undefined) {
      const parent = target.closest('[data-planning-schedule-team-id]');
      if (parent != undefined) {
        team_id = parent.dataset.planningScheduleTeamId;
      }
    }

    let volunteer_id = target.dataset.planningScheduleVolunteerId;
    if (volunteer_id == undefined) {
      const parent = target.closest('[data-planning-schedule-volunteer-id]');
      if (parent != undefined) {
        volunteer_id = parent.dataset.planningScheduleVolunteerId;
      }
    }

    let team_registration_id = target.dataset.planningScheduleTeamRegistrationId;
    if (team_registration_id == undefined) {
      const parent = target.closest('[data-planning-schedule-team-registration-id]');
      if (parent != undefined) {
        team_registration_id = parent.dataset.planningScheduleTeamRegistrationId;
      }
    }

    let team_application_id = target.dataset.planningScheduleTeamApplicationId;
    if (team_application_id == undefined) {
      const parent = target.closest('[data-planning-schedule-team-application-id]');
      if (parent != undefined) {
        team_application_id = parent.dataset.planningScheduleTeamApplicationId;
      }
    }

    side_panel_controller.createSecondaryTab(
      main_tab_id,
      secondary_tab_id,
      secondary_tab_label,
      secondary_tab_url,
      target.dataset.planningScheduleActionMethod == 'post',
      null,
      team_id,
      volunteer_id,
      team_registration_id,
      team_application_id,
    );
  }

  expandAll = (event) => {
    event.preventDefault();

    this.group_collapsing = true;
    for (const element of this.element.querySelectorAll('[data-collapse-group-id]')) {
      this._setToggleCollapseGroup(element.dataset.collapseGroupId, false, element);
    }
    const form_refresh = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    form_refresh.executeRequest(true);
    this.group_collapsing = false;
  }

  collapseAll = (event) => {
    event.preventDefault();

    this.group_collapsing = true;
    for (const element of this.element.querySelectorAll('[data-collapse-group-id]')) {
      this._setToggleCollapseGroup(element.dataset.collapseGroupId, true, element);
    }
    const form_refresh = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    form_refresh.executeRequest(true);
    this.group_collapsing = false;
  }

  refreshDatePicker = (event) => {
    const f = () => {
      $(this.endDateTarget).datepicker('option', 'minDate', this.startDateTarget.value);
      $(this.multiDateSelectTarget).datepicker('refresh');
      this._refreshSidePanelForChangedPeriod();
    };
    setTimeout(f, 100);
  }

  directTeamApplicationAction = (event) => {
    event.preventDefault();
    const side_panel_controller = this.application.getControllerForElementAndIdentifier(this.sidePanelTarget, 'schedule-view-side-panel');
    const target = event.target.closest('[data-planning-schedule-accept-team-application-url]');
    const action_name = event.target.closest('[data-action-name]').dataset.actionName;
    let tab_name;
    switch (action_name) {
      case 'Accept':
        tab_name = 'accept-team-application';
        break;
      case 'Reject':
        tab_name = 'reject-team-application';
        break;
      case 'Standby':
        tab_name = 'standby-team-application';
        break;
      default:
    }

    const main_tab_id = target.dataset.planningScheduleMainTabId;
    const secondary_tab_id = target.dataset.planningScheduleSecondaryTabId;
    const secondary_tab_label = target.dataset.planningScheduleTabLabel;
    const secondary_tab_url = target.dataset[`planningSchedule${action_name}TeamApplicationUrl`];
    const team_id = target.dataset.planningScheduleTeamId;
    side_panel_controller.createSecondaryTab(
      tab_name,
      secondary_tab_id,
      secondary_tab_label,
      secondary_tab_url,
      action_name == 'Accept',
      null,
      team_id,
    );
  }

  _selectPeriod(start_date, end_date) {
    const startDateInput = this.startDateTarget;
    const endDateInput = this.endDateTarget;

    $(startDateInput).datepicker('setDate', start_date.format('L'));
    $(endDateInput).datepicker('setDate', end_date.format('L'));

    this._refreshContent();
    this._refreshSidePanelForChangedPeriod();
    $(this.multiDateSelectTarget).datepicker('setDate', start_date.toDate());
  }

  _refreshSidePanelForChangedPeriod() {
    // Basic NO-OP
  }

  selectThisWeek = (event) => {
    const weekday_offset = this.element.dataset.planningScheduleWeekdayOffset;
    if (moment().day() >= weekday_offset) {
      this._selectPeriod(moment().day(weekday_offset),
        moment().day(weekday_offset).add('days', 6));
    } else {
      this._selectPeriod(moment().day(weekday_offset).add('weeks', -1),
        moment().day(weekday_offset).add('weeks', -1).add('days', 6));
    }
  }

  selectNextWeek = (event) => {
    const weekday_offset = this.element.dataset.planningScheduleWeekdayOffset;
    if (moment().day() >= weekday_offset) {
      this._selectPeriod(moment().day(weekday_offset).add('weeks', 1),
        moment().day(weekday_offset).add('weeks', 1).add('days', 6));
    } else {
      this._selectPeriod(moment().day(weekday_offset),
        moment().day(weekday_offset).add('days', 6));
    }
  }

  selectPreviousWeek = (event) => {
    const weekday_offset = this.element.dataset.planningScheduleWeekdayOffset;
    if (moment().day() >= weekday_offset) {
      this._selectPeriod(moment().day(weekday_offset).add('weeks', -1),
        moment().day(weekday_offset).add('weeks', -1).add('days', 6));
    } else {
      this._selectPeriod(moment().day(weekday_offset).add('weeks', -2),
        moment().day(weekday_offset).add('weeks', -2).add('days', 6));
    }
  }

  selectThisMonth = (event) => {
    const month_offset = this.element.dataset.planningScheduleMonthOffset;
    if (moment().date() >= month_offset) {
      this._selectPeriod(moment().date(month_offset),
        moment().date(month_offset).add('months', 1).add('days', -1));
    } else {
      this._selectPeriod(moment().date(month_offset).add('months', -1),
        moment().date(month_offset).add('days', -1));
    }
  }

  selectNextMonth = (event) => {
    const month_offset = this.element.dataset.planningScheduleMonthOffset;
    if (moment().date() >= month_offset) {
      this._selectPeriod(moment().date(month_offset).add('months', 1),
        moment().date(month_offset).add('months', 2).add('days', -1));
    } else {
      this._selectPeriod(moment().date(month_offset),
        moment().date(month_offset).add('months', 1).add('days', -1));
    }
  }

  selectPreviousMonth = (event) => {
    const month_offset = this.element.dataset.planningScheduleMonthOffset;
    if (moment().date() >= month_offset) {
      this._selectPeriod(moment().date(month_offset).add('months', -1),
        moment().date(month_offset).add('days', -1));
    } else {
      this._selectPeriod(moment().date(month_offset).add('months', -2),
        moment().date(month_offset).add('months', -1).add('days', -1));
    }
  }

  previousPeriod(event) {
    event.preventDefault();

    const startDateInput = this.startDateTarget;
    const endDateInput = this.endDateTarget;

    const oldStartDate = moment(startDateInput.value, 'L');
    const oldEndDate = moment(endDateInput.value, 'L');

    const newStartDate = oldStartDate.clone().add(moment.duration(oldStartDate.diff(oldEndDate)).asDays(), 'days').subtract(1, 'days');
    const newEndDate = oldStartDate.clone().subtract(1, 'days');

    $(startDateInput).datepicker('setDate', newStartDate.toDate());
    // next lines ensures minDate option doesn't prevent setting earlier date
    $(endDateInput).datepicker('option', 'minDate', newStartDate.toDate());
    $(endDateInput).datepicker('setDate', newEndDate.toDate());
    $(this.multiDateSelectTarget).datepicker('setDate', newStartDate.toDate());
    const isPrivate = event.target.dataset.privateRequest === 'true';
    this._refreshContent(isPrivate);
    this._refreshSidePanelForChangedPeriod();
  }

  nextPeriod(event) {
    event.preventDefault();
    const startDateInput = this.startDateTarget;
    const endDateInput = this.endDateTarget;

    const oldStartDate = moment(startDateInput.value, 'L');
    const oldEndDate = moment(endDateInput.value, 'L');

    const newStartDate = oldEndDate.clone().add(1, 'days');
    const newEndDate = oldEndDate.clone().add(moment.duration(oldEndDate.diff(oldStartDate)).asDays(), 'days').add(1, 'days');

    $(startDateInput).datepicker('setDate', newStartDate.toDate());
    $(endDateInput).datepicker('setDate', newEndDate.toDate());
    $(this.multiDateSelectTarget).datepicker('setDate', newStartDate.toDate());
    const isPrivate = event.target.dataset.privateRequest === 'true';
    this._refreshContent(isPrivate);
    this._refreshSidePanelForChangedPeriod();
  }

  loadThisWeek(event) {
    event.preventDefault();
    const startDate = this.startDateTarget;
    $(startDate).datepicker('setDate', moment().startOf('week').format('L'));

    const endDate = this.endDateTarget;
    $(endDate).datepicker('setDate', moment().endOf('week').format('L'));
    this._refreshContent();
    return false;
  }

  _refreshContent(private_request = false) {
    $(this.multiDateSelectTarget).datepicker('refresh');
    const controller = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    if (private_request) {
      controller.executePrivateRequest();
    } else {
      controller.refreshContent();
    }
  }

  _refreshContent2(child) {
    $(this.multiDateSelectTarget).datepicker('refresh');
    const controller = this.application.getControllerForElementAndIdentifier(child, 'form-refresh2');
    controller.refreshContent();
  }

  toggleCollapseGroup = (event) => {
    const row_header = event.target.closest('[data-collapse-group-id]');
    const is_currently_collapsed = row_header.dataset.collapsed == 'true';
    row_header.classList.toggle('is-collapsed', !is_currently_collapsed);

    const group_key = row_header.dataset.collapseGroupId;
    this._setToggleCollapseGroup(group_key, !(row_header.dataset.collapsed == 'true'), row_header);
  }

  _setToggleCollapseGroup(group_key, needs_to_be_collapsed, row_header) {
    row_header.classList.toggle('is-collapsed', needs_to_be_collapsed);

    if (!needs_to_be_collapsed) {
      for (const input_form of this.formTarget.querySelectorAll(`[name="happenings_planning_schedule_view[collapsed_keys][]"][value="${group_key}"]`)) {
        $(input_form).remove();
      }

      for (const input_form of this.formTarget.querySelectorAll(`[name="happenings_collaborators_schedule_view[collapsed_keys][]"][value="${group_key}"]`)) {
        $(input_form).remove();
      }
    } else {
      let input_element = $(`<input name="happenings_planning_schedule_view[collapsed_keys][]" value="${group_key}" type="hidden" />`);
      $(this.formTarget).append(input_element);

      input_element = $(`<input name="happenings_collaborators_schedule_view[collapsed_keys][]" value="${group_key}" type="hidden" />`);
      $(this.formTarget).append(input_element);
    }
    if (!this.group_collapsing) {
      const form_refresh = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
      form_refresh.executeRequest(true);
    }
    this._recurseToggleCollapseGroup(group_key, needs_to_be_collapsed);
    row_header.dataset.collapsed = needs_to_be_collapsed ? 'true' : 'false';
  }

  _recurseToggleCollapseGroup(group_key, needs_to_be_collapsed) {
    for (const element_to_collapse of this.element.querySelectorAll(`[data-collapse-key-${group_key}]`)) {
      element_to_collapse.classList.toggle('hide', needs_to_be_collapsed);

      if (element_to_collapse.dataset.collapseGroupId) {
        if (needs_to_be_collapsed || element_to_collapse.dataset.collapsed != 'true') {
          this._recurseToggleCollapseGroup(element_to_collapse.dataset.collapseGroupId, needs_to_be_collapsed);
        }
      }
    }
  }
}
