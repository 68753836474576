import { Controller } from 'stimulus';
import { highlightEvents, sidebarContents } from '../base/availabilities';

export default class extends Controller {
    static targets = ['form', 'formBody', 'availabilitySelector', 'windowSelector', 'startTimeInput', 'endTimeInput', 'startsAtInput', 'endsAtInput',
      'periodSelector', 'payrollSelector', 'durationInput', 'wholeDayInput', 'timeInput', 'repetitionInputs', 'useRepetitionInput',
      'nFollowingInstancesInput', 'startsAtInputErrorMessage', 'endsAtInputErrorMessage'];

    connect() {
      this.handlingLiveUpdate = false;

      if (this.hasWindowSelectorTarget) {
        $(this.windowSelectorTarget).on('change', this.liveUpdateAvailability);
        // trigger a change to set the initial state
        $(this.windowSelectorTarget).trigger('change');
      }

      if (this.hasPayrollSelectorTarget) {
        $(this.payrollSelectorTarget).on('change', this.togglePayrollDuration);
        // trigger a change to set the initial state
        $(this.payrollSelectorTarget).trigger('change');
      }

      if (this.hasWholeDayInputTarget) {
        this.wholeDayInputTarget.checked = (this.wholeDayInputTarget.dataset.initialValue === 'true');
      }

      if (this.availabilitySelectorTarget.selectedIndex > 0) {
        this.availabilitySelectorTarget.dispatchEvent(new CustomEvent('jquery:change'));
      }

      // initial highlight of event in calendar
      this.highlight();
      this.checkIfRunsOvernight(new CustomEvent('change'));
      this.toggleTimeInputs(new CustomEvent('change'));
      this.toggleRepetitionInputs(new CustomEvent('change'));
      this.checkIfPastPresentFuture(new CustomEvent('change'));
    }

    disconnect = () => {
      if (this.hasAvailabilitySelectorTarget && $(this.availabilitySelectorTarget).data('select2')) {
        // prevent orphan dropdowns to remain open when switching form quick create form to sidebar
        $(this.availabilitySelectorTarget).select2('destroy');
      }
    }

    onAvailabilityTypeChange = (event) => {
      event.stopPropagation();
      const buttons = document.querySelectorAll('.availabilities .btn.btn-submit-like');
      const { previousValue } = this.availabilitySelectorTarget.dataset;
      const newValue = this.availabilitySelectorTarget.value;

      if (newValue === '') {
        buttons.forEach((btn) => {
          btn.classList.add('hide');
        });
        const externalThis = this;
        // Inilining the .select2('open') does not open the dropdown when the user intentionally selects a null value.
        // scheduling it in a setTimeout works well. Must have something to do with the JS event loop.
        setTimeout(() => {
          $(externalThis.availabilitySelectorTarget).select2('open');
          externalThis.formBodyTarget.classList.add('hide');
        }, 250);
      } else {
        buttons.forEach((btn) => {
          btn.classList.remove('hide');
        });

        if (newValue !== previousValue) {
          // do a $.ajax(type: 'POST') to get the appropriate form for the enterAvailabilityPer type
          const url = this.availabilitySelectorTarget.dataset.formDetailsUrl;
          const data = $(this.formTarget).serialize();

          $.ajax({
            url,
            type: 'PATCH',
            data,
            error(_jqXHR, textStatus, errorThrown) {
              // log the error to the console
              console.error(`Error reading availability form: ${textStatus}`, errorThrown);
            },
          });
        }
      }
    };

    syncEndDate = (event) => {
      if (this.hasEndsAtInputTarget) {
        const startsAtInput = this.startsAtInputTarget;
        const endsAtInput = this.endsAtInputTarget;
        const startDate = $(startsAtInput).datepicker('getDate');
        const endDate = $(endsAtInput).datepicker('getDate');
        if (startDate > endDate) {
          $(startsAtInput).datepicker('setDate', startDate);
          $(endsAtInput).datepicker('setDate', startDate);
        }
        this.checkIfRunsOvernight(event);
      }
      this.checkIfRunsOvernight(event);
    }

    syncStartDate = (event) => {
      if (this.hasEndsAtInputTarget) {
        const startsAtInput = this.startsAtInputTarget;
        const endsAtInput = this.endsAtInputTarget;
        const startDate = $(startsAtInput).datepicker('getDate');
        const endDate = $(endsAtInput).datepicker('getDate');
        if (startDate > endDate) {
          $(startsAtInput).datepicker('setDate', endDate);
          $(endsAtInput).datepicker('setDate', endDate);
        }
        this.checkIfRunsOvernight(event);
      }
    }

    highlight() {
      if (this.hasFromTarget) {
        const form = this.formTarget;

        const { eventRepetitionId } = form.dataset;
        const { eventId } = form.dataset;
        highlightEvents(eventId, eventRepetitionId);
      }
    }

    liveUpdateAvailability = (event) => {
      event.stopPropagation();
      if (sidebarContents() === '' || this.availabilitySelectorTarget.value === '') {
        return;
      }
      if (this.handlingLiveUpdate) return;

      const form = this.formTarget;
      const modeInput = form.querySelector('input#mode');

      if (modeInput.value === 'cal') {
        // set flag to perform a dry run save of availability
        const dryRunInput = form.querySelector('input#dr');
        dryRunInput.value = 1;

        const data = $(form).serialize();
        this.handlingLiveUpdate = true;
        $.ajax({
          url: form.action,
          type: 'POST',
          data,
          error(_jqXHR, textStatus, errorThrown) {
            // log the error to the console
            console.error(`Error reading availability dry run: ${textStatus}`, errorThrown);
          },
          complete: () => {
            this.handlingLiveUpdate = false;
          },
        });
      }
    }

    checkIfRunsOvernight = (event) => {
      function calcTimeAt(date, timeStr) {
        const timeParts = timeStr.split(':');
        return new Date(date.getYear(), date.getMonth(), date.getDate(), parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0);
      }

      if (!this.hasStartsAtInputTarget) return;

      const overnightWarning = document.querySelector("[data-target='availabilities-form.overnightWarning']");
      let wholeDay = true;
      let startTime = '00:00';
      let endTime = '23:59';

      if (this.hasWholeDayInputTarget) {
        wholeDay = (this.wholeDayInputTarget.checked === true);
      }

      if (!wholeDay && this.hasStartTimeInputTarget) {
        startTime = this.startTimeInputTarget.value;
        endTime = this.endTimeInputTarget.value;
      }

      const startInput = document.getElementById('presenter_for_volunteers_availability_starts_at');
      const endInput = document.getElementById('presenter_for_volunteers_availability_ends_at');
      if (startInput !== undefined && endInput !== undefined) {
        let startDateTime = $(startInput).datetimepicker('getDate');
        let endDateTime = $(endInput).datetimepicker('getDate');
        startDateTime = moment(calcTimeAt(startDateTime, startTime));
        endDateTime = moment(calcTimeAt(endDateTime, endTime));
        const diffFloat = endDateTime.diff(startDateTime, true);
        const hours = diffFloat / (60 * 60 * 1000);

        if (hours > 24.0) {
          overnightWarning.classList.remove('hide');
        } else {
          overnightWarning.classList.add('hide');
        }
      }
      this.liveUpdateAvailability(event);
    };

    togglePayrollDuration = (_event) => {
      const payrollsThatCalculateDuration = this.payrollSelectorTarget.dataset.payrollsThatCalculateDuration.split(' ');
      const payrollCalculatesDuration = payrollsThatCalculateDuration.includes(this.payrollSelectorTarget.value);
      const hide = payrollCalculatesDuration || (this.payrollSelectorTarget.value === '');
      this.durationInputTarget.classList.toggle('hide', hide);
      if (hide) {
        this.durationInputTarget.querySelector('input').value = null;
      }
    }

    toggleTimeInputs = (event) => {
      if (this.hasWholeDayInputTarget) {
        const wholeDay = (this.wholeDayInputTarget.checked === true);
        if (this.hasTimeInputTarget) {
          const zeroHours = new Date(2000, 1, 1, 0, 0, 0, 0);
          const midnight = new Date(2000, 1, 1, 23, 59, 59, 0);
          const startTimeInput = document.getElementById('presenter_for_volunteers_availability_start_time');
          const endTimeInput = document.getElementById('presenter_for_volunteers_availability_end_time');

          let initialStartTimeHHMM;
          if (this.startTimeInputTarget.dataset.initialValue) {
            initialStartTimeHHMM = this.startTimeInputTarget.dataset.initialValue.split(':');
          } else initialStartTimeHHMM = [0, 0];
          const initialStartTime = new Date(2000, 1, 1, ...initialStartTimeHHMM, 0, 0);

          let initialEndTimeHHMM;
          if (this.endTimeInputTarget.dataset.initialValue) {
            initialEndTimeHHMM = this.endTimeInputTarget.dataset.initialValue.split(':');
          } else initialEndTimeHHMM = [23, 59];
          const initialEndTime = new Date(2000, 1, 1, ...initialEndTimeHHMM, 0, 0);

          if (wholeDay) {
            this.timeInputTargets.forEach((input) => {
              input.classList.add('hide');
            });
            $(startTimeInput).datetimepicker('setDate', zeroHours);
            $(endTimeInput).datetimepicker('setDate', zeroHours);
          } else {
            this.timeInputTargets.forEach((input) => {
              input.classList.remove('hide');
            });
            $(startTimeInput).datetimepicker('setDate', initialStartTime);
            if (this.endTimeInputTarget.dataset.initialValue === '00:00') {
              $(endTimeInput).datetimepicker('setDate', midnight);
            } else $(endTimeInput).datetimepicker('setDate', initialEndTime);
          }
        }
        const outerThis = this;
        setTimeout(() => {
          outerThis.liveUpdateAvailability(event);
        }, 200);
      }
    }

    toggleRepetitionInputs = (_event) => {
      if (this.hasUseRepetitionInputTarget) {
        const checked = (this.useRepetitionInputTarget.checked === true);
        if (this.hasRepetitionInputsTarget) {
          if (checked) {
            this.repetitionInputsTarget.classList.remove('hide');
          } else {
            this.repetitionInputsTarget.classList.add('hide');
          }
        }
      }
    }

    focusNfollowingInstances = (event) => {
      if (event.target.value === 'this_and_next_n') {
        this.nFollowingInstancesInputTarget.focus();
      }
      this.liveUpdateAvailability(event);
    }

    checkRadioNfollowingInstances = (event) => {
      const input = document.querySelector('input#volunteers_availability_repetition_change_which_ids_this_and_next_n');
      input.checked = 'checked';
      if (!isNaN(this.nFollowingInstancesInputTarget.value) && this.nFollowingInstancesInputTarget.value > 0) this.liveUpdateAvailability(event);
    }

    checkIfPastPresentFuture = (_event) => {
      if (this.hasStartsAtInputTarget) this.startsAtInputErrorMessageTarget.classList.add('hide');

      if (this.hasEndsAtInputTarget) this.endsAtInputErrorMessageTarget.classList.add('hide');

      let pastPresentFutureSetting = this.availabilitySelectorTarget.dataset.enterForPastPresentFuture;
      if (pastPresentFutureSetting != null) {
        pastPresentFutureSetting = pastPresentFutureSetting.split(',');
        pastPresentFutureSetting = pastPresentFutureSetting.filter((el) => el !== '');

        if (pastPresentFutureSetting.length === 5) return; // all dates allowed

        const errorMessage = this.element.dataset.messageCannotEnterFor;

        if (this.hasStartsAtInputTarget) {
          const startsAtInput = this.startsAtInputTarget;
          if (startsAtInput.disabled) return; // don't show period errors if input is disabled
          if (startsAtInput.dataset.hasErrors === 'true') return; // errors already shown

          const startDate = moment($(startsAtInput).datepicker('getDate')).startOf('day');
          if (this._notValidStart(startDate, pastPresentFutureSetting)) {
            this.startsAtInputErrorMessageTarget.querySelector('span').innerHTML = errorMessage;
            this.startsAtInputErrorMessageTarget.classList.remove('hide');
          }
        }
        if (this.hasEndsAtInputTarget) {
          const endsAtInput = this.endsAtInputTarget;
          const endDate = moment($(endsAtInput).datepicker('getDate')).startOf('day');
          if (this._notValidEnd(endDate, pastPresentFutureSetting)) {
            this.endsAtInputErrorMessageTarget.querySelector('span').innerHTML = errorMessage;
            this.endsAtInputErrorMessageTarget.classList.remove('hide');
          }
        }
      }
    }

    _notValidStart(startDate, pastPresentFutureSetting) {
      // period is not valid if ends in the past, begins in the past, in the present,
      // ends in the future, begins in the future
      let result;
      const today = moment().startOf('day');

      result = (startDate.isBefore(today) && !pastPresentFutureSetting.includes('past'));
      result = result || (startDate.isSame(today) && !pastPresentFutureSetting.includes('in_present'));
      result = result || (startDate.isAfter(today) && !pastPresentFutureSetting.includes('fully_future'));
      return result;
    }

    _notValidEnd(endDate, pastPresentFutureSetting) {
    // period is not valid if ends in the past, begins in the past, in the present,
    // ends in the future, begins in the future
      let result;
      const today = moment().startOf('day');

      result = (endDate.isBefore(today) && !pastPresentFutureSetting.includes('fully_past'));
      result = result || (endDate.isSame(today) && !pastPresentFutureSetting.includes('in_present'));
      result = result || (endDate.isAfter(today) && !pastPresentFutureSetting.includes('future'));
      return result;
    }
}
