import { Controller } from 'stimulus';

// Waterfall priorities forms.
// shared/payroll_allocations/waterfall_priorities/_new_modal.html.erb
// shared/payroll_allocations/waterfall_priorities/_edit_modal.html.erb

export default class extends Controller {
  static targets = ['percent', 'button', 'payrollServiceId'];

  connect() {
    if (this.hasPercentTarget) {
      this.percentTarget.addEventListener('input', this.percent);
    }

    if (this.hasPayrollServiceIdTarget) {
      const select2 = $(this.payrollServiceIdTarget);
      select2.on('change', this.requiredFields);
    }

    this.requiredFields();
  }

  percent = () => {
    let num = parseInt(this.percentTarget.value);
    if (num < 0) num = 0;
    if (num > 100) num = 100;
    if (this.percentTarget.value !== '') {
      this.percentTarget.value = num;
    }
    this.requiredFields();
  };

  requiredFields = () => {
    let missing = false;

    if (this.hasPayrollServiceIdTarget && this.payrollServiceIdTarget.value === '') {
      missing = true;
    }

    if (this.hasPercentTarget && this.percentTarget.value === '') {
      missing = true;
    }

    this.buttonTarget.disabled = missing;
  }
}
