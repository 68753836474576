import { Controller } from 'stimulus';

// controller: admin--tenant--manager-roles
export default class extends Controller {
  static targets = ['preset'];

  connect() {
    if (this.hasPresetTarget) {
      $(this.presetTarget).on('change', (event, _data) => {
        const preset = event.target.value;
        if (preset === 'customer') {
          this.applyCustomerPortalPreset();
          this.disableAdminAccess();
        }
        if (preset === 'none') {
          this.unselectAllRoles();
          this.enableAdminAccess();
        }
      });
      this.enableAdminAccess();
      this.enableOnChangeHandlers();
    }
  }

  customerPortalPerms = `
    r    beeple_customer_portal
    r    beeple_tenant_planning_rules
    r    beeple_tenant_functions
    r    beeple_tenant_planning_custom_fields
    r    beeple_tenant_profile_properties
    r    beeple_tenant_enrolment_properties
    r    beeple_tenant_departments
    crud beeple_tenant_addresses
    r    beeple_volunteers_volunteers
    r    beeple_volunteers_volunteers_all_data
    r    beeple_volunteers_volunteer_special_compensations
    r    beeple_volunteers_payroll_identifications
    r    beeple_volunteers_volunteer_profile_properties
    r    beeple_volunteers_volunteer_availabilities
    r    beeple_happenings_coaching
    crud beeple_volunteers_volunteer_blips
    r    beeple_happenings_happenings
    cr    beeple_happenings_checkins
    r    beeple_happenings_clusters
    crud beeple_happenings_teams
    r    beeple_happenings_teams_contracts
    r    beeple_happenings_team_profile_requirements
    r    beeple_happenings_team_enrolment_property_request
    crud beeple_happenings_team_registrations
    r    beeple_team_registration_contracts
    r    beeple_team_registration_property
    crud beeple_worked_hours
    crud beeple_worked_hours_confirmation
    crud beeple_worked_hour_premiums
    crud beeple_worked_hour_premiums_confirmation
    r    beeple_tenant_clients
  `;

  applyCustomerPortalPreset = () => {
    this.unselectAllRoles();

    const perms = [];
    this.customerPortalPerms.split('\n').forEach((line) => {
      const perm = line.trim().split(/ +/);
      if (perm[1]) perms.push(perm);
    });

    perms.forEach((perm) => {
      let element;
      const [code, name] = perm;
      const selector = `input[name="tenant_manager_role[${name}][]"] + div.toggle.btn-danger`;
      const input = this.element.querySelector(selector);
      if (!input) return;

      const tr = input.closest('tr');
      // enable the role
      tr.querySelector('.toggle.btn.btn-danger').click();
      // and any fine-grained permissions on that role
      code.split('').forEach((permission) => {
        element = tr.querySelector(`input[value='${permission}']:enabled`);
        if (element) element.click();
      });
    });
  };

  unselectAllRoles = () => {
    this.element.querySelectorAll('.toggle.btn.btn-success').forEach((el) => {
      el.click();
    });
  };

  enableAdminAccess = () => {
    this.toggleRole('beeple_admin_portal', true);
  };

  disableAdminAccess = () => {
    this.toggleRole('beeple_admin_portal', false);
  };

  toggleRole = (roleName, turnOn) => {
    const createRoleForm = document.querySelector(
      'form#new_tenant_manager_role',
    );
    // this should only apply when there ia a new role form
    if (createRoleForm) {
      const toggleBtnClass = turnOn
        ? 'btn-danger'
        : 'btn-success';
      const tergetToggle = document.querySelector(
        `[name="tenant_manager_role[${roleName}][]"] + div.toggle.${toggleBtnClass}`,
      );
      if (tergetToggle) tergetToggle.click();
    }
  };

  enableOnChangeHandlers = () => {
    // per spec: It ‘s not possible for the tenant admin to deactivate
    // both the ‘Admin portal’ and the 'Customer portal’ access right.
    const admin = $('#tenant_manager_role_beeple_admin_portal_r')[0];
    const customer = $('#tenant_manager_role_beeple_customer_portal_r')[0];
    if (admin && customer) {
      $(admin).on('change', () => this.keepOneEnabled(admin, customer));
      $(customer).on('change', () => this.keepOneEnabled(customer, admin));
    }
  };

  keepOneEnabled = (from, to) => {
    const fromChecked = $(from).prop('checked');
    const toChecked = $(to).prop('checked');
    if (fromChecked || toChecked) return;

    this.enableAdminAccess();
  };
}
