import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'phone', 'email', 'birthDate', 'nationalRegistrationNumber', 'remoteIdInput', 'inactiveWarning'];

  connect() {
    if (this.element.dataset.matchdata == undefined) {
      return; /* This is the blueprint */
    }

    this.load_match_data(JSON.parse(this.element.dataset.matchdata));
    // since match may contain an icon with tooltip we have to reinit it
    $('body').tooltip({ selector: '[data-toggle="tooltip"]' });
  }

  load_match_data(match) {
    this.remoteIdInputTarget.value = match.id;
    if (match.score >= 50) { /* this should be a unique match, so preselect */
      this.remoteIdInputTarget.checked = true;

      /* Needs to be done in timeout otherwise the handler is not called */
      setTimeout(() => {
        const event = new Event('selected');
        this.remoteIdInputTarget.dispatchEvent(event);
      }, 1);
    }

    this.nameTarget.innerHTML = match.name;
    this.emailTarget.innerHTML = match.email;
    this.birthDateTarget.innerHTML = match.birthDate;
    this.nationalRegistrationNumberTarget.innerHTML = match.nationalRegistrationNumbers.map((d) => d.nationalRegistrationNumber);
    this.phoneTarget.innerHTML = match.phone;
    if (match.inactive === true) {
      this.inactiveWarningTarget.classList.toggle('hidden', false);
    }
  }
}
