import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['latlonAppliedManually', 'latlon']

  coordinatesEnteredManually() {
    if (this.latlonTarget.value.length > 0) {
      this.latlonAppliedManuallyTarget.value = true;
    } else {
      this.latlonAppliedManuallyTarget.value = false;
    }
  }

  autocompleteInputChanged() {
    if (this.latlonTarget.value.length > 0) {
      // autocomplete succeeded
      this.latlonAppliedManuallyTarget.value = false;
    }
  }
}
