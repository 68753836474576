/*
    A group of shared functions to use between different js source files
    Just use:
        export function foo()
    and then in the source file you want to use foo:
        import { foo } from 'path/to/this/file/beeple_lib
*/

//
// Animation end events
// based on https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/
//
function whichAnimationEvent() {
  let t;
  const el = document.createElement('fakeelement');

  const animations = {
    animation: 'animationend',
    OAnimation: 'oAnimationEnd',
    MozAnimation: 'animationend',
    WebkitAnimation: 'webkitAnimationEnd',
  };

  for (t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
  return false;
}

function removeAnimationClass(el, css_classes) {
  const animationEvent = whichAnimationEvent();
  el.removeEventListener(animationEvent, removeAnimationClass);
  el.classList.remove(...css_classes);
}

// Animation classes can be seen in action here: https://daneden.github.io/animate.css/
const animationClasses = {
  general: 'zoomIn',
  removed_team_registrations: 'rollOut',
  new_team_registrations: 'bounceIn',
};

//
// shared helpers
//
export function animateWith(el, css_classes) {
  const animationEvent = whichAnimationEvent();
  el.classList.add(...css_classes);

  /* Note this is a bit of a nucelar option */
  $('.tooltip').remove();/* Make sure tooltip gets removed, otherwise it might leave artefacts see BAPP-10661 */
  el.addEventListener(animationEvent, () => {
    removeAnimationClass(el, css_classes);
  });
}

export function setupAnimations() {
  document.querySelectorAll('[data-animate-with]').forEach(
    (el) => {
      let css_classes = el.dataset.animateWith;
      if (css_classes !== null && css_classes !== undefined && css_classes !== '') {
        css_classes = css_classes.split(' ');
        animateWith(el, css_classes);
      }
    },
  );
}

export function animateEvent(el, eventName) {
  let css_classes = animationClasses[eventName];

  if (css_classes === undefined) {
    css_classes = animationClasses.general;
  }
  css_classes = `animated ${css_classes}`;
  animateWith(el, css_classes.split(' '));
}

export function reloadUX(selector) {
  // heavily based on existing code. TODO: get rid of jQuery
  reload_ux($(selector));
  reload_some_polyfills(selector);
  reload_interactive_events(selector);
  select_defaults($(selector));
  init_upload_inputs($(selector));

  $(selector).find('[autofocus]:first').focus();
  /* This used to be in reload UX but BAPP-5486 did not like this in some cases */
  $(selector).find('select[autofocus=autofocus]').each((index, object) => {
    $(object).select2('open');
  });
  $(selector).find('[data-beeple-autofocus]').focus();

  $(selector).find('[data-beeple-autosubmit="autosubmit"]').each((index, object) => {
    autosubmit($(object));
  });
}

// convenient function to filter unique velues in an array
// usage example:
// var a = [1,3,5,7,1,3];
// var unique = a.filter( distinct ) //returns [1,3,5,7]
export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}
