import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['requestedByType', 'requestedByAdmin', 'requestedByCollaboratorName'];

  connect() {
    this.setFilterMode(this.chosenRequestedByType());
  }

  onRequestedByTypeJqueryChange(_event) {
    this.setFilterMode(this.chosenRequestedByType());
  }

  setFilterMode(mode) {
    switch (mode) {
      case 'admin':
        this.isAdminFilterVisible(true);
        this.isCollaboratorFilterVisible(false);
        break;
      case 'collaborator':
        this.isAdminFilterVisible(false);
        this.isCollaboratorFilterVisible(true);
        break;
      default:
        this.isAdminFilterVisible(false);
        this.isCollaboratorFilterVisible(false);
        break;
    }
  }

  isAdminFilterVisible(flag) {
    if (this.hasRequestedByAdminTarget) {
      this.requestedByAdminTarget.closest('.form-group').hidden = !flag;
    }
  }

  isCollaboratorFilterVisible(flag) {
    this.requestedByCollaboratorNameTarget.closest('.form-group').hidden = !flag;
  }

  chosenRequestedByType() {
    const checkedFields = this.requestedByTypeTargets.filter((el) => el.checked);
    if (checkedFields.length == 0) {
      return null;
    }
    return checkedFields[0].value;
  }
}
