import { Controller } from 'stimulus';

// # The multiselector allows quick select
export default class extends Controller {
  static targets = ['input', '']

  checkInputs = (event) => {
    let any_checked = false;
    let any_unchecked = false;
    for (const target of this.inputTargets) {
      if (target.checked) {
        any_checked = true;
      } else {
        any_unchecked = true;
      }
    }

    if (this.element.dataset.multiselectorView) {
      for (const x of this.element.closest('.flexible').querySelectorAll(`[data-planning-schedule-multiselector="${this.element.dataset.multiselectorView}"]`)) {
        x.classList.toggle('hide', !any_checked);
      }
    }
  }

  toggleAll = (event) => {
    event.preventDefault();

    let set_checked = true;
    if (this.element.dataset.multiselectorCheckedAll == 'true') {
      set_checked = false;
      this.element.dataset.multiselectorCheckedAll = 'false';
    } else {
      this.element.dataset.multiselectorCheckedAll = 'true';
    }
    for (const target of this.inputTargets) {
      target.checked = set_checked;
    }

    if (this.element.dataset.multiselectorView) {
      if (this.inputTargets.length == 0) {
        set_checked = false;
      }

      for (const x of this.element.closest('.flexible').querySelectorAll(`[data-planning-schedule-multiselector="${this.element.dataset.multiselectorView}"]`)) {
        x.classList.toggle('hide', !set_checked);
      }
    }
  }
}
