import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['templateSelect', 'sortOrder', 'filterData', 'submitButton']

    connect() {
      this._initTemplateSelect();
    }

    _readFilterData = (event) => {
      const exportTemplateId = event.params.data.id;
      const payload = {};
      payload.template_id = exportTemplateId;

      const { calledFromView } = this.element.dataset;
      let fetchFilterDataUrl; let startInput; let
        endInput;

      switch (calledFromView) {
        case 'planning_schedule':
          fetchFilterDataUrl = this.element.dataset.fetchFilterDataPlanningScheduleUrl;
          startInput = document.querySelector('input[name="happenings_planning_schedule_view[period_starts_at]"]');
          endInput = document.querySelector('input[name="happenings_planning_schedule_view[period_ends_at]"]');
          payload.start = startInput.value;
          payload.end = endInput.value;
          payload.happening_id = this.element.dataset.fetchFilterDataPlanningScheduleHappeningId;
          payload.cluster_id = this.element.dataset.fetchFilterDataPlanningScheduleClusterId;
          break;

        case 'collaborator_schedule':
          fetchFilterDataUrl = this.element.dataset.fetchFilterDataCollaboratorScheduleUrl;
          startInput = document.querySelector('input#happenings_collaborators_schedule_view_period_starts_at');
          endInput = document.querySelector('input#happenings_collaborators_schedule_view_period_ends_at');
          payload.start = moment($(startInput).val(), 'DD/MM/YYYY').format('YYYY-MM-DD');
          payload.end = moment($(endInput).val(), 'DD/MM/YYYY').format('YYYY-MM-DD');
          break;

        default: {
          fetchFilterDataUrl = this.element.dataset.fetchFilterDataTeamsAndEnrolmentsUrl;

          const calendar = document.querySelector('.beeple-planning-calendar.fc');
          if (calendar == null) {
            console.error('planning-calendar not found.');
            return;
          }

          const current_view = $(calendar).fullCalendar('getView');
          payload.start = current_view.start.format('YYYY-MM-DD');
          payload.end = current_view.end.format('YYYY-MM-DD');
          payload.filters = calendar.dataset.filters;
          payload.parent_class = calendar.dataset.parentClass;
          payload.parent_id = calendar.dataset.parentId;
        }
      }

      const outside_this = this;
      this.submitButtonTarget.setAttribute('disabled', true);

      $.ajax({
        url: fetchFilterDataUrl,
        type: 'GET',
        data: payload,
        success(filterData) {
          const filterDataInput = document.querySelector('input#tenant_export_filter_data');
          const sortOrderInput = document.querySelector('input#tenant_export_sort_order');
          filterDataInput.value = JSON.stringify(filterData.filter_data);
          sortOrderInput.value = JSON.stringify(filterData.sort_order);
          outside_this.submitButtonTarget.removeAttribute('disabled');
        },
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error fetching filter data: ${textStatus}`, errorThrown);
          outside_this.submitButtonTarget.removeAttribute('disabled');
        },
      });
    }

    _initTemplateSelect() {
      function formatResult(node) {
        if (node.level < 3) return $(`<span style="color: grey; padding-left:${10 * node.level}px;">${node.text}</span>`);
        return $(`<span style="padding-left:${10 * node.level}px;">${node.text}</span>`);
      }

      let parsedData = this.templateSelectTarget.dataset.selectGroupedOptions;
      parsedData = JSON.parse(parsedData);
      $(this.templateSelectTarget).select2({
        data: parsedData,
        theme: 'bootstrap',
        minimumResultsForSearch: -1,
        width: '100%',
        formatSelection(item) {
          return item.text;
        },
        formatResult(item) {
          return item.text;
        },
        templateResult: formatResult,
      });

      $(this.templateSelectTarget).on('select2:selecting', (e) => {
        // allow only third level selection
        if (e.params.args.data.level < 3) e.preventDefault();
      });
      $(this.templateSelectTarget).on('select2:select', this._readFilterData);
    }
}
