import { Controller } from 'stimulus';

/** Then Panel toggle (V2) is a simple component to toggle panels.
 * In function it can be compared to Bootstrap Collapse plugin in the sense
 * that it will show/hide some other element when clicking a button
 *
 * Targets: None
 *
 * Handlers:
 *   - togglePanel (usually invoked with the "click" event):
 *                 opens or closes the associated panel (see data attribute toggle-panel-target)
 *
 * Data attributes: (unless mentioned otherwise always on component level)
 *  - toggle-panel-target: The CSS selector to determine which part of the screen to open/close.
 *                         Does not need to be a child of this component.
*/
export default class extends Controller {
  static targets = []

  togglePanel = (event) => {
    event.preventDefault();

    const toggle_panel_target_selector = this.element.dataset.panelToggleTarget;
    const toggle_panel_target = this.element.closest('body').querySelector(toggle_panel_target_selector);

    if (toggle_panel_target) {
      toggle_panel_target.classList.toggle('hide');
      reload_ux($(toggle_panel_target));
    } else {
      console.error('undefined toggle panel target', toggle_panel_target_selector);
    }
  }
}
