import { Controller } from 'stimulus';

// controller: admin--concerns--linkable-to-departments
export default class extends Controller {
  static targets = [
    'departmentsSelectionWrapper',
    'departmentSelectionCheckbox',
    'appliesForAllRadioButton',
  ]

  connect() {
    this.updateDepartmentsAssignment();
  }

  isAppliesToAllDepartments() {
    return this.appliesForAllRadioButtonTarget.checked;
  }

  updateDepartmentsAssignment() {
    const departmentsSelectionWrapper = this.departmentsSelectionWrapperTarget;
    const departmentSelectionCheckboxes = this.departmentSelectionCheckboxTargets;

    if (this.isAppliesToAllDepartments()) {
      departmentsSelectionWrapper.classList.add('hidden');
    } else if (departmentSelectionCheckboxes.length == 1) {
      departmentSelectionCheckboxes.forEach((el) => { el.checked = 'checked'; });
      departmentsSelectionWrapper.classList.add('hidden');
    } else {
      departmentsSelectionWrapper.classList.remove('hidden');
    }
  }
}
