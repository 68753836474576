import { Controller } from 'stimulus';
import { setSidebarContents, sidebarContents, removeFakes } from '../base/availabilities';

export default class extends Controller {
    static targets = ['heading', 'headingToggleButton', 'quickCreatePopup', 'dayPartsLegend'];

    dblClickCheck = null;

    connect() {
      if (this.hasDayPartsLegendTarget) {
        $(this.dayPartsLegendTarget)
          .popover({
            trigger: 'manual',
            container: 'body',
            placement: 'bottom',
          });
      }

      $(document).ajaxSuccess((_, xhr, settings) => {
        const filter_id = xhr.getResponseHeader('X-Beeple-StoredVolunteersFilterId');

        if (filter_id !== undefined && filter_id != null) {
          for (const el of document.querySelectorAll('[data-stored-volunteers-filter]')) {
            el.dataset.storedVolunteersFilter = filter_id;
          }
        }
      });
    }

    highlightEvents(event) {
      const contents = sidebarContents();
      if (contents.startsWith('form')) {
        // avoid highlight when form is open
        return;
      }

      const repetitionId = event.target.dataset.eventRepetitionId;

      if (repetitionId !== undefined && repetitionId !== '') {
        const sameIds = document.querySelectorAll(`#availabilities_calendar div[data-event-repetition-id='${repetitionId}']:not(.temporary)`);
        for (const div of sameIds) {
          div.classList.add('highlighted');
        }
        const others = document.querySelectorAll('#availabilities_calendar div[data-event-id]:not(.highlighted):not(.temporary)');
        for (const div of others) {
          div.classList.add('blurred');
        }
        return;
      }

      const id = event.target.dataset.eventId;
      if (id !== undefined) {
        const sameIds = document.querySelectorAll(`#availabilities_calendar div[data-event-id='${id}']:not(.temporary)`);
        for (const div of sameIds) {
          div.classList.add('highlighted');
        }
        const others = document.querySelectorAll('#availabilities_calendar div[data-event-id]:not(.highlighted):not(.temporary)');
        for (const div of others) {
          div.classList.add('blurred');
        }
      }
    }

    unHighlightEvents(_event) {
      const contents = sidebarContents();
      if (contents.startsWith('form')) {
        // avoid highlight when form is open
        return;
      }

      const others = document.querySelectorAll('#availabilities_calendar div[data-event-id]:not(.highlighted):not(.temporary)');
      for (const div of others) {
        div.classList.remove('blurred');
      }

      const sameIds = document.querySelectorAll('#availabilities_calendar div[data-event-id], #availabilities_calendar div[data-event-repetition-id]:not(.temporary)');
      for (const div of sameIds) {
        div.classList.remove('highlighted');
      }
    }

    // used when editing an availability
    availabilityPopup = (event) => {
      event.stopPropagation();

      let el = event.target.closest('.day-events:not(.locked)');
      if (el == null) {
        el = event.target.closest('.more-events');
      }
      if (el !== null) {
        let url;

        if (!event.target.dataset.editUrl) {
          url = event.target.closest('[data-edit-url]').dataset.editUrl;
        } else {
          url = event.target.dataset.editUrl;
        }
        if (url !== undefined) {
          if ((event.target.dataset.dryRun === undefined) || (event.target.dataset.dryRun != 'true')) {
            removeFakes();
            $.ajax(url);
          }
        }
      }
    }

    // to handle click and dblclick on the same element: the DOM triggers two click events
    // before triggering the dblclick. See: https://developer.mozilla.org/en-US/docs/Web/API/Element/dblclick_event
    // So, this method makes a "pause" after the first click to check and see if the user actually intended a dblclick
    // checking the event.detail property that has the number of clicks (see https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event)
    quickPopup = (event) => {
      // FOR IE 11 event.detail is always 0
      // For all other browsers we only handle when detail = 1 (the first click in a - potential - double click)
      if (event.detail === 1 || event.detail === 0) {
        // FOR IE 11 we might track the double click as well, so we have to
        // clear the previous timeout before overwriting it.
        clearTimeout(this.dblClickCheck);
        this.dblClickCheck = setTimeout(() => {
          // Single click

          const el = event.target.closest('.day-events:not(.locked)');
          if (el !== null) {
            const { quickCreateUrl } = el.dataset;
            if (quickCreateUrl === undefined || quickCreateUrl === '') {
              return;
            }
          }

          const controller = this.application.getControllerForElementAndIdentifier(this.quickCreatePopupTarget, 'availabilities-quick-create');
          controller.openPopup(event);
        }, 300);
      }
    }

    // Double click listener
    newAvialabilityFormInSidebar = (event) => {
      clearTimeout(this.dblClickCheck);
      this.newAvailabilityPopup(event);
    }

    // used when creating a new availability
    newAvailabilityPopup = (event) => {
      event.stopPropagation();
      removeFakes();
      const el = event.target.closest('.day-events:not(.locked)');
      if (el !== null) {
        const { date } = el.dataset;
        const url = document.querySelector('#availabilities_calendar').dataset.newUrl;

        if (url !== undefined) {
          $.ajax(url, { data: { date } });
        }
      }
    }

    showDetails(event) {
      event.stopPropagation();
      const url = document.querySelector('#availabilities_calendar').dataset.detailsUrl;
      if (url !== undefined) {
        window.location = url;
      }
    }

    toggleYesNo(event) {
      event.stopPropagation();
      const url = event.target.dataset.toggleUrl;
      if (url !== undefined && url !== '') {
        $.ajax({ url, type: 'POST', dataType: 'script' });
      }
    }

    showPreviousMonth = (event) => {
      event.preventDefault();

      const el = this.headingTarget;
      const { previousMonth } = el.dataset;
      const url = el.dataset.updateCalendarUrl;
      const data = {
        volunteers_availabilities_view: {
          start_date: previousMonth,
        },
      };
      this._post(url, data);
    }

    showNextMonth = (event) => {
      event.preventDefault();

      const el = this.headingTarget;
      const { nextMonth } = el.dataset;
      const url = el.dataset.updateCalendarUrl;
      const data = {
        volunteers_availabilities_view: {
          start_date: nextMonth,
        },
      };
      this._post(url, data);
    }

    _post(url, data) {
      $.ajax({
        url,
        type: ('POST'),
        data,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.warn(`Error posting: ${textStatus}`, errorThrown);
        },
      });
    }

    newAvailability = (event) => {
      event.stopPropagation();

      removeFakes();
      const el = this.headingTarget;
      const { date } = el.dataset;
      const url = el.dataset.newUrl;

      if (url !== undefined) {
        $.ajax(url, { data: { date } });
      }
    }

    openModal(event) {
      event.stopPropagation();
      let btn; let calendarDate;
      // click on icon or button will get correct element
      if (event.target.dataset.url !== undefined) {
        btn = event.target;
      } else {
        btn = event.target.closest('[data-url]');
      }

      calendarDate = '';

      if ($('#availability_overview-calendar').length) {
        calendarDate = $('#availability_overview-calendar').fullCalendar('getDate').format();
      }

      if ($("[data-target='availabilities-calendar.heading']").data('date')) {
        calendarDate = $("[data-target='availabilities-calendar.heading']").data('date');
      }

      $.ajax({
        url: btn.dataset.url,
        data: {
          stored_volunteers_filter: btn.dataset.storedVolunteersFilter,
          stored_availabilities_filter: btn.dataset.storedAvailabilitiesFilter,
          sort_order: btn.dataset.sortOrder,
          view_date: calendarDate,
        },
        type: 'GET',
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.warn(`Error calling export modal: ${textStatus}`, errorThrown);
        },
      });
    }

    toggleSidebar = (event) => {
      event.stopPropagation();
      let btn;
      if (event.target.dataset.contents !== undefined) {
        btn = event.target;
      } else {
        btn = event.target.closest('[data-contents]');
      }
      const { url, contents } = btn.dataset;

      if (contents !== undefined) {
        if (sidebarContents() === contents) {
          setSidebarContents(null);
          this._toggleSidebar(false);
          btn.classList.remove('btn-primary');
          btn.classList.add('btn-default');
        } else {
          setSidebarContents(contents);
          const { date } = this.headingTarget.dataset;

          if (url !== undefined) {
            $.ajax(url, {
              data: {
                volunteers_availabilities_view: {
                  start_date: date,
                  sidebar_contents: contents,
                },
              },
            });
          }
        }
      }
    }

    closeSidebar = (event) => {
      event.stopPropagation();
      this._toggleSidebar(false);
    }

    _toggleSidebar(show) {
      const sidebar = document.querySelector('.sidebar');
      if (sidebar !== undefined) {
        if (show) {
          sidebar.classList.remove('hide');
        } else {
          sidebar.classList.add('hide');
          this._disableToggleButtons();
          setSidebarContents(null);
        }
      }
    }

    moreEventsPopup = (event) => {
      event.stopPropagation();
      const el = event.target.closest('.event-container');
      const { url } = el.dataset;
      if (url !== undefined) {
        removeFakes();
        const date = el.dataset.eventsDate;
        $.ajax(url, { data: { start_date: date } });
      }
    };

    _disableToggleButtons = () => {
      this.headingToggleButtonTargets.forEach((btn) => {
        btn.classList.remove('btn-primary');
        btn.classList.add('btn-default');
        btn.blur();
      });
    }

  openLegend = (event) => {
    event.preventDefault();
    event.stopPropagation();
    $(this.dayPartsLegendTarget)
      .popover('toggle');
    this.dayPartsLegendTarget.classList.toggle('btn-primary');
    this.dayPartsLegendTarget.classList.toggle('btn-default');
    this.dayPartsLegendTarget.blur();
  };
}
