import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['fType', 'fKeysCount', 'fHiddenKeysCount'];

    connect() {
      this.updateCounters(0);
    }

    hasValue(object) {
      return form_group_cotains_selected_value($(object).find('.form-group-collapsable'));
    }

    triggerAutoSubmit() {
      let calendar = $('div.beeple-planning-calendar');
      if (calendar.length === 0) {
        // Then, we should be using a filter on public view
        calendar = $('#teams-public-calendar, #team-registrations-calendar');
      }

      if (calendar.length > 0) {
        calendar.fullCalendar('refetchEvents');
      } else {
        autosubmit_update($(this.element.closest('form')));
      }
    }

    storeFilterSettings(data) {
      // do a $.ajax(type: 'PATCH') to /filters
      // to persist the Volunteers::StoredFilterSettings
      const payload = data;
      payload.f_type = this.element.dataset.beepleFilterType;
      payload.f_variant = this.element.dataset.beepleFilterVariant;

      $.ajax({
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        url: '/filters',
        type: 'PATCH',
        data: JSON.stringify(payload),
        success(_response, _textStatus, _jqXhr) {
          // noop;
        },
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error saving filter settings: ${textStatus}`, errorThrown);
        },
      });
    }

    updateCounters() {
      const keysCount = $('div.enabled-filters div[data-beeple-filter-key-id-visible]').length;
      const disabledKeysCount = keysCount - $('div.disabled-filters div[data-beeple-filter-key-id-invisible].hide-filter').length;

      if (disabledKeysCount >= keysCount) {
        // no filters shown: hide titles
        $(this.element).find('div.filter-reset-title').addClass('hide-filter');
      } else {
        $(this.element).find('div.filter-reset-title').removeClass('hide-filter');
      }

      if (disabledKeysCount <= 0) {
        // no filters hidden: hide disabled section
        $(this.element).find('div.disabled-filters').addClass('hide-filter');
      } else {
        $(this.element).find('div.disabled-filters').removeClass('hide-filter');
      }
    }

    disableFilterKey(event) {
      event.stopPropagation();
      event.preventDefault();

      const enclosingDiv = event.target.closest('div[data-beeple-filter-key-id-visible]');
      const keyId = enclosingDiv.dataset.beepleFilterKeyIdVisible;
      enclosingDiv.classList.add('hide-filter');

      const enclosingDivForHidden = $(this.element).find(`div[data-beeple-filter-key-id-invisible="${keyId}"]`);
      enclosingDivForHidden.removeClass('hide-filter');

      this.updateCounters();

      const data = {};
      data[keyId] = true;
      this.storeFilterSettings(data);

      // collapse the to-be-hidden filter-key so that when reactivating it shows up collapsed
      $(enclosingDiv).find('div.form-group.form-group-collapsable').addClass('collapsed');

      // lastly, trigger an autosubmit on the filter form (or a calendar.fullCalendar('refetchEvents')
      // if the disabled filter key hasValue()
      if (this.hasValue(enclosingDiv)) {
        // trigger a filter key reset (should automatically do an autosubmit)
        $(enclosingDiv).find('[data-beeple-toggle="reset"]').click();
        // this.triggerAutoSubmit();
      }
    }

    enableFilterKey(event) {
      event.stopPropagation();
      event.preventDefault();

      const enclosingDiv = event.target.closest('div[data-beeple-filter-key-id-invisible]');
      const keyId = enclosingDiv.dataset.beepleFilterKeyIdInvisible;
      enclosingDiv.classList.add('hide-filter');

      const enclosingDivForVisible = $(this.element).find(`div[data-beeple-filter-key-id-visible="${keyId}"]`);
      enclosingDivForVisible.removeClass('hide-filter');
      init_select(enclosingDivForVisible);

      this.updateCounters();

      const data = {};
      data[keyId] = false;
      this.storeFilterSettings(data);
    }
}
