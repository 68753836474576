import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { onLoadOpenUrl } = this.element.dataset;

    if (onLoadOpenUrl) {
      $.ajax({
        url: onLoadOpenUrl,
        type: ('GET'),
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error retrieving ${onLoadOpenUrl} after loading page. ${textStatus}`, errorThrown);
        },
      });
    }
  }
}
