import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['pageNumber']

  switchPage(event) {
    event.preventDefault();
    const new_page = event.target.dataset.dashboardWidgetPage;
    this.setPageNumber(new_page);

    const controller = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    controller.executeRequest();

    return false;
  }

  refreshSamePage() {
    const controller = this.application.getControllerForElementAndIdentifier(this.element, 'form-refresh');
    controller.executeRequest();

    return false;
  }

  setPageNumber(new_page) {
    this.pageNumberTarget.setAttribute('value', new_page);
  }
}
