import { Controller } from 'stimulus';

export default class extends Controller {
    // used for the export button in fullCalendars
    openExportModal = (event) => {
      event.preventDefault();

      const { fetchFilterDataUrl } = this.element.dataset;
      const { exportModalUrl } = this.element.dataset;
      const { parentClass } = this.element.dataset;
      const { parentId } = this.element.dataset;

      const calendar = document.querySelector('.beeple-planning-calendar.fc');
      if (calendar == null) {
        console.error('planning-calendar not found.');
        return;
      }

      const current_view = $(calendar).fullCalendar('getView');
      const start = current_view.start.format('YYYY-MM-DD');
      const end = current_view.end.format('YYYY-MM-DD');
      const { filters } = calendar.dataset;

      $.ajax({
        url: fetchFilterDataUrl,
        type: 'GET',
        data: {
          parent_class: parentClass, parent_id: parentId, start, end, filters,
        },
        success(filterData) {
          $.ajax({
            url: exportModalUrl,
            data: filterData,
            type: 'POST',
            error(_jqXHR, textStatus, errorThrown) {
              // log the error to the console
              console.error(`Error calling export modal: ${textStatus}`, errorThrown);
            },
          });
        },
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error fetching filter data: ${textStatus}`, errorThrown);
        },
      });
    };

    // used for the export button that opens multi-type exports
    // mainly in planning boards and teams-and-enrolments
    openMultiTypeExportModal = (event) => {
      event.preventDefault();

      const { exportModalUrl } = this.element.dataset;

      $.ajax({
        url: exportModalUrl,
        data: {},
        type: 'GET',
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error calling export modal: ${textStatus}`, errorThrown);
        },
      });
    }
}
