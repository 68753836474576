import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['input'];

    currentValueInMinutes = undefined;

    minuteStep = 1;

    connect() {
      if (!this.hasInputTarget) {
        return;
      }
      this.recalculateValue();
      this.minuteStep = parseInt(document.querySelector("meta[name='beeple-mininal-planning-unit']").content);
    }

    toggleDisable(disabled) {
      this.inputTarget.disabled = disabled;
      this.inputTarget.readonly = disabled;
    }

    recalculateValue() {
      if (this.inputTarget.value == '') {
        return;
      }
      const splitted_values = this.inputTarget.value.split(':');
      this.setDurationInMinutes(parseInt(splitted_values[0]) * 60 + parseInt(splitted_values[1]));
    }

    updateValue(event) {
      if (event.key == 'Tab') {
        if (this._isHourSelected(this.inputTarget.selectionStart)) {
          this._selectMinuteRange();
          event.preventDefault();
        }
      }

      if (event.key != 'ArrowUp' && event.key != 'ArrowDown') {
        return;
      }

      if ('selectionStart' in this.inputTarget) {
        const caret_position = this.inputTarget.selectionStart;
        const is_hour_selected = this._isHourSelected(caret_position);
        if (is_hour_selected) {
          if (event.key == 'ArrowUp') {
            this.setDurationInMinutes(this.currentValueInMinutes + 60);
          } else {
            this.setDurationInMinutes(this.currentValueInMinutes - 60);
          }
        } else if (event.key == 'ArrowUp') {
          this.setDurationInMinutes(this.currentValueInMinutes + this.minuteStep);
        } else {
          this.setDurationInMinutes(this.currentValueInMinutes - this.minuteStep);
        }
        $(this.inputTarget).change();

        if (is_hour_selected) {
          this._selectHourRange();
        } else {
          this._selectMinuteRange();
        }
      }
    }

    increaseValue(event) {
      if (this._isHourSelected(this.inputTargetSelectionStart)) {
        this.setDurationInMinutes(this.currentValueInMinutes + 60);
      } else {
        this.setDurationInMinutes(this.currentValueInMinutes + this.minuteStep);
      }
      $(this.inputTarget).change();

      this._resetInputTargetSelection();
    }

    decreaseValue(event) {
      if (this._isHourSelected(this.inputTargetSelectionStart)) {
        this.setDurationInMinutes(this.currentValueInMinutes - 60);
      } else {
        this.setDurationInMinutes(this.currentValueInMinutes - this.minuteStep);
      }
      $(this.inputTarget).change();

      this._resetInputTargetSelection();
    }

    rememberSelection(event) {
      this.inputTargetSelectionStart = this.inputTarget.selectionStart;
    }

    _resetInputTargetSelection() {
      this.inputTarget.focus();
      if (this._isHourSelected(this.inputTargetSelectionStart)) {
        this._selectHourRange();
      } else {
        this._selectMinuteRange();
      }
    }

    _isHourSelected(caret_position) {
      const middle_position = this.inputTarget.value.indexOf(':');
      return caret_position <= middle_position;
    }

    _selectHourRange() {
      const middle_position = this.inputTarget.value.indexOf(':');
      this.inputTarget.setSelectionRange(0, middle_position);
    }

    _selectMinuteRange() {
      const middle_position = this.inputTarget.value.indexOf(':');
      this.inputTarget.setSelectionRange(middle_position + 1, this.inputTarget.value.length);
    }

    setDurationInMinutes(minutes) {
      if (minutes < 0) {
        minutes = 0;
      }
      const hours = Math.floor(minutes / 60);
      const minutes_left = minutes % 60;
      this.currentValueInMinutes = minutes;

      let hours_as_string = hours.toString();
      if (hours_as_string.length <= 1) {
        hours_as_string = `0${hours_as_string}`;
      }

      let minutes_left_as_string = minutes_left.toString();
      if (minutes_left_as_string.length <= 1) {
        minutes_left_as_string = `0${minutes_left_as_string}`;
      }
      const new_value = `${hours_as_string}:${minutes_left_as_string}`;
      if (new_value != this.inputTarget.value) {
        this.inputTarget.value = new_value;
      }
    }

    getDurationInMinutes() {
      if (this.inputTarget.dataset.html5Type == 'time') {
        const current_value = moment($(this.inputTarget).datetimepicker('getDate'));
        if (current_value.isValid()) {
          return current_value.hours() * 60 + current_value.minutes();
        }
        this.setDurationInMinutes(this.element.dataset.defaultLengthInMinutes || 0);
        return this.currentValueInMinutes;
      }
      if (this.currentValueInMinutes == undefined) {
        this.setDurationInMinutes(this.element.dataset.defaultLengthInMinutes || 0);
      }

      return this.currentValueInMinutes;
    }
}
