import { Controller } from 'stimulus';

// controller: admin--tenant--settings
export default class extends Controller {
  static targets = [
    'warningWillDisableCustomerPortal',
  ]

  connect() {
    const usesClientManagementToggle = document.querySelector(
      'input[type=\'checkbox\'][name=\'tenant[uses_client_management]\']',
    );
    // since the tenant[uses_client_management] checkbox is a boostrap toggle, it doesn't subscribe to the native addEventListener
    // so we need to declare the avent as jquery .on() :(
    $(usesClientManagementToggle)
      .on('change', this.checkIfHaveToDisableCustomerPortal);
  }

  checkIfHaveToDisableCustomerPortal = (event) => {
    if (event.target.checked === false) {
      this.warningWillDisableCustomerPortalTarget.classList.remove('hide');
    } else {
      this.warningWillDisableCustomerPortalTarget.classList.add('hide');
    }
  }
}
