import { Controller } from 'stimulus';

/** Schedule View Side Panel is a component used for managing the side panel
 * of a plannings schedule
 *
 * Targets:
 *   - mainTabs
 *   - secondaryTabs
 *   - tabContents
 +   - loadingContent
 +   - tabBadges
 *
 * Handlers:
 *   - switchToMainTab
 *   - createSecondaryTab
 *   - panelFeedback
 *
 * Data attributes: (unless mentioned otherwise always on component level)
 *  - TBD
*/
export default class extends Controller {
  static targets = ['mainTabs', 'secondaryTabs', 'tabContents', 'loadingContent', 'tabBadge',
    'secondaryTabEnrolmentErrorsBadge', 'secondaryTabCandidacyErrorsBadge']

  connect() {
    super.connect();
    this.witdh_of_secondary_tab = 112;

    if (this.element.dataset.onLoadOpen) {
      this._onLoadOpenTab(this.element.dataset.onLoadOpen, this.element.dataset.onLoadOpenUrl);
    }
  }

  panelFeedback = (event) => {
    const target = event.target.closest('[data-schedule-view-side-panel-main-tab-id]');
    const response_data = event.detail.data;

    if (target == null) {
      return;
    }

    const main_tab_id = target.dataset.scheduleViewSidePanelMainTabId;
    const secondary_tab_id = target.dataset.scheduleViewSidePanelSecondaryTabId;

    let main_controller = this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller~="planning-schedule"]'), 'planning-schedule');
    main_controller = main_controller || this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller~="collaborators-schedule"]'), 'collaborators-schedule');
    if (main_controller) {
      main_controller.actionFeedback(response_data);
    }

    if (response_data.panel == undefined) {
      if (!response_data.keep_panel_open) {
        if ((main_tab_id == 'bulk-edit') && (main_controller)) {
          main_controller.finishBulkEdit();
        }
        this._closeSecondaryTab(main_tab_id, secondary_tab_id);
      }
    } else {
      let current_panel = null;
      if (main_tab_id == 'planning' || main_tab_id == 'bulk-edit' || main_tab_id == 'bulk-edit-results' || main_tab_id == 'bulk-delete' || main_tab_id == 'bulk-delete-results'
        || main_tab_id == 'bulk-copy' || main_tab_id == 'bulk-copy-results') {
        current_panel = this.tabContentsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"]`);
      } else {
        current_panel = this.tabContentsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"][data-schedule-view-side-panel-secondary-tab-id="${secondary_tab_id}"]`);
      }
      current_panel.innerHTML = response_data.panel;
      apply_collapsable_filters($(current_panel));

      this._reloadPanelContent(current_panel);
    }
  }

  switchToMainTab = (event) => {
    event.preventDefault();

    const target = event.target.closest('[data-schedule-view-side-panel-main-tab-id]');
    this._switchToMainTab(target.dataset.scheduleViewSidePanelMainTabId, null);
  }

  switchToSecondaryTab = (event) => {
    event.preventDefault();
    const target = event.target.closest('[data-schedule-view-side-panel-secondary-tab-id]');
    const main_target = event.target.closest('[data-schedule-view-side-panel-main-tab-id]');
    this._switchToMainTab(main_target.dataset.scheduleViewSidePanelMainTabId,
      target.dataset.scheduleViewSidePanelSecondaryTabId,
      target.dataset.scheduleViewSidePanelTeamId,
      target.dataset.scheduleViewSidePanelVolunteerId,
      target.dataset.scheduleViewSidePanelTeamRegistrationId,
      target.dataset.scheduleViewSidePanelTeamApplicationId);
  }

  closeSecondaryTab = (event) => {
    event.preventDefault();
    const target = event.target.closest('[data-schedule-view-side-panel-secondary-tab-id]');
    const main_target = event.target.closest('[data-schedule-view-side-panel-main-tab-id]');

    if (target !== null) {
      this._closeSecondaryTab(main_target.dataset.scheduleViewSidePanelMainTabId, target.dataset.scheduleViewSidePanelSecondaryTabId);
    } else {
      this._closeSecondaryTab(main_target.dataset.scheduleViewSidePanelMainTabId, '');
    }
  }

  closeSecondaryTabById = (tabMainId, tabSecondaryId) => {
    this._closeSecondaryTab(tabMainId, tabSecondaryId);
  }

  browseLeftInSecondaryTabs = (event) => {
    event.preventDefault();
    const actual_tab_list = event.target.closest('[data-schedule-view-side-panel-main-tab-id]').querySelector('.actual-tabs');
    const current_value = parseInt(actual_tab_list.style.left);
    let new_value = current_value + (this.witdh_of_secondary_tab * 2);
    if (new_value > 0) {
      new_value = 0;
    }

    actual_tab_list.style.left = `${new_value.toString()}px`;
  }

  browseRightInSecondaryTabs = (event) => {
    event.preventDefault();
    const actual_tab_list = event.target.closest('[data-schedule-view-side-panel-main-tab-id]').querySelector('.actual-tabs');
    const current_value = parseInt(actual_tab_list.style.left);

    let new_value = current_value - (this.witdh_of_secondary_tab * 2);
    const number_of_tabs = actual_tab_list.querySelectorAll('li').length;
    const min_value = -((Math.floor((number_of_tabs - 2) / 2) + (number_of_tabs % 2)) * this.witdh_of_secondary_tab * 2);
    if (new_value < min_value) {
      new_value = min_value;
    }
    actual_tab_list.style.left = `${new_value.toString()}px`;
  }

  createSecondaryTab = (main_tab_id, secondary_tab_id, secondary_tab_title, content_url, is_post = false, extra_data = {}, team_id = null, volunteer_id = null, team_registration_id = null, team_application_id = null, switchTo = null) => {
    let secondary_tab_div = document.createElement('DIV');
    const open_panel_callback = (data) => {
      let main_controller = this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller~="planning-schedule"]'), 'planning-schedule');
      main_controller = main_controller || this.application.getControllerForElementAndIdentifier(this.element.closest('[data-controller~="collaborators-schedule"]'), 'collaborators-schedule');
      if (main_controller) {
        main_controller.actionFeedback(data);
      }

      if (data.panel == null) {
        if (!data.keep_panel_open) {
          this._closeSecondaryTab(main_tab_id, secondary_tab_id);
        }
        return;
      }
      secondary_tab_div.innerHTML = data.panel;
      const hasErrors = secondary_tab_div.querySelector(".fixed-content[data-has-error='true']");
      this._toggleSecondaryTabErrorsBadge(main_tab_id, secondary_tab_id, (hasErrors !== null));
      this._reloadPanelContent(secondary_tab_div);
      if (switchTo) {
        this._switchToMainTab(main_tab_id,
          secondary_tab_id);
      }
      if (data.reload_special_compensations) {
        /* global beeple_reload_special_compensations */

        beeple_reload_special_compensations();
      }
    };

    if (main_tab_id == 'planning' || main_tab_id == 'bulk-edit' || main_tab_id == 'bulk-edit-results' || main_tab_id == 'bulk-delete' || main_tab_id == 'bulk-delete-results'
      || main_tab_id == 'bulk-copy' || main_tab_id == 'bulk-copy-results') {
      const current_tab_page = this.tabContentsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"]`);
      if (current_tab_page != null) {
        current_tab_page.remove();
      }
      secondary_tab_div.classList.toggle('tab-content', true);
      secondary_tab_div.setAttribute('data-schedule-view-side-panel-main-tab-id', main_tab_id);
      secondary_tab_div.classList.toggle('hide', true);

      this.tabContentsTarget.appendChild(secondary_tab_div);
      if (is_post) {
        $.post({
          url: content_url,
          success: open_panel_callback,
          data: extra_data,
        });
      } else {
        $.get({
          url: content_url,
          success: open_panel_callback,
          dataType: 'json',
        });
      }

      this._switchToMainTab(main_tab_id, secondary_tab_id, team_id, volunteer_id, team_registration_id, team_application_id);
      return null;
    }

    const secondary_tab_list = this.secondaryTabsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"]`);
    const actual_tab_list = secondary_tab_list.querySelector('.actual-tabs');

    if (secondary_tab_list.querySelector(`[data-schedule-view-side-panel-secondary-tab-id="${secondary_tab_id}"]`) == null) {
      const tab_node = document.createElement('LI');
      tab_node.classList.toggle('selected', true);
      tab_node.setAttribute('data-schedule-view-side-panel-secondary-tab-id', secondary_tab_id);
      tab_node.setAttribute('data-schedule-view-side-panel-team-id', team_id);
      tab_node.setAttribute('data-schedule-view-side-panel-volunteer-id', volunteer_id);
      tab_node.setAttribute('data-schedule-view-side-panel-team-registration-id', team_registration_id);
      tab_node.setAttribute('data-schedule-view-side-panel-team-application-id', team_application_id);

      const a_node = document.createElement('A');
      a_node.dataset.action = 'click->schedule-view-side-panel#switchToSecondaryTab';
      a_node.innerHTML = secondary_tab_title;
      a_node.dataset.toggle = 'tooltip';
      a_node.dataset.container = 'body';
      a_node.title = secondary_tab_title;
      tab_node.appendChild(a_node);
      $(a_node).tooltip({ trigger: 'hover', html: true });

      const close_tab_node = document.createElement('A');
      close_tab_node.dataset.action = 'click->schedule-view-side-panel#closeSecondaryTab';
      close_tab_node.classList.toggle('mdi', true);
      close_tab_node.classList.toggle('mdi-close-circle', true);
      tab_node.appendChild(close_tab_node);

      let errorsBadge;
      if (this.hasSecondaryTabEnrolmentErrorsBadgeTarget) {
        if (team_registration_id != null) errorsBadge = this.secondaryTabEnrolmentErrorsBadgeTarget.cloneNode(true);
        else if (team_application_id != null) errorsBadge = this.secondaryTabCandidacyErrorsBadgeTarget.cloneNode(true);
        if (errorsBadge) {
          tab_node.appendChild(errorsBadge);
          $(errorsBadge).tooltip({ trigger: 'hover' });
        }
      }

      actual_tab_list.appendChild(tab_node);
      this._updateBadge(actual_tab_list);

      secondary_tab_div.classList.toggle('tab-content', true);
      secondary_tab_div.setAttribute('data-schedule-view-side-panel-main-tab-id', main_tab_id);
      secondary_tab_div.setAttribute('data-schedule-view-side-panel-secondary-tab-id', secondary_tab_id);
      secondary_tab_div.classList.toggle('hide', true);

      this.tabContentsTarget.appendChild(secondary_tab_div);

      if (content_url != null) {
        if (is_post) {
          $.post({
            url: content_url,
            success: open_panel_callback,
            data: extra_data,
          });
        } else {
          $.get({
            url: content_url,
            success: open_panel_callback,
            data: extra_data,
          });
        }
      }
    } else {
      secondary_tab_div = this.tabContentsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"][data-schedule-view-side-panel-secondary-tab-id="${secondary_tab_id}"]`);
    }

    this._switchToMainTab(main_tab_id, secondary_tab_id, team_id, volunteer_id, team_registration_id, team_application_id);
    return secondary_tab_div;
  }

  _reloadPanelContent(panel_content) {
    reload_ux($(panel_content));
    reload_some_polyfills(panel_content);
    reload_interactive_events(panel_content);
    apply_collapsable_filters($(panel_content));

    $(panel_content).find('input.rating').rating();
  }

  _closeSecondaryTab(main_tab_id, secondary_tab_id) {
    let sibling_tab_to_open = null;
    let current_tab_was_selected = false;
    for (const nav of this.secondaryTabsTarget.querySelectorAll('[data-schedule-view-side-panel-main-tab-id]')) {
      if (nav.dataset.scheduleViewSidePanelMainTabId != main_tab_id) {
        nav.classList.toggle('hide', true);
      } else {
        nav.classList.toggle('hide', false);

        for (const tab_item of nav.querySelectorAll('[data-schedule-view-side-panel-secondary-tab-id]')) {
          if (tab_item.dataset.scheduleViewSidePanelSecondaryTabId == secondary_tab_id) {
            sibling_tab_to_open = tab_item.previousElementSibling;
            if (sibling_tab_to_open == null) {
              sibling_tab_to_open = tab_item.nextElementSibling;
            }

            const actual_tab_list = tab_item.closest('.actual-tabs');
            current_tab_was_selected = tab_item.classList.contains('selected');
            tab_item.remove();

            const number_of_tabs = actual_tab_list.querySelectorAll('li').length;
            const min_value = -((Math.floor((number_of_tabs - 2) / 2) + (number_of_tabs % 2)) * this.witdh_of_secondary_tab * 2);
            const current_value = parseInt(actual_tab_list.style.left);
            if (current_value < min_value) {
              actual_tab_list.style.left = `${min_value.toString()}px`;
            }
            this._updateBadge(actual_tab_list);
          }
        }
      }
    }

    for (const tab_content of this.tabContentsTarget.querySelectorAll('[data-schedule-view-side-panel-secondary-tab-id]')) {
      if (tab_content.dataset.scheduleViewSidePanelMainTabId == main_tab_id
          && tab_content.dataset.scheduleViewSidePanelSecondaryTabId == secondary_tab_id) {
        tab_content.remove();
      }
    }

    if (sibling_tab_to_open && current_tab_was_selected) {
      this._switchToMainTab(main_tab_id, sibling_tab_to_open.dataset.scheduleViewSidePanelSecondaryTabId);
    } else if (sibling_tab_to_open == null) {
      const tab_page = this.mainTabsTarget.querySelector(`[data-schedule-view-side-panel-main-tab-id="${main_tab_id}"]`);
      tab_page.classList.toggle('hide', true);

      let other_sibling = tab_page.previousElementSibling;
      while (other_sibling != null) {
        if (!other_sibling.classList.contains('hide')) {
          break;
        }
        other_sibling = other_sibling.previousElementSibling;
      }

      if (other_sibling != null) {
        while (other_sibling != null) {
          if (!other_sibling.classList.contains('hide')) {
            break;
          }
          other_sibling = other_sibling.nextElementSibling;
        }
      }

      if (other_sibling != null) {
        this._switchToMainTab(other_sibling.dataset.scheduleViewSidePanelMainTabId);
      } else {
        const planning_schedule_view = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller~="planning-schedule"]'), 'planning-schedule');
        if (planning_schedule_view) {
          planning_schedule_view.selectTeamById(null);
        }
        this.element.classList.toggle('hide', true);
      }
    }
  }

  _toggleSecondaryTabErrorsBadge(main_tab_id, secondary_tab_id, showBadge) {
    for (const nav of this.secondaryTabsTarget.querySelectorAll('[data-schedule-view-side-panel-main-tab-id]')) {
      if (nav.dataset.scheduleViewSidePanelMainTabId === main_tab_id) {
        for (const tab_item of nav.querySelectorAll('[data-schedule-view-side-panel-secondary-tab-id]')) {
          if (tab_item.dataset.scheduleViewSidePanelSecondaryTabId === secondary_tab_id) {
            const badge = tab_item.querySelector('span.badge.has-errors');
            if (badge == undefined) {
              break;
            }
            if (showBadge) {
              badge.classList.remove('hide');
            } else {
              badge.classList.add('hide');
            }
            break;
          }
        }
      }
    }
  }

  _switchToMainTab(main_tab_id, secondary_tab_id, team_id = null, volunteer_id = null, team_registration_id = null, team_application_id = null) {
    this.element.classList.toggle('hide', false);

    for (const nav of this.mainTabsTarget.querySelectorAll('[data-schedule-view-side-panel-main-tab-id]')) {
      if (nav.dataset.scheduleViewSidePanelMainTabId == main_tab_id) {
        nav.classList.toggle('hide', false);
        nav.classList.toggle('selected', true);

        if (main_tab_id == 'planning') {
          if (team_id) {
            nav.dataset.scheduleViewSidepanelTeamId = team_id;
          } else {
            team_id = nav.dataset.scheduleViewSidepanelTeamId;
          }

          if (volunteer_id) {
            nav.dataset.scheduleViewSidepanelVolunteerId = volunteer_id;
          } else {
            volunteer_id = nav.dataset.scheduleViewSidepanelVolunteerId;
          }
        }
      } else {
        nav.classList.toggle('selected', false);
      }
    }

    for (const nav of this.secondaryTabsTarget.querySelectorAll('[data-schedule-view-side-panel-main-tab-id]')) {
      if (nav.dataset.scheduleViewSidePanelMainTabId != main_tab_id) {
        nav.classList.toggle('hide', true);
      } else {
        nav.classList.toggle('hide', false);
        if (secondary_tab_id == null) {
          secondary_tab_id = nav.dataset.scheduleViewSidePanelSecondaryTabIdCurrentlySelected;
        } else {
          nav.dataset.scheduleViewSidePanelSecondaryTabIdCurrentlySelected = secondary_tab_id;
        }

        let child_index = 1;
        const actual_tab_list = nav.querySelector('.actual-tabs');
        for (const tab_item of nav.querySelectorAll('[data-schedule-view-side-panel-secondary-tab-id]')) {
          if (tab_item.dataset.scheduleViewSidePanelSecondaryTabId == secondary_tab_id) {
            tab_item.classList.toggle('selected', true);
            team_id = tab_item.dataset.scheduleViewSidePanelTeamId;
            volunteer_id = tab_item.dataset.scheduleViewSidePanelVolunteerId;
            team_registration_id = tab_item.dataset.scheduleViewSidePanelTeamRegistrationId;
            team_application_id = tab_item.dataset.scheduleViewSidePanelTeamApplicationId;
            actual_tab_list.style.left = `${-((Math.floor((child_index - 2) / 2) + (child_index % 2)) * this.witdh_of_secondary_tab * 2).toString()}px`;
          } else {
            tab_item.classList.toggle('selected', false);
          }
          child_index += 1;
        }
      }
    }

    for (const tab_content of this.tabContentsTarget.querySelectorAll('[data-schedule-view-side-panel-main-tab-id]')) {
      let visible = false;
      if (main_tab_id == 'planning' || main_tab_id == 'bulk-edit' || main_tab_id == 'bulk-edit-results' || main_tab_id == 'bulk-delete' || main_tab_id == 'bulk-delete-results'
        || main_tab_id == 'bulk-copy' || main_tab_id == 'bulk-copy-results') {
        visible = tab_content.dataset.scheduleViewSidePanelMainTabId == main_tab_id;
      } else if (secondary_tab_id != null) {
        visible = tab_content.dataset.scheduleViewSidePanelMainTabId == main_tab_id
                    && tab_content.dataset.scheduleViewSidePanelSecondaryTabId == secondary_tab_id;
      }
      tab_content.classList.toggle('hide', !visible);
      this._reloadPanelContent(tab_content);

      for (const select2 of tab_content.querySelectorAll("select:not([data-select2-disabled='true']):not(.hidden)")) {
        $(select2).select2({ theme: 'bootstrap' });
      }
    }

    const planning_schedule_view = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller~="planning-schedule"]'), 'planning-schedule');
    if (planning_schedule_view) {
      planning_schedule_view.selectTeamById(team_id);
    }

    const collaborators_schedule_view = this.application.getControllerForElementAndIdentifier(document.querySelector('[data-controller~="collaborators-schedule"]'), 'collaborators-schedule');
    if (collaborators_schedule_view) {
      collaborators_schedule_view.selectVolunteerId(volunteer_id);
      collaborators_schedule_view.selectTeamRegistrationId(team_registration_id);
      collaborators_schedule_view.selectTeamApplicationId(team_application_id);
    }
  }

  _updateBadge(tabList) {
    const numberOfTabs = tabList.querySelectorAll('li').length;

    const mainList = tabList.closest('ul.secondary-tabs');
    const tabId = mainList.dataset.scheduleViewSidePanelMainTabId;

    const targetTab = this.tabBadgeTargets.find((tab) => (tab.closest(`[data-schedule-view-side-panel-main-tab-id=${tabId}]`) !== null));
    if (targetTab) {
      if (numberOfTabs > 99) {
        targetTab.innerHTML = '..';
      } else {
        targetTab.innerHTML = numberOfTabs;
      }
    }
  }

  _onLoadOpenTab(mainTabId, url) {
    this.createSecondaryTab(
      mainTabId,
      '',
      '',
      url,
    );
  }
}
