import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['premiumSelect', 'hourSelect'];

  premiumSelected = (event) => {
    event.stopPropagation();
    const premiumId = this.premiumSelectTarget.value;
    const { workedHourId } = this.premiumSelectTarget.dataset;

    // do a $.ajax(type: 'GET') to get the appropriate details for the selected type
    const url = this.premiumSelectTarget.dataset.formDetailsUrl;
    const data = {
      id: workedHourId,
      premium_id: premiumId,
    };

    $.ajax({
      url,
      type: 'GET',
      data,
      error(_jqXHR, textStatus, errorThrown) {
        // log the error to the console
        console.error(`Error reading premium details form: ${textStatus}`,
          errorThrown);
      },
    });
  };

  hourSelected = (event) => {
    event.stopPropagation();
    const workedHourId = this.hourSelectTarget.value;

    // do a $.ajax(type: 'GET') to get the appropriate details for the selected worked hour
    let url = this.hourSelectTarget.dataset.formDetailsUrl;
    url = url.replace('dummy', workedHourId);

    $.ajax({
      url,
      type: 'GET',
      error(_jqXHR, textStatus, errorThrown) {
        // log the error to the console
        console.error(`Error reading premium details form: ${textStatus}`,
          errorThrown);
      },
    });
  };
}
