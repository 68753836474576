import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['startDateTimeInput', 'endDateTimeInput',
      'shiftDurationInput', 'breakDurationInput',
      'workDurationInput'];

    connect() {
    }

    initBreakDurationInput() {
      if ($(this.breakDurationInputTarget).datetimepicker('getDate') == null && this.breakDurationInputTarget.dataset.defaultDate) {
        const defaultBreakDuration = moment(this.breakDurationInputTarget.dataset.defaultDate, 'YYYY-MM-DD HH:mm::ss ZZ').toDate();
        $(this.breakDurationInputTarget).datetimepicker('setDate', defaultBreakDuration);
      }
    }

    limitAndRecalculateEndDateTime() {
      if ($(this.startDateTimeInputTarget).data('polyfill-shift-editor-ignore')) {
        return;
      }
      const opts = { };
      const minDateTime = $(this.startDateTimeInputTarget).datetimepicker('getDate');

      if ($(this.endDateTimeInputTarget).datetimepicker('getDate') == null && this.endDateTimeInputTarget.dataset.defaultDate) {
        const defaultDate = moment(this.endDateTimeInputTarget.dataset.defaultDate, 'YYYY-MM-DD HH:mm::ss ZZ').toDate();
        $(this.endDateTimeInputTarget).datetimepicker('setDate', defaultDate);
      }

      if (this.hasBreakDurationInputTarget) {
        this.initBreakDurationInput();
      }

      const shiftDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      const newEndDateTime = moment(minDateTime).add(shiftDurationInMinutes, 'minutes').toDate();

      if (!this.element.dataset.shiftEditorTimeOnly == 'true') {
        opts.minDate = minDateTime;
        opts.minDateTime = minDateTime;

        if (this.element.dataset.shiftEditorMoreThan24hAllowed == 'exact') {
          const maxDateTime = moment(minDateTime).add(24, 'hours').toDate();

          opts.maxDate = maxDateTime;
          opts.maxDateTime = maxDateTime;
        } else if (this.element.dataset.shiftEditorMoreThan24hAllowed != 'true') {
          const maxDateTime = moment(minDateTime).add(23, 'hours').add(59, 'minutes').toDate();

          opts.maxDate = maxDateTime;
          opts.maxDateTime = maxDateTime;
        }
        $(this.endDateTimeInputTarget).datetimepicker('option', opts);
      }
      $(this.endDateTimeInputTarget).datetimepicker('setDate', newEndDateTime);
    }

    recalculateEndBasedOnShiftDuration() {
      const shiftDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      const startDateTime = $(this.startDateTimeInputTarget).datetimepicker('getDate');

      if (this.hasWorkDurationInputTarget) {
        this.recalculateWorkDuration();
      }

      if (this.hasBreakDurationInputTarget) {
        this._disableBreakDurationIfLongerThan24h();
      }

      if (startDateTime == null) {
        return;
      }

      $(this.endDateTimeInputTarget).datetimepicker('setDate', moment(startDateTime).add(shiftDurationInMinutes, 'minutes').toDate());
    }

    recalculateShiftDuration() {
      if ($(this.startDateTimeInputTarget).datetimepicker('getDate') == null && this.startDateTimeInputTarget.dataset.defaultDate) {
        const defaultDate = moment(this.startDateTimeInputTarget.dataset.defaultDate, 'YYYY-MM-DD HH:mm::ss ZZ').toDate();
        $(this.startDateTimeInputTarget).datetimepicker('setDate', defaultDate);
      }

      const startDateTime = $(this.startDateTimeInputTarget).datetimepicker('getDate');
      const endDateTime = $(this.endDateTimeInputTarget).datetimepicker('getDate');

      let shiftDuration = moment(endDateTime).diff(moment(startDateTime), 'minutes');
      if (shiftDuration < 0) {
        shiftDuration += 24 * 60;
      }

      this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').setDurationInMinutes(shiftDuration);

      if (this.hasBreakDurationInputTarget) {
        this._disableBreakDurationIfLongerThan24h(shiftDuration);
      }

      if (this.hasWorkDurationInputTarget) {
        this.recalculateWorkDuration(shiftDuration);
      }
    }

    recalculatShiftDurationBasedOnWorkDuration() {
      const work_duration = this.application.getControllerForElementAndIdentifier(this.workDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      const break_duration = this.application.getControllerForElementAndIdentifier(this.breakDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      const shift_duration = work_duration + break_duration;

      this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').setDurationInMinutes(shift_duration);
      this.recalculateEndBasedOnShiftDuration();
    }

    recalculateWorkDurationBasedOnBreakDuration() {
      const shiftDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      let breakDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.breakDurationInputTarget, 'duration-input-widget').getDurationInMinutes();

      if (breakDurationInMinutes > shiftDurationInMinutes) {
        breakDurationInMinutes = shiftDurationInMinutes;
        this.application.getControllerForElementAndIdentifier(this.breakDurationInputTarget, 'duration-input-widget').setDurationInMinutes(breakDurationInMinutes);
      }

      this.application.getControllerForElementAndIdentifier(this.workDurationInputTarget, 'duration-input-widget').setDurationInMinutes(shiftDurationInMinutes - breakDurationInMinutes);
    }

    recalculateWorkDuration(currentShiftDurationInMinutes = null) {
      let shiftDurationInMinutes = null;
      if (currentShiftDurationInMinutes) {
        shiftDurationInMinutes = currentShiftDurationInMinutes;
      } else {
        shiftDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      }
      const breakDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.breakDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      this.application.getControllerForElementAndIdentifier(this.workDurationInputTarget, 'duration-input-widget').setDurationInMinutes(shiftDurationInMinutes - breakDurationInMinutes);
    }

    _disableBreakDurationIfLongerThan24h(currentShiftDurationInMinutes = null) {
      let shiftDurationInMinutes = null;
      if (currentShiftDurationInMinutes) {
        shiftDurationInMinutes = currentShiftDurationInMinutes;
      } else {
        shiftDurationInMinutes = this.application.getControllerForElementAndIdentifier(this.shiftDurationInputTarget, 'duration-input-widget').getDurationInMinutes();
      }
      const breakDurationWidget = this.application.getControllerForElementAndIdentifier(this.breakDurationInputTarget, 'duration-input-widget');
      const workDurationWidget = this.application.getControllerForElementAndIdentifier(this.workDurationInputTarget, 'duration-input-widget');

      if (shiftDurationInMinutes > 24 * 60) {
        breakDurationWidget.toggleDisable(true);
        breakDurationWidget.setDurationInMinutes(0);
        workDurationWidget.toggleDisable(true);
      } else {
        breakDurationWidget.toggleDisable(false);
        workDurationWidget.toggleDisable(false);
      }
    }
}
