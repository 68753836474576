import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const btns = $(this.element).find('a.btn');
    btns.removeClass('hidden');
  }

  previousPeriod(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = $('body').find('form[data-beeple-autosubmit-target]');
    form.find('input[name=start]').val(this.element.dataset.selectedDate);
    form.find('input[name=next_prev]').val('prev');
    autosubmit_update(form);
  }

  nextPeriod(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = $('body').find('form[data-beeple-autosubmit-target]');
    form.find('input[name=start]').val(this.element.dataset.selectedDate);
    form.find('input[name=next_prev]').val('next');
    autosubmit_update(form);
  }

  print(event) {
    event.preventDefault();
    event.stopPropagation();
    window.print();
  }
}
