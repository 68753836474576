import { Controller } from 'stimulus';
import { resetHighlights, removeFakes, setSidebarContents } from '../base/availabilities';

export default class extends Controller {
  static targets = ['rejectForm', 'rejectReason', 'modeInput', 'repetitionChangeWhichIds',
    'nFollowingInstances', 'rejectPopupBtn', 'destroyAailabilityButton', 'approveBtn'];

    //
    //  availabilities conflicts with other availabilities or with enrolments
    //
    cancelConflictsPopup = (event) => {
      event.stopPropagation();
      beeple_modal_remove('#availability_conflicts_modal');
      beeple_modal_remove('#availability_enrolment_conflicts_modal');
      Ladda.stopAll();
      this.closeQuickCreatePopup(event);
      this.closeSidebar(event);
      resetHighlights();
      removeFakes();
    }

    hideConflictsPopup() {
      beeple_modal_remove('#availability_conflicts_modal');
      beeple_modal_remove('#availability_enrolment_conflicts_modal');
    }

    closeModal(event) {
      event.stopPropagation();
      beeple_modal_remove('#availability_modal');

      if (event.target.dataset.reloadOnClose === 'true') {
        window.location.reload();
      }
    }

    closeSidebar(event) {
      event.stopPropagation();
      const sidebar = document.querySelector('.sidebar');
      if (sidebar !== undefined) {
        sidebar.classList.add('hide');
        setSidebarContents(null);
      }
    }

    closeModalOrSidebar(event) {
      this.closeModal(event);
      this.closeSidebar(event);
      resetHighlights();
      removeFakes();
    }

    closeQuickCreatePopup = (event) => {
      const popup = document.querySelector('.quick-create-popup');
      if (popup != null) {
        const controller = this.application.getControllerForElementAndIdentifier(popup, 'availabilities-quick-create');
        controller.closePopup(event);
      }
    }

    submit(_event) {
      this.hideConflictsPopup();
      const frm = document.querySelector('#availability_modal_body form');

      // clear the flag to prevent a dry run and actually save the availability
      const dryRunInput = frm.querySelector('input#dr');
      dryRunInput.value = 0;

      const data = new FormData(frm);

      $.ajax({
        url: frm.action,
        type: 'POST',
        data,
        processData: false,
        contentType: false,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error creating/updating availability: ${textStatus}`, errorThrown);
        },
      });
    }

    override(event) {
      const overrideInput = document.querySelector('#availability_modal_body form input#ovrd');
      overrideInput.value = 1;
      this.submit(event);
    }

    confirmMsg(event) {
      let btn;
      if (event.target.tagName == 'BUTTON') {
        btn = event.target;
      } else {
        btn = event.target.closest('button');
      }

      const msg = btn.dataset.confirmWith;
      if (msg !== undefined && msg.length > 0) {
        return confirm(msg);
      }
      return true;
    }

    approveAndSubmit(event) {
      if (this.confirmMsg(event)) {
        const approve = document.getElementById('volunteers_availability_is_approved');
        approve.value = true;
        this.submit(event);
      }
    }

    approve(event) {
      if (this.confirmMsg(event)) {
      // just approval
        const url = this.approveBtnTarget.dataset.approveUrl;
        $.ajax({
          url,
          type: 'post',
        });
      }
    }
    // lockAndSubmit(event) {
    //     let lock = document.getElementById("volunteers_availability_is_locked_true");
    //     lock.checked = true;
    //     this.submit(event);
    // }

    openRejectPopup = (event) => {
      const url = this.rejectPopupBtnTarget.dataset.rejectUrl;
      const data = this._getRepetitionData();
      $.ajax({
        url: `${url}.js`,
        type: ('GET'),
        data,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error rejecting availability: ${textStatus}`, errorThrown);
        },
      });
    }

    cancelReject(event) {
      event.stopPropagation();
      beeple_modal_remove('#availability_reject_modal');
      Ladda.stopAll();
    }

    rejectAndSubmit = (event) => {
      const url = this.rejectFormTarget.action;

      // the inputs in the reject popup
      const rejectReason = this.rejectReasonTarget.value;
      const mode = this.modeInputTarget.value;
      const repetitionChangeWhichIds = this.repetitionChangeWhichIdsTarget.value;
      const nFollowingInstances = this.nFollowingInstancesTarget.value;

      $.ajax({
        url,
        type: ('POST'),
        data: {
          mode,
          admin_reject_reason: rejectReason,
          repetition_change_which_ids: repetitionChangeWhichIds,
          n_following_instances: nFollowingInstances,
        },
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error performing availability reject: ${textStatus}`, errorThrown);
        },
      });
    }

    //
    // team_registration conflicts with availabilities
    //
    cancelEnrolmentConflictsPopup(event) {
      event.stopPropagation();
      beeple_modal_remove('#team_registration_conflicts_modal');
      Ladda.stopAll();
    }

    submitEnrolment(_event) {
      beeple_modal_remove('#team_registration_conflicts_modal');

      const frm = document.querySelector('form#new_volunteers_team_registration');
      const data = $(frm).serialize();

      $.ajax({
        url: `${frm.action}.js`,
        type: ('POST'),
        data,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error creating/updating enrolment: ${textStatus}`, errorThrown);
        },
      });
    }

    overrideForEnrolment(event) {
      const overrideInput = document.querySelector('form#new_volunteers_team_registration input#v');
      overrideInput.value = 'false';
      this.submitEnrolment(event);
    }

    submitByVolunteer(event) {
      beeple_modal_remove('#team_registration_conflicts_modal');

      const action = event.target.closest("button[data-action='availabilities#submitByVolunteer']").dataset.url;

      if (action !== undefined) {
        $.ajax({
          url: action,
          type: ('POST'),
          error(_jqXHR, textStatus, errorThrown) {
            // log the error to the console
            console.error(`Error retrieving new volunteer enrolment url: ${textStatus}`, errorThrown);
          },
        });
      }
    }

  destroyAailability = (event) => {
    event.stopPropagation();

    let url;
    let
      confirmMessage;

    if (this.hasDestroyAailabilityButtonTarget) {
      url = this.destroyAailabilityButtonTarget.dataset.url;
      confirmMessage = this.destroyAailabilityButtonTarget.dataset.confirm;
    }
    if (url !== undefined) {
      url += '.js';

      if (confirmMessage !== undefined) {
        if (confirm(confirmMessage)) {
          const data = this._getRepetitionData();
          $.ajax({
            url,
            type: 'delete',
            data,
          });
        } else {
          Ladda.stopAll();
        }
      } else {
        $.ajax({
          url,
          type: 'delete',
        });
      }
    }
  };

  _getRepetitionData = () => {
    let data = {};
    const radios = document.querySelectorAll("input[type='radio'][name='volunteers_availability[repetition_change_which_ids]']");
    if (radios !== null) {
      // we gather data to consume the
      // repetition_change_which_ids and n_following_instances
      // values in case there is a repetition
      const repetitionChangeWhichIds = Array.from(radios).find((radio) => radio.checked === true);

      const nFollowingInstances = document.querySelector('#volunteers_availability_n_following_instances');
      if (repetitionChangeWhichIds && nFollowingInstances) {
        data = {
          repetition_change_which_ids: repetitionChangeWhichIds.value,
          n_following_instances: nFollowingInstances.value,
        };
      }
    }
    return data;
  }
}
