import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['channelItemTemplate', 'channelList', 'channel', 'messageInput',
    'messageTemplate',
    'searchInput', 'userSelect', 'userSelectSearch',
    'usersAddSelect', 'usersAddSelectSearch', 'chatBoxHeader', 'chatBoxFooter',
    'currentChannelName', 'currentChannelMembers', 'messagesList',
    'markAsResolvedButton', 'markedAsResolvedButton',
    'departmentList']

  connect() {
    if (this.element.dataset.chatEndpoint !== undefined) {
      const protocol = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}`;
      this.consumer = ActionCable.createConsumer(`${protocol}://${this.element.dataset.chatEndpoint}/cable`);

      // clear-out the channel list
      this.channels = [];
      this.channelListTarget.replaceChildren(this.channelItemTemplateTarget);

      // and clear the message list
      this.messagesListTarget.replaceChildren();

      // also, hide chatbox header and footer
      this.chatBoxHeaderTarget.classList.add('hidden');
      this.chatBoxFooterTarget.classList.add('hidden');

      // if there's a list of departments, highlight the one currently active on the chat app
      for (const other_dept of this.element.querySelectorAll('[data-controller="chat-department"].active')) {
        other_dept.classList.toggle('active', false);
      }
      const activeDept = this.element.querySelector(`.chat-department[data-user-id="${this.element.dataset.userId}"]`);
      if (activeDept) {
        activeDept.classList.toggle('active', true);
      }
      // the id of a department the volunteer is tied to or ''
      this.departmentId = this.element.dataset.dId;
      // the chat id of the tenant or department will be used to filter channels for the volunteer
      // (mark channels as readonly if needed)
      this.adminChatId = this.element.dataset.aId;

      this._loadUnreadMessages();

      this.chat_channel = this.consumer.subscriptions.create(
        {
          channel: 'ChatChannel',
          data: { user_id: this.element.dataset.userId, access_token: this.element.dataset.userAccessToken },
        },
        {
          received: this._received,
        },
      );
    }
  }

  disconnect() {
    if (this.consumer !== undefined) {
      this.consumer.disconnect();
    }
  }

  // $chat.on 'input', '.js-chat-input', @_messageInputAutoSize

  // $chat.on 'click', '.js-invite-members', @_toggleInviteUsers
  // $chat.on 'click', '.js-invite-members-submit', @_inviteUsers
  // $chat.on 'click', '.js-name-submit', @_updateChannel

  checkMessageToSend = (event) => {
    if (event.which == 13 && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage(event);
    }
  }

  sendMessage = (event) => {
    if (this.messageInputTarget.value.trim() !== '') {
      this.chat_channel.send({ type: 'send-message', channel_id: this.element.dataset.currentChannelId, message: this.messageInputTarget.value.trim() });
      this.messageInputTarget.value = '';
      this._markAsUnresolved(this.element.dataset.currentChannelId);
    }
  };

  openChatBox(channel_id) {
    this.chatBoxHeaderTarget.classList.remove('hidden');
    this.chatBoxFooterTarget.classList.remove('hidden');
    this.messagesListTarget.classList.remove('hidden');
    if (this.hasUsersAddSelectTarget) {
      this.usersAddSelectTarget.classList.add('hidden');
    }

    if (this.hasUserSelectTarget) {
      this.userSelectTarget.classList.toggle('hidden', true);
    }

    const channel = this.channels[channel_id];
    this.element.dataset.currentChannelId = channel_id;
    this.currentChannelNameTarget.innerHTML = this._generateChannelName(channel);
    this.currentChannelMembersTarget.innerHTML = this._generateChannelMembers(channel);
    this.messagesListTarget.innerHTML = '';
    if (channel.readOnly) {
      this.messageInputTarget.disabled = 'disabled';
      this.messageInputTarget.placeholder = this.messageInputTarget.dataset.readOnlyMessage;
    } else {
      this.messageInputTarget.disabled = null;
      this.messageInputTarget.placeholder = this.messageInputTarget.dataset.readWriteMessage;
    }

    this.chat_channel.send({ type: 'get-messages', channel_id });

    this._updateBoxButtons(channel.resolved);
    this._markAsRead(channel);
  }

  openChatFor(userId, userAccessToken, departmentId) {
    this.element.dataset.userId = userId;
    this.element.dataset.userAccessToken = userAccessToken;
    this.element.dataset.dId = departmentId;

    // force reloading the chat (stimulus js) app:
    // 1. schedule a timeout to "re-connect" by putting back the data-controller attribute
    // 2. set the data-controller to something other than 'chat' so that we 'force' a disconnect
    setTimeout(() => {
      this.element.dataset.controller = 'chat';
    }, 200);
    this.element.dataset.controller = 'somethingElse';
  }

  searchChannels = (event) => {
    const search_function = () => {
      // this._enablePulseForChannels()
      const search_string = this.searchInputTarget.value;

      if (search_string.trim() == '') {
        this._updateChannelList();
        return;
      }

      $.ajax({
        type: 'GET',
        url: '/admin/volunteers/search.json',
        dataType: 'json',
        data: {
          q: search_string,
          department_id: this.departmentId,
        },
        success: (data) => {
          const user_ids = data.map((user_data) => user_data.beeple_chat_user_id);

          for (const channel of this.channelTargets) {
            const member_ids = channel.dataset.channelMemberIds.split(';');
            const intersect = user_ids.filter((value) => member_ids.indexOf(value) !== -1);
            channel.classList.toggle('hide', (intersect.length === 0 || (!this._showResolved() && channel.dataset.resolved === 'true')));
          }
        },
      });
    };
    setTimeout(search_function, 500);
  }

  /* ============== Admin only  ================== */

  toggleResolvedVisibility = (event) => {
    this.element.dataset.showResolvedToo = event.target.checked;
    this._updateChannelList();
  }

  toggleUsersSelect = (_event) => {
    const userSelectHidden = this.userSelectTarget.classList.contains('hidden');
    const hasChatChannels = (this.channelListTarget.querySelectorAll(
      'li.chat-channel:not(.hide)',
    ).length > 0);
    if (userSelectHidden) {
      this.userSelectTarget.classList.remove('hidden');
      this.chatBoxHeaderTarget.classList.add('hidden');
      this.chatBoxFooterTarget.classList.add('hidden');
      this.messagesListTarget.classList.add('hidden');
    } else {
      this.userSelectTarget.classList.add('hidden');
      if (hasChatChannels) {
        this.chatBoxHeaderTarget.classList.remove('hidden');
        this.chatBoxFooterTarget.classList.remove('hidden');
        this.messagesListTarget.classList.remove('hidden');
      }
    }

    const outer = this;

    $(this.userSelectSearchTarget).select2({
      theme: 'bootstrap',
      width: '100%',
      allowClear: true,
      placeholder: '',
      ajax: {
        url: '/admin/volunteers/search.json',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            q: params.term,
            department_id: outer.departmentId,
          };
        },
        processResults(data) {
          const results = data.map((user_data) => {
            const text = `${user_data.user.family_name} ${user_data.user.other_names}`;
            const id = user_data.beeple_chat_user_id;
            return { id, text };
          });
          return { results };
        },
        cache: true,
      },
      minimumInputLength: 3,
    });
  }

  toggleAddUsersSelect = (event) => {
    this.messagesListTarget.classList.toggle('hidden');
    this.usersAddSelectTarget.classList.toggle('hidden');
    this.chatBoxFooterTarget.classList.toggle('hidden');
    const outer = this;

    $(this.usersAddSelectSearchTarget).select2({
      theme: 'bootstrap',
      width: '100%',
      allowClear: true,
      placeholder: '',
      ajax: {
        url: '/admin/volunteers/search.json',
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            q: params.term,
            department_id: outer.departmentId,
          };
        },
        processResults(data) {
          const results = data.map((user_data) => {
            const text = `${user_data.user.family_name} ${user_data.user.other_names}`;
            const id = user_data.beeple_chat_user_id;
            return { id, text };
          });
          return { results };
        },
        cache: true,
      },
      minimumInputLength: 3,
    });
  }

  createChannel = (_event) => {
    const user_ids = Array.from(this.userSelectSearchTarget.querySelectorAll(':checked')).map((x) => x.value);
    if (user_ids.length > 0) {
      this.chat_channel.send({ type: 'create-channel', member_ids: user_ids });
      this.userSelectTarget.classList.toggle('hidden');
      this.chatBoxHeaderTarget.classList.toggle('hidden');
      this.chatBoxFooterTarget.classList.toggle('hidden');
      this.messagesListTarget.classList.toggle('hidden');
    }
  }

  addUsersToChannel = (event) => {
    const user_ids = Array.from(this.usersAddSelectSearchTarget.querySelectorAll(':checked')).map((x) => x.value);
    if (user_ids.length > 0) {
      this.chat_channel.send({ type: 'add-users-to-channel', member_ids: user_ids, channel_id: this.element.dataset.currentChannelId });
    }
    this.messagesListTarget.classList.toggle('hidden');
    this.usersAddSelectTarget.classList.toggle('hidden');
    this.chatBoxFooterTarget.classList.toggle('hidden');
  }

  deleteChannelConfirmation = (event) => {
    const channel_id = event.target.closest('[data-channel-id]').dataset.channelId;

    if (confirm('Are you sure?')) {
      this.chat_channel.send({ type: 'delete-channel', channel_id });
    }
  }

  resolveChannel = (event) => {
    const channel_id = event.target.closest('[data-channel-id]').dataset.channelId;
    this.chat_channel.send({ type: 'toggle-resolve-channel', channel_id });
  }

  toggleChannelResolved = (event) => {
    if (this.element.dataset.currentChannelId !== null) {
      const channel = this.channels[this.element.dataset.currentChannelId];
      if (channel !== null) {
        this.chat_channel.send({ type: 'toggle-resolve-channel', channel_id: channel.id });
      }
    }
  }

  /* ============== Private =================== */

  _loadUnreadMessages() {
    const multiAccounts = document.querySelector('meta[name="chat-accounts"]');
    if (multiAccounts == null) {
      // Not using departments, or on public side.
      return;
    }

    const { chatEndpoint } = this.element.dataset;

    $.ajax({
      url: `//${chatEndpoint}/api/v1/accounts/unread.json`,
      type: 'POST',
      data: JSON.stringify({ accounts: JSON.parse(multiAccounts.content) }),
      dataType: 'json',
      contentType: 'application/json',
    }).done((data) => {
      this.departmentListTarget.querySelectorAll('li.chat-department').forEach((element) => {
        const { userId } = element.dataset;
        const unreadCount = data.unread_messages_per_user[userId];

        if (unreadCount > 0) {
          const badge = element.querySelector('.badge');
          element.dataset.unreadCount = unreadCount;
          badge.innerHTML = unreadCount;
        } else {
          const badge = element.querySelector('.badge');
          element.dataset.unreadCount = 0;
          badge.innerHTML = null;
        }
      });
    });
  }

    _updateChannelList = () => {
      if (this._showResolved()) {
        for (const channel of this.channelTargets) {
          channel.classList.toggle('hide', false);
        }
      } else {
        for (const channel of this.channelTargets) {
          if (channel.dataset.resolved == 'true') {
            channel.classList.toggle('hide', true);
          } else {
            channel.classList.toggle('hide', false);
          }
        }
      }
    }

    _showResolved() {
      return (this.element.dataset.showResolvedToo === 'true' || !this._isAdminView());
    }

    _received = (data) => {
      switch (data.type) {
        case 'authenticated':
          setTimeout(() => {
            this._openChannelList(data.channels);
          }, 10);
          break;
        case 'new-message':
          this._messageReceived(data);
          this._updateUnreadCount();
          break;
        case 'list-messages':
          if (data.channel_id != this.element.dataset.currentChannelId) {
            break;
          }
          for (const message of data.messages) {
            this._renderMessage(message);
          }
          this._updateUnreadCount();
          break;
        case 'new-channel':
          this.channels[data.id] = data;
          this._renderChannel(data, true);
          break;
        case 'update-channel':
          this.channels[data.id] = data;
          this._updateChannel(data, true);
          break;
        case 'delete-channel':
          this.element.querySelector(`[data-channel-id="${data.id}"]`).remove();
          this._updateUnreadCount();
          break;
        default:
      }
    }

    _messageReceived(message) {
      if (message.channel_id != this.element.dataset.currentChannelId) {
        const channel_element = this.element.querySelector(`[data-channel-id="${message.channel_id}"]`);
        if (channel_element == null) {
          return;
        }
        channel_element.dataset.unreadCount = parseInt(channel_element.dataset.unreadCount) + 1;
        channel_element.dataset.lastMessageDate = this._messageDate(message.sent_at);
        channel_element.dataset.resolved = false;

        const channel_controller = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
        channel_controller.update();
        return;
      }

      this._markAsRead(this.channels[message.channel_id]);

      const channel_element = this.element.querySelector(`[data-channel-id="${message.channel_id}"]`);
      channel_element.dataset.lastMessageDate = this._messageDate(message.sent_at);

      const channel_controller = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
      channel_controller.update();

      this._renderMessage(message);

    /* @_setAutoScroll() # Needed here because the .on 'scroll' is not working*?
    /*@_updateScroll() */
    }

    _markAsRead(channel) {
      this.chat_channel.send({ type: 'mark-as-read', channel_id: channel.id });

      const channel_element = this.element.querySelector(`[data-channel-id="${channel.id}"]`);
      channel_element.dataset.unreadCount = 0;
      const channel_controller = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
      channel_controller.update();
    }

    _updateBoxButtons(resolved) {
      if (this._isAdminView()) {
        if (resolved === true) {
          this.markAsResolvedButtonTarget.classList.add('hidden');
          this.markedAsResolvedButtonTarget.classList.remove('hidden');
        } else {
          this.markAsResolvedButtonTarget.classList.remove('hidden');
          this.markedAsResolvedButtonTarget.classList.add('hidden');
        }
      }
    }

    _markAsUnresolved(channelId) {
      const channel_element = this.element.querySelector(`[data-channel-id="${channelId}"]`);
      channel_element.dataset.resolved = false;
      const channel_controller = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
      channel_controller.update();
    }

    _updateChannel(channel) {
      const channel_element = this.element.querySelector(`[data-channel-id="${channel.id}"]`);
      channel_element.dataset.unreadCount = channel.unread_count || 0;
      channel_element.dataset.resolved = channel.resolved || false;
      channel_element.dataset.name = this._generateChannelName(channel);
      channel_element.dataset.channelMemberIds = channel.members
        .map((member) => member.user_id)
        .join(';');

      if (this.element.dataset.currentChannelId === channel.id) {
      // update the open chat box
        this.currentChannelNameTarget.innerHTML = this._generateChannelName(channel);
        this.currentChannelMembersTarget.innerHTML = this._generateChannelMembers(channel);
        this._updateBoxButtons(channel.resolved);
      }

      if (!this._showResolved() && channel.resolved) {
      // hide the resolved channel
        channel_element.classList.add('hide');
      }

      const channel_controller = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
      channel_controller.update();
    }

    _renderMessage(message) {
      const message_dom = this.messageTemplateTarget.cloneNode(true);
      message_dom.dataset.senderName = message.sender.nickname;
      message_dom.dataset.sentAt = this._messageDate(message.sent_at);
      message_dom.dataset.received = message.sender.user_id != this.element.dataset.userId;
      message_dom.dataset.message = message.message;
      message_dom.classList.toggle('hide', false);
      this.messagesListTarget.append(message_dom);
      message_dom.scrollIntoView(true);
    }

    _openChannelList(channels) {
      let firstChannel;

      const x = (a, firstChan) => () => {
        const current_slice = channels.slice(a, a + 100);
        // if (firstChannel) {
        //   console.log(`RENDERING ${a} firstChannel: ${firstChannel.id}`);
        // } else {
        //   console.log(`RENDERING ${a} firstChannel: NONE`);
        // }
        for (const channel of current_slice) {
          // mark as readonly channels where the other party is not the tenant or department
          // (might be the case when the tenant enabled/disabled departments after some chats are already there)
          channel.readOnly = (this.adminChatId !== undefined && !channel.members.some((m) => m.user_id === this.adminChatId));

          if (firstChan === undefined && channel.resolved !== true) {
            firstChan = channel;
          }
          this.channels[channel.id] = channel;
          this._renderChannel(channel);
        }

        if (a + 100 > channels.length) {
          if (firstChan !== undefined) {
            // activate last message:
            // needs the timeout to allow the JS event loop to hookup stimulusjs
            // controllers on the newly added chat-channels
            setTimeout(() => {
              const channel_element = this.element.querySelector(`[data-channel-id="${firstChan.id}"]`);
              const channelController = this.application.getControllerForElementAndIdentifier(channel_element, 'chat-channel');
              const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true,
              });
              channelController.openChannel(event);
              this._updateUnreadCount();
            }, 1000);
          }
        } else {
          setTimeout(x(a + 100, firstChan), 50);
        }
      };

      setTimeout(x(0, firstChannel), 50);
    }

    _renderChannel(channel, first = false) {
      // noop if channel is already already rendered
      const existingChannel = this.channelListTarget.querySelector(`li.chat-channel[data-channel-id="${channel.id}"]`);
      if (existingChannel) {
        return;
      }

      const { last_message } = channel;
      let last_message_date = '';
      if (last_message) {
        last_message_date = this._messageDate(last_message.sent_at);
      }

      const new_channel_item = this.channelItemTemplateTarget.cloneNode(true);
      new_channel_item.dataset.target = 'chat.channel';
      new_channel_item.dataset.name = this._generateChannelName(channel);
      new_channel_item.dataset.lastMessageDate = last_message_date;
      new_channel_item.dataset.unreadCount = channel.unread_count || 0;
      new_channel_item.dataset.resolved = channel.resolved || false;
      new_channel_item.dataset.channelId = channel.id;
      new_channel_item.dataset.isAdminView = this.channelItemTemplateTarget.dataset.isAdminView;
      new_channel_item.dataset.readOnly = channel.readOnly;
      new_channel_item.dataset.channelMemberIds = channel.members
        .map((member) => member.user_id)
        .join(';');

      if (this._showResolved()) {
        new_channel_item.classList.toggle('hide', false);
      } else {
        new_channel_item.classList.toggle('hide', (new_channel_item.dataset.resolved === 'true'));
      }

      if (first) {
        this.channelListTarget.prepend(new_channel_item);
      } else {
        this.channelListTarget.append(new_channel_item);
      }
    }

    _generateChannelName(channel) {
      const user_id = this.element.dataset.userId;
      if (channel.name == '') {
        return channel.members.filter((member) => member.user_id != user_id).map((member) => member.nickname).join(', ');
      }
      return channel.name;
    }

    _generateChannelMembers(channel) {
      return channel.members
        .map((member) => member.nickname)
        .join(', ');
    }

    _messageDate(timestamp) {
    // BAPP-10043 spec: keep sorted by date (as is now) but always show the date at the end of the record, never the time
      return moment(timestamp).format('L');
    }

    _isAdminView() {
      return (this.channelItemTemplateTarget.dataset.isAdminView === 'true');
    }

    _updateUnreadCount() {
    //  declaring unreadCountBadge within a data-controller="chat" didn't work
      const unreadCountBadge = document.querySelector("[data-target='chat-notification.unreadCountBadge']");
      if (unreadCountBadge !== null) {
        let unreadCount = 0;

        for (const channel of this.channelTargets) {
          unreadCount += parseInt(channel.dataset.unreadCount || 0);
        }
        unreadCountBadge.innerHTML = unreadCount;
        unreadCountBadge.classList.toggle('hide', (unreadCount === 0));
      }

      // Also update the departments list
      this._loadUnreadMessages();
    }
}
