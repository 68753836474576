import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['settingsForm', 'toggleAllSettingsLink', 'settingsInput'];

    connect() {
      this._initAllSettingsLink();
    }

    updateSettings = (_event) => {
      const form = this.settingsFormTarget;
      const data = $(form).serialize();
      const url = form.action;
      this._post(url, data);
      this._initAllSettingsLink();
    }

    toggleAllSettings = (event) => {
      event.preventDefault();
      const allEnabled = (this.toggleAllSettingsLinkTarget.dataset.allEnabled === 'true');
      this.toggleAllSettingsLinkTarget.dataset.allEnabled = !allEnabled;

      this.settingsInputTargets.forEach((input) => {
        input.checked = !allEnabled;
      });
      this.updateSettings(event);
    }

    _post(url, data) {
      $.ajax({
        url,
        type: ('POST'),
        data,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error changing settings: ${textStatus}`, errorThrown);
        },
      });
    }

    _initAllSettingsLink() {
      if (this.hasToggleAllSettingsLinkTarget) {
        const allChecked = this.settingsInputTargets.every((input) => input.checked);

        let newText;
        if (allChecked) {
          this.toggleAllSettingsLinkTarget.dataset.allEnabled = 'true';
          newText = this.toggleAllSettingsLinkTarget.dataset.disableAllText;
        } else {
          this.toggleAllSettingsLinkTarget.dataset.allEnabled = 'false';
          newText = this.toggleAllSettingsLinkTarget.dataset.enableAllText;
        }
        this.toggleAllSettingsLinkTarget.innerHTML = newText;
      }
    }
}
