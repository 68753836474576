import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['selectHappeningCheckbox', 'toggleHappeningCheckbox'];

  syncSelectedWithToggled = (_event) => {
    // toggleHappeningCheckbox is hidden from view but changed by clicking on the checkbox label
    // this handler responds on change event of the toggleHappeningCheckbox
    this.selectHappeningCheckboxTarget.checked = this.toggleHappeningCheckboxTarget.checked;
  }
}
