import { Controller } from 'stimulus';
// Controller used to search options of a multi choice list.
export default class extends Controller {
  static targets = ['row', 'input'];

  connect() {
    this.originalRows = Array.from(this.rowTargets);
  }

  filterRows(event) {
    event.preventDefault();

    const searchTerm = this.inputTarget.value.toLowerCase();
    this.rowTargets.forEach((row, index) => {
      const rowData = this.originalRows[index].textContent.toLowerCase();
      if (rowData.includes(searchTerm)) {
        row.style.display = '';
      } else {
        row.style.display = 'none';
      }
    });
  }
}
