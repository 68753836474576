import { Controller } from 'stimulus';

// Used in app/views/shared/payroll_allocations/_waterfall_priorities.html.erb

export default class extends Controller {
  static targets = ['showContractType'];

  indexOrigin = this.hasShowContractTypeTarget ? 1 : 0;

  connect() {
    $(this.element).sortable({
      handle: '.handle',
      items: '> tr[data-reorder-priority-url]',
      update: (event, ui) => {
        $.ajax({
          url: $(ui.item).data('reorder-priority-url'),
          method: 'POST',
          data: {
            _method: 'PATCH',
            reorder_priority: {
              to_index: ui.item.index() - this.indexOrigin,
              payroll_service_id: ui.item.data('payroll-service-id'),
            },
          },
        });
      },
    });
  }
}
