import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['sourceSelector', 'form'];

    connect() {
      // for some reason (maybe because it's on a popup) it did not work when
      // declaring the data-action='change->getTargetPeriods'
      // directly on the form
      if (this.sourceSelectorTarget !== undefined) {
        $(this.sourceSelectorTarget).on('change', this.readSourcePeriod);
      }
    }

    readSourcePeriod(event) {
      const url = event.target.dataset.targetsUrl;
      const payload = { source_id: event.target.value };

      $.ajax({
        url,
        type: 'POST',
        data: payload,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error reading source counter period: ${textStatus}`, errorThrown);
        },
      });
    }

    performCopy(event) {
      event.preventDefault();

      const frm = this.formTarget;
      const data = $(frm).serialize();

      $.ajax({
        url: `${frm.action}.js`,
        type: ('POST'),
        data,
        error(_jqXHR, textStatus, errorThrown) {
          // log the error to the console
          console.error(`Error submitting counter copy action: ${textStatus}`, errorThrown);
        },
      });
    }
}
