import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'type'];

  connect() {
    const { typeTarget, selectTarget } = this;

    $(selectTarget).on('select2:select', (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const templateType = selectedOption.dataset.type;
      typeTarget.value = templateType;
    });
  }
}
