/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Stimulus.js stuff
import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import '@mdi/font/css/materialdesignicons.min.css';

import 'jquery-ui-timepicker-addon/dist/jquery-ui-timepicker-addon.min.css';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';
import 'jquery-ui-multidatespicker/jquery-ui.multidatespicker.css';
import 'fullcalendar/dist/fullcalendar.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.min.css';
import 'bootstrap-toggle/css/bootstrap-toggle.min.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'ladda/dist/ladda.min.css';
import 'nvd3/build/nv.d3.min.css';
import 'dragtable/dragtable.css';
import 'animate.css/animate.min.css';

import 'jquery-ui/themes/base/all.css';
import '@claviska/jquery-minicolors/jquery.minicolors.css';

const application = Application.start();
const context = require.context('controllers', true, /.js$/);
application.load(definitionsFromContext(context));
