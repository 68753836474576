import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['linkCollaboratorButton', 'createCollaboratorButton',
    'linkedEnvironmentInput',
    'resultsNoMatches',
    'matchesLoading', 'matches',
    'matchBlueprint', 'matchResult'];

  connect() {
    this.linkCollaboratorButtonTarget.classList.toggle('hidden', true);
    this.createCollaboratorButtonTarget.classList.toggle('hidden', true);
  }

  find_matches() {
    this.matchesLoadingTarget.classList.toggle('hidden', false);
    this.matchesTarget.classList.toggle('hidden', true);
    for (const old_result of this.matchResultTargets) {
      old_result.remove();
    }

    this.linkCollaboratorButtonTarget.classList.toggle('hidden', true);
    this.createCollaboratorButtonTarget.classList.toggle('hidden', true);

    if (this.linkedEnvironmentInputTarget.value == '') {
      /* No selection, nothing to do */
      this.matchesLoadingTarget.classList.toggle('hidden', true);
      return;
    }

    $.ajax({
      url: this.element.dataset.matchesUrl,
      type: 'POST',
      data: {
        linked_work_environment_id: this.linkedEnvironmentInputTarget.value,
      },
      success: (data, _textStatus, _jqXHR) => { this.display_matches(data); },
    });
  }

  matchSelected = (event) => {
    // if 'Link existing collaborator' button was disabled, re-enable it
    if (!this.linkCollaboratorButtonTarget.classList.contains('hidden')) {
      this.linkCollaboratorButtonTarget.removeAttribute('disabled');
      this.linkCollaboratorButtonTarget.classList.remove('btn-default');
      this.linkCollaboratorButtonTarget.classList.remove('ladda-button');
      this.linkCollaboratorButtonTarget.classList.add('btn-primary');
    }

    this.linkCollaboratorButtonTarget.classList.toggle('hidden', false);
    this.selectedRemoteCollaboratorId = event.target.value;
    const selectedMatchData = this.matchesData.matches.find((match) => match.id == this.selectedRemoteCollaboratorId);
    if (selectedMatchData.confirmMessage && selectedMatchData.confirmMessage.length > 0) {
      // Show confirmation dialog before linking new collab
      this.linkCollaboratorButtonTarget.dataset.confirm = selectedMatchData.confirmMessage;
    } else {
      this.linkCollaboratorButtonTarget.dataset.confirm = null;
    }
  }

  display_matches = (result) => {
    this.matchesData = result;
    this.linkCollaboratorButtonTarget.classList.toggle('hidden', true);
    this.createCollaboratorButtonTarget.classList.toggle('hidden', !result.canCreate);

    this.matchesLoadingTarget.classList.toggle('hidden', true);
    this.matchesTarget.classList.toggle('hidden', false);

    this.resultsNoMatchesTarget.classList.toggle('hidden', result.matches.length > 0);
    this.createCollaboratorButtonTarget.classList.toggle('btn-primary', result.matches.length == 0);

    const sorted_matches = result.matches.sort((a, b) => {
      if (a.score < b.score) {
        return 1;
      } if (a.score > b.score) {
        return -1;
      }
      return 0;
    });
    for (const match of sorted_matches) {
      const matchControllerNode = this.matchBlueprintTarget.cloneNode(true);
      matchControllerNode.dataset.target = 'admin--multi--link-collaborator.matchResult'; // Remove the blueprint
      matchControllerNode.classList.toggle('hidden', false);
      matchControllerNode.dataset.matchdata = JSON.stringify(match);
      this.matchBlueprintTarget.parentNode.appendChild(matchControllerNode);
    }
  }
}
