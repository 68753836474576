import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['emptyEventsTemplate', 'emptyEventsTemplateInCellHeader'];

    addEventTemplate = (_event) => {
      if (this.hasEmptyEventsTemplateTarget) {
        const templateClone = this.emptyEventsTemplateTarget;
        templateClone.classList.remove('hide');
      } else if (this.hasEmptyEventsTemplateInCellHeaderTarget) {
        const templateClone = this.emptyEventsTemplateInCellHeaderTarget;
        templateClone.classList.remove('hidden-keep-space');
      }
    }

    removeEventTemplate = (_event) => {
      if (this.hasEmptyEventsTemplateTarget) {
        const templateClone = this.emptyEventsTemplateTarget;
        templateClone.classList.add('hide');
      } else if (this.hasEmptyEventsTemplateInCellHeaderTarget) {
        const templateClone = this.emptyEventsTemplateInCellHeaderTarget;
        templateClone.classList.add('hidden-keep-space');
      }
    }
}
