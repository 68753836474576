const customLabelMapping = {
  function: 'activerecord.models.happenings/function.one',
  functions: 'activerecord.models.happenings/function.other',
  profile_property: 'activerecord.models.volunteers/profile_property.one',
  profile_properties: 'activerecord.models.volunteers/profile_property.other',
  profile_property_group: 'activerecord.models.tenant/profile_property_group.one',
  profile_property_groups: 'activerecord.models.tenant/profile_property_group.other',
  enrolment_property: 'activerecord.models.tenant/enrolment_property.one',
  enrolment_properties: 'activerecord.models.tenant/enrolment_property.other',
  enrolment_property_group: 'activerecord.models.tenant/enrolment_property_group.one',
  enrolment_property_groups: 'activerecord.models.tenant/enrolment_property_group.other',
  compensation: 'activerecord.models.happenings/team/compensation.one',
  compensations: 'activerecord.models.happenings/team/compensation.other',
  premium: 'activerecord.models.tenant/premium.one',
  premiums: 'activerecord.models.tenant/premium.other',
  department: 'activerecord.models.tenant/department.one',
  departments: 'activerecord.models.tenant/department.other',
  accessory: 'activerecord.models.tenant/accessories/item.one',
  accessories: 'activerecord.models.tenant/accessories/item.other',
  accessory_group: 'activerecord.models.tenant/accessories/group.one',
  accessory_groups: 'activerecord.models.tenant/accessories/group.other',
  collaborator: 'activerecord.models.volunteers/volunteer.one',
  collaborators: 'activerecord.models.volunteers/volunteer.other',
  organisation: 'activerecord.models.volunteers/organisation.one',
  organisations: 'activerecord.models.volunteers/organisation.other',
  happening: 'activerecord.models.happenings/happening.one',
  happenings: 'activerecord.models.happenings/happening.other',
  cluster: 'activerecord.models.happenings/cluster.one',
  clusters: 'activerecord.models.happenings/cluster.other',
  team: 'activerecord.models.happenings/team.one',
  teams: 'activerecord.models.happenings/team.other',
  team_registration: 'activerecord.models.volunteers/team_registration.one',
  team_registrations: 'activerecord.models.volunteers/team_registration.other',
  team_registration_callsheet: 'activerecord.models.volunteers/team_registration/callsheet.one',
  team_registration_callsheets: 'activerecord.models.volunteers/team_registration/callsheet.other',
  project_callsheet: 'activerecord.models.volunteers/team_registration/project_callsheet.one',
  project_callsheets: 'activerecord.models.volunteers/team_registration/project_callsheet.other',
  team_application: 'activerecord.models.volunteers/team_application.one',
  team_applications: 'activerecord.models.volunteers/team_application.other',
  supplier: 'activerecord.models.supplier.one',
  suppliers: 'activerecord.models.supplier.other',
  client: 'activerecord.models.tenant/client.one',
  clients: 'activerecord.models.tenant/client.other',
  upload: 'activerecord.models.upload.one',
  uploads: 'activerecord.models.upload.other',
  team_invite: 'activerecord.models.happenings/team/invite.one',
  team_invites: 'activerecord.models.happenings/team/invite.other',
  job: 'custom_keys_defaults.job',
  jobs: 'custom_keys_defaults.jobs',
  contract_type_contractual: 'custom_keys_defaults.contract_types.contractual',
  contract_type_interim: 'custom_keys_defaults.contract_types.interim',
  contract_type_volunteer: 'custom_keys_defaults.contract_types.volunteer',
  contract_type_intern: 'custom_keys_defaults.contract_types.intern',
  contract_type_freelancer: 'custom_keys_defaults.contract_types.freelancer',
  social_statute_career_pause: 'custom_keys_defaults.social_statutes.career_pause',
  social_statute_disabled_entrepreneur: 'custom_keys_defaults.social_statutes.disabled_entrepreneur',
  social_statute_early_retired: 'custom_keys_defaults.social_statutes.early_retired',
  social_statute_entrepreneur: 'custom_keys_defaults.social_statutes.entrepreneur',
  social_statute_flexijobs: 'custom_keys_defaults.social_statutes.flexijobs',
  social_statute_functionary: 'custom_keys_defaults.social_statutes.functionary',
  social_statute_incapacitated: 'custom_keys_defaults.social_statutes.incapacitated',
  social_statute_living_wager: 'custom_keys_defaults.social_statutes.living_wager',
  social_statute_retired: 'custom_keys_defaults.social_statutes.retired',
  social_statute_student: 'custom_keys_defaults.social_statutes.student',
  social_statute_unemployed: 'custom_keys_defaults.social_statutes.unemployed',
  social_statute_unemployed_waiting_period: 'custom_keys_defaults.social_statutes.unemployed_waiting_period',
  social_statute_unknown: 'custom_keys_defaults.social_statutes.unknown',
  social_statute_working_class: 'custom_keys_defaults.social_statutes.working_class',
  social_statute_working_class_blue_collar: 'custom_keys_defaults.social_statutes.working_class_blue_collar',
  social_statute_work_student: 'custom_keys_defaults.social_statutes.work_student',
};

export default class I18nLabelSubstitutionFormatter {
  loadTranslations() {
    return new Promise((resolve) => {
      const getAPIData = new XMLHttpRequest();

      getAPIData.onreadystatechange = () => {
        if (getAPIData.readyState === 4) {
          if (getAPIData.status === 200) {
            this.translations = JSON.parse(getAPIData.responseText);
          } else {
            this.translations = {};
          }
          resolve();
        }
      };

      getAPIData.open('GET', '/b/internal/custom_labels.json');
      getAPIData.send();
    });
  }

  //
  // interpolate
  //
  // @param {string} message
  //   string of list or named format.
  //   e.g.
  //   - named formatting: 'Hi {name}'
  //   - list formatting: 'Hi {0}'
  //
  // @param {Object | Array} values
  //   values of `message` interpolation.
  //   passed values with `$t`, `$tc` and `i18n` functional component.
  //   e.g.
  //   - $t('hello', { name: 'kazupon' }) -> passed values: Object `{ name: 'kazupon' }`
  //   - $t('hello', ['kazupon']) -> passed values: Array `['kazupon']`
  //   - `i18n` functional component (component interpolation)
  //     <i18n path="hello">
  //       <p>kazupon</p>
  //       <p>how are you?</p>
  //     </i18n>
  //     -> passed values: Array (included VNode):
  //        `[VNode{ tag: 'p', text: 'kazupon', ...}, VNode{ tag: 'p', text: 'how are you?', ...}]`
  //
  // @return {Array<any>}
  //   interpolated values. you need to return the following:
  //   - array of string, when is using `$t` or `$tc`.
  //   - array included VNode object, when is using `i18n` functional component.
  //
  interpolate(message, values) {
    let interpolatedMessage = message;
    for (const match of message.matchAll(/(&{Label.([a-z_]*)})/g)) {
      const translationKey = customLabelMapping[match[2]];
      const translation = this.translations[translationKey];
      interpolatedMessage = interpolatedMessage.replace(match[0], translation);
    }

    for (const match of message.matchAll(/(&{label.([a-z_]*)})/g)) {
      const translationKey = customLabelMapping[match[2]];
      const translation = this.translations[translationKey];
      interpolatedMessage = interpolatedMessage.replace(match[0], translation.toLowerCase());
    }

    for (const match of message.matchAll(/(%{([a-z_]*)})/g)) {
      const translationKey = match[2];
      const translation = values[translationKey];
      interpolatedMessage = interpolatedMessage.replace(match[0], translation);
    }

    return [interpolatedMessage];
  }
}
